<mat-form-field>
    <input
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        aria-label="Airport"
        matInput
        placeholder="Airport"
        type="text"
    />
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
        [displayWith]="displayFn"
    >
        <mat-option
            *ngFor="let airport of filteredAirports | async"
            [value]="airport"
        >
            {{ airport.icao }} - {{ airport.fullAirportName }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
