<a class="logo" href="/default-layout">
    <img
        alt=""
        class="logo-img"
        height="31"
        src="assets/img/landing-page/fbolinx_dark.png"
        width="95"
    />
</a>

<span class="compress-logo">
    <img
        alt=""
        class="logo-img"
        height="33"
        src="assets/img/logo-compress.png"
        width="38"
    />
</span>
