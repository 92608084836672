<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center">
            <h5 *ngIf="!isCustomerActive">Customer is inactive</h5>
        </div>
        <div *ngIf="isPricesExpired" class="col-12 text-center">
            <h5>Pricing expired. Please update your cost and retail values.</h5>
        </div>
    </div>
    <div *ngIf="domesticPrivatePricing && !isPricesExpired && isCustomerActive" class="row">
        <!--Fee and tax breakdown-->
        <div class="col-xl-6 col-md-12 fee-and-tax-breakdown-container mb-2"
            *ngIf="
                !hideFeeAndTaxGeneralBreakdown &&
                domesticPrivatePricing &&
                domesticPrivatePricing.pricingList &&
                domesticPrivatePricing.pricingList.length > 0 &&
                domesticPrivatePricing.pricingList[0].fboPrice
            "
        >
            <fee-and-tax-breakdown
                #feeAndTaxBreakdown
                (omitCheckChanged)="omitChanged($event)"
                [customerMargin]="
                    domesticPrivatePricing.pricingList[0].customerMarginAmount
                "
                [displayMode]="feeAndTaxDisplayMode"
                [fboPrice]="domesticPrivatePricing.pricingList[0].fboPrice"
                [feesAndTaxes]="feesAndTaxes"
                [marginType]="domesticPrivatePricing.pricingList[0].marginType"
                [discountType]="domesticPrivatePricing.pricingList[0].discountType"
                [showLineSeparator]="showFeeAndTaxLineSeparator"
                [validDepartureTypes]="defaultValidDepartureTypes"
                [validFlightTypes]="defaultValidFlightTypes"
                [isMember]="isMember"
            ></fee-and-tax-breakdown>
        </div>

        <!--Price breakdown-->
        <div
            *ngIf="
                !hidePriceTierBreakdown &&
                domesticPrivatePricing &&
                internationalPrivatePricing &&
                domesticCommercialPricing &&
                internationalCommercialPricing"
            class="col-12">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <!--No-rules display-->
                        <table *ngIf="priceBreakdownDisplayType == 0">
                            <thead>
                                <tr>
                                    <th>
                                        <div>Volume Tier</div>
                                    </th>
                                    <th>
                                        <div>All Flights</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="
                                        let priceRow of domesticPrivatePricing.pricingList;
                                        let indexOfRow = index
                                    "
                                >
                                    <td>
                                        <div>
                                            {{
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow]
                                                    .minGallons
            }}
            -
            {{
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow]
                                                    .maxGallons
            }}
        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow],
                                                [0, 1, 3],
                                                [0, 1, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                    [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    domesticPrivatePricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!--2-rule - departure-based display-->
                        <table *ngIf="priceBreakdownDisplayType == 1">
                            <thead>
                                <tr>
                                    <th>
                                        <div>Volume Tier</div>
                                    </th>
                                    <th>
                                        <div>Domestic Flights</div>
                                    </th>
                                    <th>
                                        <div>International Flights</div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    *ngFor="
                                        let priceRow of domesticPrivatePricing.pricingList;
                                        let indexOfRow = index
                                    "
                                >
                                    <td>
                                        <div>
                                            {{
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow]
                                                    .minGallons
                                            }}
                                            -
                                            {{
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow]
                                                    .maxGallons
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow],
                                                [0, 1, 3],
                                                [0, 1, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    domesticPrivatePricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                internationalPrivatePricing
                                                    .pricingList[indexOfRow],
                                                [0, 2, 3],
                                                [0, 1, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    internationalPrivatePricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!--2-rule flight-type-based display-->
                        <table *ngIf="priceBreakdownDisplayType == 2">
                            <thead>
                                <tr>
                                    <th>
                                        <div>Volume Tier</div>
                                    </th>
                                    <th>
                                        <div>Private Flights</div>
                                    </th>
                                    <th>
                                        <div>Commercial Flights</div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    *ngFor="
                                        let priceRow of domesticPrivatePricing.pricingList;
                                        let indexOfRow = index
                                    "
                                >
                                    <td>
                                        <div>
                                            {{
                                                internationalCommercialPricing
                                                    .pricingList[indexOfRow]
                                                    .minGallons | decimalPrecision
                                            }}
                                            -
                                            {{
                                                internationalCommercialPricing
                                                    .pricingList[indexOfRow]
                                                    .maxGallons | decimalPrecision
                                            }}
                                        </div>
                                    </td>

                                     <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow],
                                                [0, 1, 3],
                                                [0, 1, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    domesticPrivatePricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>

                                     <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                domesticCommercialPricing
                                                    .pricingList[indexOfRow],
                                                [0, 1, 3],
                                                [0, 2, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    domesticCommercialPricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <i
                                                *ngIf="!hideTooltips"
                                                aria-hidden="true"
                                                class="fa fa-external-link"
                                            ></i>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!--4-rule display-->
                        <table *ngIf="priceBreakdownDisplayType == 3">
                            <thead>
                                <tr>
                                    <th>
                                        <div>Volume Tier</div>
                                    </th>
                                    <th>
                                        <div>Int/Comm</div>
                                    </th>
                                    <th>
                                        <div>Int/Private</div>
                                    </th>
                                    <th>
                                        <div>Dom/Comm</div>
                                    </th>
                                    <th>
                                        <div>Dom/Private</div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    *ngFor="
                                        let priceRow of domesticPrivatePricing.pricingList;
                                        let indexOfRow = index
                                    "
                                >
                                    <td>
                                        <div>
                                            {{
                                                internationalCommercialPricing
                                                    .pricingList[indexOfRow]
                                                    .minGallons
                                            }}
                                            -
                                            {{
                                                internationalCommercialPricing
                                                    .pricingList[indexOfRow]
                                                    .maxGallons
                                            }}
                                        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                internationalCommercialPricing
                                                    .pricingList[indexOfRow],
                                                [0, 2, 3],
                                                [0, 2, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    internationalCommercialPricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                internationalPrivatePricing
                                                    .pricingList[indexOfRow],
                                                [0, 2, 3],
                                                [0, 1, 3]
                                            )
                                        "
                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    internationalPrivatePricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                domesticCommercialPricing
                                                    .pricingList[indexOfRow],
                                                [0, 1, 3],
                                                [0, 2, 3]
                                            )
                                        "
                                                                        (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    domesticCommercialPricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        (mouseenter)="
                                            mouseEnterPriceItem(
                                                domesticPrivatePricing
                                                    .pricingList[indexOfRow],
                                                [0, 1, 3],
                                                [0, 1, 3]
                                            )
                                        "

                                    (click)="openFeeAndTaxBreakdownPopUp()"
                                        [ngClass]="{'hover-mouseenter': !hideTooltips}"
                                    >
                                        <div>
                                            <div class="d-inline-block">
                                                ${{
                                                    domesticPrivatePricing
                                                        .pricingList[indexOfRow]
                                                        .allInPrice
                                                        | decimalPrecision
                                                }}
                                            </div>
                                            <div
                                                *ngIf="!hideTooltips"
                                                class="d-inline-block"
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    class="fa fa-external-link"
                                                ></i>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
       </div>

    </div>

    <div>
        <ngx-ui-loader
            [loaderId]="priceBreakdownLoader"
            bgsPosition="center-center"
            fgsColor="#252d47"
            fgsType="fading-circle"
            overlayColor="rgba(0, 0, 0, 0.06)"
            pbColor="#252d47"
        ></ngx-ui-loader>
    </div>
</div>
