<div
    *ngIf="isPopUpOpen"
    [@fadeInOut]="isPopUpOpen ? 'in' : 'out'"
    class="report-popup-container"
>
    <div class="popup-content mat-elevation-z8">
        <mat-toolbar class="element" color="primary">
            <mat-toolbar-row>
                <span>{{ report?.title }}</span>
                <span class="spacer"></span>
                <mat-icon class="close-icon" (click)="closePopUp()">close</mat-icon>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="element middle-element">
            <div
                *ngIf="isReportVisible[analiticsReportTypes.CustomerStatistics]"
            >
                <app-analytics-companies-quotes-deal
                    #customerStatitics
                ></app-analytics-companies-quotes-deal>
            </div>
            <div
                *ngIf="isReportVisible[analiticsReportTypes.ArrivalsDepartures]"
            >
                <app-analytics-airport-arrivals-depatures
                    #airportArrivalsDepartures
                    [customers]="customers"
                    (refreshCustomers)="getCustomersList()"
                ></app-analytics-airport-arrivals-depatures>
            </div>
            <div
                *ngIf="
                    isReportVisible[
                        analiticsReportTypes.FBONetworkArrivalDepartures
                    ]
                "
            >
                <app-group-analytics-intra-network-visits-report
                    #fboNetworkArrivalsDepartures
                ></app-group-analytics-intra-network-visits-report>
            </div>
            <div
                *ngIf="isReportVisible[analiticsReportTypes.LostToCompetition]"
            >
                <app-missedorders-grid #missedOrders></app-missedorders-grid>
            </div>
            <div
                *ngIf="
                    isReportVisible[
                        analiticsReportTypes.FuelerLinxCustomerCaptureRate
                    ]
                "
            >
                <app-customer-capture-rate
                    #customerCaptureRate
                ></app-customer-capture-rate>
            </div>
        </div>
        <div class="action-buttons">
            <div class="pull-right">
                <button
                    (click)="exportReport()"
                    class="mr-3"
                    ni-button
                    size="big"
                    view="accent"
                >
                    Export
                </button>
            </div>
        </div>
    </div>
</div>
