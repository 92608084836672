export enum UserRole {
    NotSet = 0,
    Primary = 1,
    GroupAdmin = 2,
    Conductor = 3,
    Member = 4,
    CSR = 5,
    NonRev = 6,
    X1 = 7
}
export enum AccountType {
    Premium = 0,
    Freemium = 1
}
