<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading">
    <div style="min-width:330px; max-width:330px;">
        <div>
            <div class="p-2">
                <div>
                    <span class="text-gray-dark">
                        {{customer}}
                    </span>
                    <span><i class="fa fa-external-link mr-1 cursor-pointer text-primary" (click)="goToCustomer()"></i></span>
                </div>
                <div>
                    <span class="text-gray-dark">
                        {{tailNumber}}
                    </span>
                    <span>
                        <i class="fa fa-external-link mr-1 cursor-pointer text-primary"
                           (click)="goToCustomerAircraft()">
                        </i>
                    </span>
                </div>
            </div>
            <div class="p-2" *ngIf="(customerNotes != undefined && customerNotes != '') || (customerAircraftNotes != undefined && customerAircraftNotes != '')">
                <div class="text-gray-dark">Customer Note (internal)</div>
                <div>
                    <div class="padding-5" style="width: 300px; max-width: 300px;">
                        {{customerNotes}}
                    </div>

                    <div class="padding-5" style="width: 300px; max-width: 300px;">
                        {{customerAircraftNotes}}
                    </div>
                    <div><hr /></div>
                </div>
            </div>
            <div class="p-2" *ngIf="serviceOrderNotes.length > 0">
                <div>Operator Request</div>
                <div *ngFor="let note of serviceOrderNotes">
                    <div class="pb-2">
                        <div *ngIf="!note.isEdit" class="padding-5" style="width: 300px; max-width: 300px;">
                            {{note}}
                        </div>
                    </div>
                </div>
                <div><hr /></div>
            </div>
            <div class="p-2">
                <div>Additional Notes (internal)</div>
                    <div *ngFor="let note of additionalNotes">
                        <div class="pb-2">
                            <div class="row">
                                <div class="col-7">
                                    <div *ngIf="!note.isEdit" class="padding-5" style="width: 220px; max-width: 220px;">
                                        {{note.note}}
                                    </div>
                                    <div *ngIf="note.isEdit" style="min-width:220px;">
                                        <mat-form-field>
                                            <textarea matInput [(ngModel)]="note.note" rows="6" cols="150"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-5 text-right">
                                    <button (click)="saveEditAdditionalNoteClicked(note)" *ngIf="note.isEdit" ni-button size="small" beforeIcon="fa fa-check" [view]="'success'">
                                    </button>
                                    <button (click)="editAdditionalNoteClicked(note)" *ngIf="!note.isEdit" ni-button size="small" beforeIcon="fa fa-pencil" [view]="'success'">
                                    </button>
                                    <button (click)="deleteAdditionalNoteClicked(note);" [view]="'error'" beforeIcon="fa fa-trash" ni-button size="small">
                                    </button>
                                </div>
                            </div>
                            <div><span class="bold">Note Added By: </span>{{note.addedByName}}</div>
                        </div>
                </div>
            </div>
            <div class="row p-2">
                <div class="col-9">
                    <mat-form-field>
                        <input matInput placeholder="Additional Notes (internal)" [(ngModel)]="newAdditionalNote" />
                    </mat-form-field>
                </div>
                <div class="col-2 mt-3">
                    <button [view]="'accent'"
                            beforeIcon="fa fa-plus"
                            class="action-btn"
                            ni-button
                            size="small"
                            (click)="addAdditionalNoteClicked()">
                        Add
                    </button>
                </div>
            </div>
        </div>
        <div class="navbar-footer m-md-3"></div>
    </div>
</div>
