<section>
    <div class="container-fluid">
        <div class="row mt-3">
            <div class="col-12">
                <ngx-ui-loader [loaderId]="fboHomeLoaderName"
                               bgsColor="rgb(100, 181, 246)"
                               bgsPosition="center-center"
                               bgsType="ball-spin"
                               overlayColor="rgba(0, 0, 0, 0.1)"></ngx-ui-loader>
            </div>
        </div>

        <div *ngIf="!airportFboGeofenceGridItem && airportFboGeoFenceGridData" class="row">
            <div *ngIf="fboGeofencingData" class="col-12">
                <app-fbo-geofencing-grid [fboGeofencingData]="fboGeofencingData" [airportFboGeoFenceGridData]="airportFboGeoFenceGridData" (onEditRow)="onEditAirportFboGeoFence($event)" (onAddAirport)="onAddAirportFboGeoFence()"></app-fbo-geofencing-grid>
            </div>
        </div>
        <div *ngIf="airportFboGeofenceGridItem" class="row">
            <div class="col-12">
                <app-fbo-geofencing-map [airportFboGeofenceGridItem]="airportFboGeofenceGridItem" (onCloseEditing)="onEditClosed($event)"></app-fbo-geofencing-map>
            </div>
        </div>
    </div>




</section>
