export enum FlightLegStatus {
    EnRoute,
    Landing,
    TaxiingDestination,
    Arrived,
    Departing,
    TaxiingOrigin
}
export enum SwimType {
    Arrival ,
    Departure
}
export enum coordinatesSource {
    Antenna = 'Antenna',
    Swim = 'SWIM',
    None = 'None'
}
