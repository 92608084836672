<h1 mat-dialog-title>New Customer Tag</h1>
<div mat-dialog-content>
    <div>
        <div>
            <mat-form-field>
                <input [(ngModel)]="data.name" aria-label="Tag" matInput placeholder="Tag" />
            </mat-form-field>
        </div>
    </div>
</div>
<div class="mt-2 text-center">
    <button
        [mat-dialog-close]="data"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        Add Tag
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
</div>