<section>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
    <div *ngIf="(groupsFbosData || currentGroup) && !isDeletingGroup">
        <div *ngIf="currentGroup">
            <app-groups-edit
                (cancelClicked)="cancelGroupEditClicked()"
                (saveClicked)="saveGroupEditClicked()"
                [groupInfo]="currentGroup"
            ></app-groups-edit>
        </div>
        <div *ngIf="!currentGroup">
            <app-groups-grid
                (editFboClicked)="editFboClicked($event)"
                (editGroupClicked)="editGroupClicked($event)"
                [groupsFbosData]="groupsFbosData"
                [groupGridState]="groupGridState"
                (deleteGroupClicked)="deleteGroup($event)"
            ></app-groups-grid>
        </div>
    </div>
</section>
