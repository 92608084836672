<ni-card [headerBgColor]="'gray'" title="Activity Reports">
    <ng-container *ngFor="let report of reports">
        <div class="report-item" *ngIf="!report.isHidden">
            <div class="report-title" (click)="openReport(report)">
              <p>{{report.title}}</p>
            </div>
            <div class="report-description">
                <p>{{report.description}}</p>
            </div>
          </div>
    </ng-container>
</ni-card>
