<h1 mat-dialog-title>New Service Order</h1>
<div mat-dialog-content>

    <div *ngIf="!selectedServiceOrder">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div *ngIf="selectedServiceOrder">
            <app-service-orders-item-list [serviceOrder]="selectedServiceOrder"
                                          (closeClicked)="dialogRef.close();"
                                          [isFreemiumAccount]="isFreemiumAccount"
                                          [showClose]="false"></app-service-orders-item-list>
    </div>

</div>
<div *ngIf="errorMessage != ''" class="mt-2 text-center" style="color: red">
    <p>{{errorMessage}}</p>
</div>
<div class="mt-2 text-center">
    <button (click)="dialogRef.close();" mat-stroked-button>Close</button>
</div>



