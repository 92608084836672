<section>
    <div *ngIf="pricingTemplatesData">
        <app-pricing-templates-grid
            (deletePricingTemplateClicked)="
                deletePricingTemplateClicked($event)
            "
            (editPricingTemplateClicked)="editPricingTemplateClicked($event)"
            (newPricingTemplateAdded)="newPricingTemplateAdded()"
            [pricingTemplatesData]="pricingTemplatesData"
        ></app-pricing-templates-grid>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
</section>
