

    <div class="d-flex justify-content-around price-checker">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field>
                                    <mat-select placeholder="Check Price By:" [(ngModel)]="tab" (selectionChange)="onOptionChange($event)">
                                        <mat-option value="customer">
                                            Customer
                                        </mat-option>
                                        <mat-option value="tail">
                                            Tail Number
                                        </mat-option>
                                        <mat-option value="template">
                                            Template
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <!--<mat-tab-group (selectedTabChange)="onTabChanged($event)">-->
                    <!--Customer searching tab-->
                    <!--<mat-tab label="Customer">-->
                    <div class="container-fluid" *ngIf="tab === 'customer'">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <app-autocomplete-search label="Customer"
                                                         [optionValue]="'company'"
                                                         [options]="allCustomers"
                                                         [displayFn]="displayCustomerName"
                                                         (selectionChanged)="
                                            customerForLookupChanged($event)
                                        "></app-autocomplete-search>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field>
                                    <mat-label>Tail Number</mat-label>
                                    <mat-select (selectionChange)="
                                                customerForLookupTailChanged()
                                            "
                                                [(ngModel)]="
                                                tailNumberForCustomerLookup
                                            "
                                                [disabled]="
                                                !customerForCustomerLookup
                                            "
                                                placeholder="Tail Number">
                                        <mat-option *ngFor="
                                                    let aircraft of aircraftForCustomer
                                                "
                                                    [value]="aircraft.tailNumber">
                                            {{ aircraft.tailNumber }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!--</mat-tab>-->
                    <!--Aircraft searching tab-->
                    <!--<mat-tab class="mb-4" label="Aircraft">-->
                    <div class="container-fluid" *ngIf="tab === 'tail'">
                        <div class="row">
                            <div class="col">
                                <app-autocomplete-search label="Tail Number*"
                                                         [options]="allTailNumbers"
                                                         (selectionChanged)="
                                            tailNumberLookupChanged($event)
                                        "></app-autocomplete-search>
                            </div>
                            <div class="col">
                                <mat-form-field>
                                    <mat-label>Customer</mat-label>
                                    <mat-select (selectionChange)="
                                                tailNumberLookupCustomerChanged()
                                            "
                                                [(ngModel)]="customerForTailLookup"
                                                [disabled]="
                                                !customerForTailLookup ||
                                                !customersForTail
                                            "
                                                placeholder="Customer">
                                        <mat-option *ngFor="
                                                    let customer of customersForTail
                                                "
                                                    [value]="customer">
                                            {{ customer.company }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!--</mat-tab>-->
                    <!--Price template searching tab-->
                    <!--<mat-tab label="Template">-->
                    <div *ngIf="tab == 'template'">
                        <mat-form-field>
                            <mat-label>ITP Template</mat-label>
                            <mat-select (selectionChange)="priceTemplateChanged()"
                                        [(ngModel)]="pricingTemplateId"
                                        class="font-weight-bold"
                                        placeholder="ITP Template">
                                <mat-option *ngFor="
                                            let pricingTemplate of pricingTemplates
                                        "
                                            [value]="pricingTemplate.oid">
                                    {{ pricingTemplate.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!--</mat-tab>
        </mat-tab-group>-->
                </div>

                <!--"Check Pricing" button column-->
                <!--<div class="col-2 mt-5">
      <div class="text-center">
        <button mat-raised-button
                color="accent"
                class="mr-1 updateBtn"
                (click)="lookupPricing()">
          Check Pricing
        </button>
      </div>
    </div>-->
                <!--Pricing template/company information-->
                <div class="col-12">
                    <div class="row" style="font-size: 14px">
                        <div *ngIf="tailLookupError"
                             class="h6 mt-0 mb-0 text-danger pl-5">
                            No Tail Price Found!
                        </div>

                        <div *ngIf="
                        priceLookupInfo &&
                        !tailLookupError" class="col-12">
                            <div class="container-fluid">
                                <div class="row pb-2">
                                    <div class="col-6 font-weight-bold">
                                        Pricing Template:
                                    </div>
                                    <div class="col-6">
                                        {{
                                    priceLookupInfo?.template
                                        ? priceLookupInfo.template
                                        : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6 font-weight-bold">Company:</div>
                                    <div class="col-6">
                                        {{
                                    priceLookupInfo?.company
                                        ? priceLookupInfo.company
                                        : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row pb-2" *ngIf="priceCheckerLookupType != 0">
                                    <div class="col-6 font-weight-bold">
                                        Make/Model:
                                    </div>
                                    <div class="col-6">
                                        {{
                                    priceLookupInfo?.makeModel
                                        ? priceLookupInfo.makeModel
                                        : "N/A"
                                        }}
                                    </div>
                                </div>
                                <div class="row pb-2" *ngIf="priceCheckerLookupType != 0">
                                    <div class="col-6 font-weight-bold">Ramp Fee:</div>
                                    <div *ngIf="priceLookupInfo?.rampFee" class="col-12 col-md-4">
                                        ${{
                                    priceLookupInfo?.rampFee.price
                                        | decimalPrecision
                                        }}
                                        waived at
                                        {{
                                    priceLookupInfo.rampFee.waived
                                        | decimalPrecision
                                        }}
                                        gal.
                                    </div>
                                    <div *ngIf="!priceLookupInfo?.rampFee" class="col-12 col-md-4">
                                        N/A
                                    </div>
                                </div>
                                <div *ngIf="priceLookupInfo?.rampFee && priceCheckerLookupType != 0" class="row">
                                    <div class="col-6 font-weight-bold">
                                        Ramp Fee Rule:
                                    </div>
                                    <div class="col-12 col-md-4">
                                        {{
                                    priceLookupInfo.rampFee
                                        .categorizationDescription
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="sampleCalculation" class="container-fluid pb-4 sub-widget">
        <div class="row mt-4 mb-2" *ngIf="isPriceBreakdownCustomerActive">

            <div *ngIf="!hideFeeAndTaxGeneralBreakdown"
                 class="col font-weight-bold"
                 style="padding-left: 53px">
                Taxes and Fees
            </div>

            <div *ngIf="!hidePriceTierBreakdown"
                 class="col font-weight-bold"
                 style="padding-left: 16px">
                Customer's All-In Rate
            </div>
        </div>

        <div class="row mt-4 price-lookup-breakdown" *ngIf="feesAndTaxes">
            <div class="col-12" style="font-size: 14px">
                <price-breakdown #priceBreakdownPreview
                                 (calculationsComplated)="
                    priceBreakdownCalculationsCompleted($event)
                "
                                 [customerInfoByGroupId]="
                    sampleCalculation.customerInfoByGroupId
                "
                                 [hideFeeAndTaxGeneralBreakdown]="hideFeeAndTaxGeneralBreakdown"
                                 [hidePriceTierBreakdown]="hidePriceTierBreakdown"
                                 [feeAndTaxDisplayMode]="2"
                                 [priceTemplateId]="sampleCalculation.pricingTemplateId"
                                 [showFeeAndTaxLineSeparator]="true"
                                 [feesAndTaxes]="feesAndTaxes"
                                 [tailNumber]="sampleCalculation.tailNumber"
                                 [hideTooltips]="hideTooltips"
                                 (customerActiveCheckCompleted)="
                    priceBreakdownCustomerActiveCheckCompleted($event)"
                                 (pricesExpiredCheckCompleted)="
                    priceBreakdownPricesExpiredCheckCompleted($event)">
                </price-breakdown>
            </div>
        </div>
    </div>

    <ngx-ui-loader [loaderId]="tailLoader"
                   bgsPosition="center-center"
                   fgsColor="#252d47"
                   fgsType="fading-circle"
                   overlayColor="rgba(0, 0, 0, 0.06)"
                   pbColor="#252d47"></ngx-ui-loader>
