<section>
    <mat-tab-group style="background: white" [selectedIndex]="selectedTabIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                Customers ({{ customersCount }})
            </ng-template>
            <div class="table-container customers-grid-container">
                <div *ngIf="customersData && pricingTemplatesData">
                    <app-customers-grid
                        (customerDeleted)="customerDeleted()"
                        (editCustomerClicked)="editCustomerClicked($event)"
                        (exportAircraftClick)="exportAircraftClick($event)"
                        [aircraftData]="aircraftData"
                        [customerGridState]="customerGridState"
                        [customersData]="customersData"
                        [pricingTemplatesData]="pricingTemplatesData"
                        [fuelVendors]="fuelVendors"
                        [tags]="tags"
                        (customerPriceClicked)="customerPriceClicked($event)"
                        (refreshAircrafts)="refreshAircrafts()"
                        (onCompanyFilterApplied)="onCompanyFilterApplied($event)"
                    ></app-customers-grid>
                </div>
                <ngx-ui-loader
                    [loaderId]="charNameCustomer"
                    bgsColor="rgb(100, 181, 246)"
                    bgsPosition="center-center"
                    bgsType="ball-spin"
                    overlayColor="rgba(0, 0, 0, 0.1)"
                ></ngx-ui-loader>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                Aircraft ({{ aircraftData?.length }})
            </ng-template>
            <div class="table-container aircrafts-grid">
                <div *ngIf="pricingTemplatesData && aircraftData">
                    <app-aircrafts-grid
                        [aircraftsData]="aircraftData"
                        [pricingTemplatesData]="pricingTemplatesData"
                        (refreshAircrafts)="refreshAircrafts()"
                    ></app-aircrafts-grid>
                </div>
                <ngx-ui-loader
                    [loaderId]="charNameAircraft"
                    bgsColor="rgb(100, 181, 246)"
                    bgsPosition="center-center"
                    bgsType="ball-spin"
                    overlayColor="rgba(0, 0, 0, 0.1)"
                ></ngx-ui-loader>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>
