<app-analytics-report-popup
    [report]="selectedRerport"
></app-analytics-report-popup>
<ni-card bgColor="main">
    <div class="row mt-3">
        <div class="col-md-6 col-xl-3">
            <app-statistics-total-customers
                [endDate]="filterEndDate"
                [startDate]="filterStartDate"
            ></app-statistics-total-customers>
        </div>

        <div class="col-md-6 col-xl-3">
            <app-statistics-total-aircraft
                [endDate]="filterEndDate"
                [startDate]="filterStartDate"
            ></app-statistics-total-aircraft>
        </div>

        <div class="col-md-6 col-xl-3">
            <app-statistics-total-orders
                [endDate]="filterEndDate"
                [startDate]="filterStartDate"
            ></app-statistics-total-orders>
        </div>

        <div class="col-md-6 col-xl-3">
            <app-statistics-orders-by-location
                [endDate]="filterEndDate"
                [startDate]="filterStartDate"
            ></app-statistics-orders-by-location>
        </div>
    </div>
    <section class="row">
        <div class="col-12">
            <app-analytics-activity-reports
                (reportClicked)="openReport($event)"
            ></app-analytics-activity-reports>
        </div>
    </section>
    <div class="row">
        <div class="col-md-6">
            <app-analytics-fuel-vendor-source></app-analytics-fuel-vendor-source>
        </div>
        <div class="col-md-6">
            <app-analytics-market-share-fbo-airport></app-analytics-market-share-fbo-airport>
        </div>
        <div class="col-md-6">
            <app-analytics-orders-quote></app-analytics-orders-quote>
        </div>
        <div class="col-md-6">
            <app-analytics-orders-over-time></app-analytics-orders-over-time>
        </div>
        <div class="col-md-6">
            <app-analytics-customer-breakdown></app-analytics-customer-breakdown>
        </div>
        <div class="col-md-6">
            <app-analytics-volumes-nearby-airport></app-analytics-volumes-nearby-airport>
        </div>
    </div>
</ni-card>
