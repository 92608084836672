<div class="mt-4 mb-4 text-center" mat-dialog-content style="max-width: 600px">
    <img height="60" src="/assets/img/landing-page/green-tick.gif" width="60" />
    <h5>Thank you! Your data has been submitted.</h5>
    <button
        (click)="onCancelClick()"
        class="mt-3"
        color="accent"
        mat-flat-button
    >
        Close
    </button>
</div>
