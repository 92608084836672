<app-flight-watch-map
    #map
    (setIcaoList)="setIcaoList.emit($event)"
    (markerClicked)="this.aicraftClick.emit($event)"
    (popUpClosed)="popUpClosed.emit($event)"
    (updatePopUpData)="updatePopUpData($event)"
    (getLatestData)="getLatestData($event)"
    style="width: 100%"
    [selectedPopUp]="selectedPopUp"
    [center]="center"
    [data]="flightWatchDictionary"
    [icao]="icao"
    [isStable]="isStable"
></app-flight-watch-map>

<div class="map-controls-button layout-setting-button" *ngIf="showFilters">
    <button
        class="mat-black"
        mat-flat-button
        (click)="showLayers = !showLayers"
    >
        <mat-icon>layers</mat-icon>
    </button>
</div>
<div class="map-controls-container" *ngIf="showLayers">
    <mat-card>
        <mat-card-title>
            <div>Layout Settings</div>
            <div
                style="
                    position: absolute;
                    top: 5px;
                    right: 9px;
                    cursor: pointer;
                "
                (click)="showLayers = !showLayers"
            >
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="map-control-content">
                <div class="font-weight-bold">App Layout</div>
                <div>
                    <div
                        class="slide-toggle-label"
                        [ngClass]="
                            isCommercialVisible ? 'slide-toggle-label-on' : ''
                        "
                    >
                        Show Commercial Aircraft
                    </div>
                    <div class="slide-toggle">
                        <span
                            class="mat-slide-toggle-content"
                            style="margin-left: 6px"
                            *ngIf="isCommercialVisible"
                            >ON</span
                        >
                        <mat-slide-toggle
                            [checked]="isCommercialVisible"
                            (toggleChange)="toggleCommercial($event)"
                        ></mat-slide-toggle>
                        <span
                            class="mat-slide-toggle-content"
                            style="margin-left: -20px"
                            *ngIf="!isCommercialVisible"
                            >OFF</span
                        >
                    </div>
                </div>
                <div>
                    <div
                        class="slide-toggle-label"
                        [ngClass]="
                            isShowAirportCodesEnabled
                                ? 'slide-toggle-label-on'
                                : ''
                        "
                    >
                        Show Airport Identifier
                    </div>
                    <div class="slide-toggle">
                        <span
                            class="mat-slide-toggle-content"
                            style="margin-left: 6px"
                            *ngIf="isShowAirportCodesEnabled"
                            >ON</span
                        >
                        <mat-slide-toggle
                            [checked]="isShowAirportCodesEnabled"
                            (toggleChange)="toggleLayer('icao')"
                        ></mat-slide-toggle>
                        <span
                            class="mat-slide-toggle-content"
                            style="margin-left: -20px"
                            *ngIf="!isShowAirportCodesEnabled"
                            >OFF</span
                        >
                    </div>
                </div>
                <div>
                    <div
                        class="slide-toggle-label"
                        [ngClass]="
                            isShowTaxiwaysEnabled ? 'slide-toggle-label-on' : ''
                        "
                    >
                        Show Taxiways
                    </div>
                    <div class="slide-toggle">
                        <span
                            class="mat-slide-toggle-content"
                            style="margin-left: 6px"
                            *ngIf="isShowTaxiwaysEnabled"
                            >ON</span
                        >
                        <mat-slide-toggle
                            [checked]="isShowTaxiwaysEnabled"
                            (toggleChange)="toggleLayer('taxiway')"
                        ></mat-slide-toggle>
                        <span
                            class="mat-slide-toggle-content"
                            style="margin-left: -20px"
                            *ngIf="!isShowTaxiwaysEnabled"
                            >OFF</span
                        >
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<div class="map-filters-container" *ngIf="showFilters">
    <app-flight-watch-filters
    [icaoList]="icaoList"
    [icao]="icao"
    (textFilterChanged)="textFilterChanged.emit($event)"
    (icaoChanged)="icaoChanged.emit($event)"
    (updateDrawerButtonPosition)="updateDrawerButtonPosition.emit()"
    ></app-flight-watch-filters>
</div>
<ngx-ui-loader
[loaderId]="chartName"
bgsColor="rgb(100, 181, 246)"
bgsPosition="center-center"
bgsType="ball-spin"
overlayColor="rgba(0, 0, 0, 0.1)"
></ngx-ui-loader>
