<div>
    <mat-form-field>
        <input
            (keyup)="applyFilter($event.target.value)"
            matInput
            placeholder="Search by Name, Role, Username, etc."
        />
        <span matPrefix><i class="fa fa-search"></i></span>
    </mat-form-field>

    <table
        [dataSource]="usersDataSource"
        class="col-md-12"
        mat-table
        matSort
        matSortActive="lastName"
        matSortDirection="asc"
    >
        <ng-container matColumnDef="firstName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                First Name
            </th>
            <td *matCellDef="let user" mat-cell>{{ user.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Last Name</th>
            <td *matCellDef="let user" mat-cell>{{ user.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="username">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Username</th>
            <td *matCellDef="let user" mat-cell>{{ user.username }}</td>
        </ng-container>

        <ng-container matColumnDef="roleDescription">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
            <td *matCellDef="let user" mat-cell>{{ user.roleDescription }}</td>
        </ng-container>

        <ng-container matColumnDef="copyAlerts">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                Copy On Distribution?
            </th>
            <td *matCellDef="let user" mat-cell>
                <mat-slide-toggle
                    (click)="UpdateCopyAlertsValue(user)"
                    [(ngModel)]="user.copyAlerts"
                    [checked]="user.copyAlerts"
                    color="accent"
                ></mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="copyOrders">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                Copy On Orders?
            </th>
            <td *matCellDef="let user" mat-cell>
                <mat-slide-toggle
                    (click)="UpdateCopyOrdersValue(user)"
                    [(ngModel)]="user.copyOrders"
                    [checked]="user.copyOrders"
                    color="accent"
                ></mat-slide-toggle>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th *matHeaderCellDef mat-header-cell>
                <div class="pull-right">
                    <button
                        (click)="newRecord()"
                        [view]="'accent'"
                        beforeIcon="fa fa-plus"
                        ni-button
                    >
                        Add New
                    </button>
                </div>
            </th>
            <td *matCellDef="let user" mat-cell>
                <div class="pull-right">
                    <button
                        (click)="deleteRecord(user); $event.stopPropagation()"
                        [view]="'error'"
                        beforeIcon="fa fa-trash"
                        ni-button
                        size="small"
                    >
                        Delete
                    </button>
                </div>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
            (click)="editRecord(row, $event)"
            *matRowDef="let row; columns: displayedColumns"
            mat-row
        ></tr>
    </table>
</div>

<div>
    <mat-paginator [length]="resultsLength" [pageSize]="10"></mat-paginator>
</div>
