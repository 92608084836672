<h1 mat-dialog-title>Ramp Fees Import Information</h1>
<div class="mt-1" mat-dialog-content>
    <div>
        <p>
            Please make sure the first row of the file contains the column
            names.
        </p>
    </div>
</div>
<div class="pull-right" mat-dialog-actions>
    <button (click)="onCancelClick()" color="warn" mat-button>
        Let me fix it first!
    </button>
    <button (click)="onOkClick()" color="success" mat-raised-button>
        I Understand
    </button>
</div>
