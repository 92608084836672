    <section class="container-fluid">
        <div class="row mt-3">
            <div class="col-12">
                <ngx-ui-loader [loaderId]="antennaStatusHomeLoaderName"
                               bgsColor="rgb(100, 181, 246)"
                               bgsPosition="center-center"
                               bgsType="ball-spin"
                               overlayColor="rgba(0, 0, 0, 0.1)"></ngx-ui-loader>
            </div>
        </div>

        <div *ngIf="antennaStatusGridData" class="row">
            <div class="col-12">
                <app-antenna-status-grid [antennaStatusData]="antennaStatusData" [antennaStatusGridData]="antennaStatusGridData"></app-antenna-status-grid>
            </div>
        </div>
    </section>
