<div class="chart-container">
    <div class="flex position-relative">
        <app-report-filters
            [hiddenFilters]="reportHiddenItems"
            [icao]="icaoFilter"
            [dataSource]="dataSource"
            [selectedDateFilter]="selectedDateFilter"
            (onDateChange)="applyPresetDateFilter($event)"
        >
        </app-report-filters>
        <div class="actions">
            <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                <mat-icon
                    aria-hidden="true"
                    class="mat-icon material-icons"
                    role="img"
                    >settings</mat-icon
                >
            </button>
            <mat-menu
                #gridActions="matMenu"
                class="switch-nested-menu-left-arrows"
                xPosition="before"
            >
                <button (click)="openSettings()" mat-menu-item>Settings</button>
            </mat-menu>
        </div>
    </div>
    <div
        class="table-container mat-elevation-z3"
        (scroll)="onTableScroll($event)"
    >
        <table [dataSource]="dataSource" class="col-12 mb-0" mat-table matSort>
            <ng-container matColumnDef="customerName">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    Flight Dept.
                </th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.customerName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="itpMarginTemplate">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    ITP Margin Template
                </th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.itpMarginTemplate }}
                </td>
            </ng-container>

            <ng-container matColumnDef="eta">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>ETA</th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.eta }}
                </td>
            </ng-container>

            <ng-container matColumnDef="etd">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>ETD</th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.etd }}
                </td>
            </ng-container>

            <ng-container matColumnDef="volume">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    Volume (gal.)
                </th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.volume }} gal.
                </td>
            </ng-container>

            <ng-container matColumnDef="tailNumber">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    Tail #
                </th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.tailNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="createdDate">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    Created
                </th>
                <td *matCellDef="let missedorder" mat-cell>
                    {{ missedorder.createdDate }}
                </td>
            </ng-container>

            <tr
                *matHeaderRowDef="visibleColumns; sticky: true"
                mat-header-row
            ></tr>
            <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
        </table>
        <div class="invisible">
            <mat-paginator
                (page)="(0); onPageChanged($event)"
                [length]="resultsLength"
                [pageSizeOptions]="[10, 25, 50, 100]"
                [pageSize]="pageSize"
            ></mat-paginator>
        </div>
    </div>
</div>
<ngx-ui-loader
    [loaderId]="chartName"
    bgsColor="rgb(100, 181, 246)"
    bgsPosition="center-center"
    bgsType="ball-spin"
    overlayColor="rgba(0, 0, 0, 0.1)"
></ngx-ui-loader>
