<h1 mat-dialog-title>Send Email</h1>
<div mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Email Template" [formGroup]="form">
            <mat-form-field style="max-width: 200px">
                <input aria-label="From email"
                       formControlName="fromAddress"
                       matInput
                       placeholder="From email" />
                <mat-error *ngIf="form.controls.fromAddress.invalid">Invalid email address.</mat-error>
            </mat-form-field>
            @FBOLinx.com

            <mat-form-field style="max-width: 600px">
                <input aria-label="Reply To"
                       formControlName="replyTo"
                       matInput
                       placeholder="Reply To" />
                <mat-error *ngIf="form.controls.replyTo.invalid">Invalid email address.</mat-error>
            </mat-form-field>

            <mat-form-field style="max-width: 600px">
                <input aria-label="Subject"
                       formControlName="subject"
                       matInput
                       placeholder="Subject" />
                <mat-error *ngIf="form.controls.subject.invalid">Subject is required.</mat-error>
            </mat-form-field>

            <ejs-richtexteditor #typeEmail formControlName="emailContentHtml"
                                placeholder="Enter email body here..."></ejs-richtexteditor>
        </mat-tab>
        <mat-tab label="Customer Emails">
            <ejs-grid #grid height="300px" [dataSource]="gridData">
                <e-columns>
                    <e-column field="company" headerText="Company"></e-column>
                    <e-column
                        field="firstName"
                        headerText="First Name"
                    ></e-column>
                    <e-column
                        field="lastName"
                        headerText="Last Name"
                    ></e-column>
                    <e-column field="email" headerText="Email"></e-column>
                </e-columns>
            </ejs-grid>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button [disabled]="form.invalid" (click)="onSendClick()" color="accent" mat-raised-button>
        Send Email
    </button>
</div>
