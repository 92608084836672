<h1 mat-dialog-title>New Email Template</h1>
<div mat-dialog-content>
    <div>
        <div *ngIf="!this.data.hideName">
            <mat-form-field>
                <input
                    aria-label="Name"
                    matInput
                    [(ngModel)]="data.name"
                    placeholder="Name"
                />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field style="max-width: 600px">
                <input
                    aria-label="Subject"
                    [(ngModel)]="data.subject"
                    matInput
                    placeholder="Subject"
                />
            </mat-form-field>
        </div>

        <ejs-richtexteditor
            #typeEmail
            id="rteEmail"
            [(ngModel)]="data.emailContentHtml"
            placeholder="Enter email body here..."
            [insertImageSettings]='insertImageSettings'
            (imageSelected)='onImageSelected($event)'
            (beforeImageDrop)='onImageDrop($event)'
        ></ejs-richtexteditor>
    </div>
</div>
<div mat-dialog-actions>
    <button
        [disabled]="isSubmitDisabled"
        [mat-dialog-close]="data"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        {{ submitButtonTitle }}
    </button>
    <button [mat-dialog-close]="" class="mr-1" mat-stroked-button>
        Cancel
    </button>
</div>
