<h1 mat-dialog-title>
    Account Profile
    <a (click)="onCancelClick()" class="float-right close-btn">
        <mat-icon>close</mat-icon>
    </a>
</h1>

<div mat-dialog-content>
    <div *ngIf="!availableroles">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="availableroles">
        <mat-tab-group>
            <!--Account Login Info-->
            <mat-tab label="Login Info">
                <div class="container-fluid mt-2">
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field>
                                <input [(ngModel)]="data.firstName"
                                       aria-label="First Name"
                                       matInput
                                       placeholder="First Name" />
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>
                                <input [(ngModel)]="data.lastName"
                                       aria-label="Last Name"
                                       matInput
                                       placeholder="Last Name" />
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>
                                <input #userLoginEmail="ngModel"
                                       [(ngModel)]="data.username"
                                       [email]="true"
                                       aria-label="Email Login"
                                       matInput
                                       placeholder="Email Login"
                                       type="email" />
                            </mat-form-field>
                            <div *ngIf="userLoginEmail.errors?.email"
                                 style="color: red">
                                Please enter a valid email address.
                            </div>
                        </div>
                        <div class="col-6">
                            <mat-form-field *ngIf="availableroles">
                                <mat-select [(ngModel)]="data.role"
                                            disabled
                                            placeholder="Role">
                                    <mat-option *ngFor="let role of availableroles"
                                                [value]="role.value">
                                        {{ role.description }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>
                                <input [(ngModel)]="data.newPassword"
                                       aria-label="Password"
                                       matInput
                                       placeholder="Password"
                                       type="password" />
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field>
                                <input [(ngModel)]="data.confirmPassword"
                                       aria-label="Confirm Password"
                                       matInput
                                       placeholder="Confirm Password"
                                       type="password" />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="
                             data.confirmPassword &&
                             data.confirmPassword.length>
                            0 &&
                            data.newPassword &&
                            data.newPassword.length > 0 &&
                            data.newPassword != data.confirmPassword
                            "
                            class="text-danger"
                            >
                            Passwords must match
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-2 text-center">
                            <button [disabled]="
                                    !data.confirmPassword ||
                                    !data.newPassword ||
                                    data.confirmPassword !== data.newPassword ||
                                    userLoginEmail.errors?.email
                                "
                                    [mat-dialog-close]="data"
                                    class="mr-1"
                                    color="accent"
                                    mat-raised-button>
                                Save
                            </button>
                            <button (click)="onCancelClick()"
                                    mat-stroked-button>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>

            <mat-tab *ngIf="!isCsr" label="System Contacts">
                <form (ngSubmit)="onSaveSystemContacts()"
                      [formGroup]="systemContactsForm">
                    <div class="mt-4 mb-2 pl-4">
                        <p>
                            Add contacts at your FBO to copy on relevant system
                            emails, such as fuel requests.
                        </p>
                        <mat-form-field style="max-width: 240px">
                            <input aria-label="FuelDesk Email"
                                   formControlName="fuelDeskEmail"
                                   matInput
                                   placeholder="FuelDesk Email"
                                   required />
                        </mat-form-field>

                        <button [disabled]="!systemContactsForm.valid"
                                class="ml-3"
                                color="accent"
                                mat-raised-button
                                type="submit">
                            Save Email
                        </button>

                        <div class="d-inline-block float-right"
                             style="margin-top: 12px">
                            <button (click)="newRecord($event)"
                                    [view]="'accent'"
                                    beforeIcon="fa fa-plus"
                                    ni-button>
                                Add New
                            </button>
                        </div>

                        <div *ngIf="fuelDeskEmail.errors?.email"
                             style="color: red">
                            Please enter a valid email address.
                        </div>
                    </div>
                </form>

                <!--Contacts Grid-->
                <div *ngIf="contactsData">
                    <app-systemcontacts-grid (contactDeleted)="contactDeleted($event)"
                                             [contactsData]="contactsData"></app-systemcontacts-grid>
                </div>
            </mat-tab>

            <mat-tab label="Email Distribution">
                <form (ngSubmit)="onSaveEmailDistribution()"
                      [formGroup]="emailDistributionForm">
                    <div class="mt-4 mb-2 pl-4">
                        <mat-form-field style="max-width: 240px">
                            <input formControlName="senderAddress"
                                   matInput
                                   placeholder="Sender Address"
                                   matTooltip="Your distribution emails will come from this address"
                                   matTooltipPosition="after"
                                   required />
                        </mat-form-field>
                        @FBOLinx.com
                        <div *ngIf="senderAddress.errors?.pattern"
                             style="color: red">
                            Please enter with no spaces.
                        </div>
                        <div>
                            <mat-form-field style="max-width: 240px">
                                <input formControlName="replyTo"
                                       matInput
                                       placeholder="ReplyTo"
                                       matTooltip="For distribution emails, customer replies will default to this address"
                                       matTooltipPosition="after"
                                       required />
                            </mat-form-field>
                            <div *ngIf="replyTo.errors?.email"
                                 style="color: red">
                                Please enter a valid email address.
                            </div>
                        </div>
                        <div class="mt-4 mb-2 pl-4">
                            <button [disabled]="!emailDistributionForm.valid"
                                    class="ml-3"
                                    color="accent"
                                    mat-raised-button
                                    type="submit">
                                Save Emails
                            </button>
                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Company">
                <div class="mt-4 mb-2 pl-4">
                    <label>Upload your logo</label><br />
                    <input type="file"
                           (change)="onFileChange($event)"
                           accept=".jpg,.jpeg,.png" />
                    <button *ngIf="!isUploadingLogo"
                            (click)="uploadFile()"
                            class="ml-3"
                            color="accent"
                            mat-raised-button>
                        Upload File
                    </button>
                    <div *ngIf="isUploadingLogo">
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
                <form [formGroup]="companyForm" *ngIf="fboPreferencesData">
                    <div class="row border-top row-padding">
                        <div class="col">Receive FuelerLinx contract fuel order notifications</div>
                        <div class="col text-center">
                            <mat-slide-toggle color="accent" (click)="onOrderNotificationsChange()" formControlName="orderNotifications"></mat-slide-toggle>
                        </div>
                    </div>
                    <div class="row border-top row-padding">
                        <div class="col">Direct Orders Notifications</div>
                        <div class="col text-center">
                            <mat-slide-toggle color="accent" (click)="onDirectOrderNotificationsChange()" formControlName="directOrderNotifications"></mat-slide-toggle>
                        </div>
                    </div>
                </form>
                <div *ngIf="logoUrl">
                    <img src="{{ 'data:' + logoUrl }}" />

                    <div class="mt-4 mb-2 pl-4">
                        <button (click)="deleteFile()"
                                class="mr-1"
                                color="warn"
                                mat-raised-button>
                            Delete
                        </button>
                    </div>
                </div>
            </mat-tab>

            <mat-tab label="Products">
                <form [formGroup]="productsForm" *ngIf="fboPreferencesData">
                    <div class="mt-4 mb-2 pl-4 container">
                        <div class="row border-bottom row-padding">
                            <div class="col cell-padding">Product</div>
                        </div>
                        <div class="row border-bottom row-padding">
                            <div class="col">JetA</div>
                            <div class="col text-center">
                                <mat-slide-toggle color="accent" (click)="onProductsChange('JetA')" formControlName="enableJetA"></mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row border-bottom row-padding">
                            <div class="col">Sustainable Fuel (SAF)</div>
                            <div class="col text-center">
                                <mat-slide-toggle color="accent" (click)="onProductsChange('SAF')" formControlName="enableSaf"></mat-slide-toggle>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Settings">
                <form  *ngIf="fboPreferencesData">
                    <div class="mt-4 mb-2 pl-4 container">
                        <div class="row border-bottom row-padding">
                            <div class="col text-center">Number of decimals to display</div>
                            <div class="col text-center">
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field>
                                        <input matInput class="text-center" type="number"
                                        name="numberInput"
                                        [(ngModel)]="this.fboPreferencesData.decimalPrecision"
                                        #numberInputModel="ngModel"
                                        required
                                        min="2"
                                        max="12"
                                        pattern="^[1-9]*$"
                                        (input)="onDecimalPrecisionchange()"/>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isDecimalPrecisionInRange" class="text-center"
                                style="color: red">
                            Decimal Precision must be between 2 and 12
                        </div>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
