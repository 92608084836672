<div
    class="card-wrap"
    [ngClass]="{
        'success-card': bgColor === 'success',
        'info-card': bgColor === 'info',
        'gray-card': bgColor === 'gray',
        'warning-card': bgColor === 'warning',
        'danger-card': bgColor === 'danger',
        'dead-card': bgColor === 'dead',
        'dark-card': bgColor === 'dark',
        'main-bg-card': bgColor === 'main',
        'outline-card': outline,
        collapsible: collapsible
    }"
>
    <h3
        class="card-header h5"
        *ngIf="title"
        (click)="headerClick()"
        [ngClass]="{
            'text-center': align === 'center',
            'success-card-header': headerBgColor === 'success',
            'info-card-header': headerBgColor === 'info',
            'gray-card-header': headerBgColor === 'gray',
            'warning-card-header': headerBgColor === 'warning',
            'danger-card-header': headerBgColor === 'danger',
            'dead-card-header': headerBgColor === 'dead',
            'dark-card-header': headerBgColor === 'dark',
            'small-header': theme === 'small',
            'tiny-header': theme === 'tiny'
        }"
    >
        <div class="row">
            <div class="col-12">
                <span #cardTitle
                    >{{ title }} {{ collapsible && !opened ? " + " : "" }}</span
                >
                <h6
                    #cardSubTitle
                    class="card-subtitle"
                    [ngClass]="{
                        invisible: !subTitleVisible
                    }"
                >
                    {{ subtitle }}
                </h6>
            </div>
        </div>
    </h3>
    <div
        [@openClose]="!collapsible || opened"
        class="card-content"
        [style]="customStyle"
        [ngClass]="{
            'tiny-content': theme === 'tiny'
        }"
    >
        <ng-content></ng-content>
    </div>
</div>
