<div #customerTableContainer class="customers-grid" style="position: relative">
    <div class="container-fluid">
        <div class="row">
            <div *ngIf="dataSource" class="col-5">
                <app-table-global-search
                    [matDataSource]="dataSource"
                    placeholder="Search by Company, Tail, etc."
                    (filteredDataSource)="onFilterApplied($event)"
                ></app-table-global-search>
            </div>
            <div class="col-2">
                <mat-form-field>
                    <mat-select
                        (selectionChange)="customerFilterTypeChanged()"
                        [(ngModel)]="customerFilterType"
                        placeholder="Search Within"
                    >
                        <mat-option [value]="0"> All Customers </mat-option>
                        <mat-option [value]="1">
                            Customers Needing Attention
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-top: 15px; margin-left: -20px" class="col-5">
                <button
                    (click)="newCustomer()"
                    [view]="'accent'"
                    beforeIcon="fa fa-plus"
                    class="action-btn float-right"
                    ni-button
                    size="small"
                >
                    Add New
                </button>
            </div>
            <div class="export-import-menu">
                <div class="actions-menu">
                    <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                        <mat-icon
                            aria-hidden="true"
                            class="mat-icon material-icons"
                            role="img"
                            >settings</mat-icon
                        >
                    </button>
                    <mat-menu
                        #gridActions="matMenu"
                        class="switch-nested-menu-left-arrows"
                        xPosition="before"
                    >
                        <!--<button (click)="launchImporter()" [ngClass]="{ disabled: !LICENSE_KEY }" mat-menu-item>
                            Import
                        </button>-->
                        <button
                            [matMenuTriggerFor]="exportActions"
                            mat-menu-item
                        >
                            Export
                        </button>
                        <button (click)="openSettings()" mat-menu-item>
                            Settings
                        </button>
                    </mat-menu>
                    <mat-menu #exportActions="matMenu" xPosition="before">
                        <button
                            (click)="exportCustomersToExcel()"
                            mat-menu-item
                        >
                            Export Customers
                        </button>
                        <button
                            (click)="exportCustomerAircraftToExcel()"
                            mat-menu-item
                        >
                            Export Aircraft
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="row">
            <div *ngIf="anySelected()" class="col-2 bulk-update">
                <mat-form-field>
                    <mat-select
                        (selectionChange)="bulkMarginTemplateUpdate($event)"
                        class="bulk-update-select"
                        placeholder="Bulk Update Margin Template"
                    >
                        <mat-option
                            *ngFor="let pricingTemplate of pricingTemplatesData"
                            [value]="pricingTemplate"
                        >
                            {{ pricingTemplate.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div
                *ngIf="anySelected()"
                style="margin-top: 3px"
                class="col bulk-action-btn"
            >
                <button
                    (click)="exportCustomersToExcel(true)"
                    [view]="'accent'"
                    ni-button
                    size="small"
                >
                    Export Selected
                </button>
            </div>
        </div>
    </div>
    <div
        class="w-100 table-container"
        style="overflow-x: auto; position: relative"
        (scroll)="onTableScroll($event)"
    >
        <table #customerTable
               [dataSource]="dataSource"
               class="col-md-12 mb-0"
               mat-table
               matSort>
            <ng-container matColumnDef="selectAll">
                <th *matHeaderCellDef mat-header-cell>
                    <input (change)="selectAction()"
                           [(ngModel)]="selectAll"
                           class="checkboxHeight"
                           id="a"
                           style="position: absolute; margin-top: 8px"
                           type="checkbox" />
                    <div for="a" style="margin-left: 25px; margin-top: 0px">
                        <div>Select</div>
                        <div>All</div>
                    </div>
                </th>
                <td *matCellDef="let customer" clickStopPropagation mat-cell>
                    <div class="row mx-auto">
                        <span>
                            <input (change)="selectUnique()"
                                   [(ngModel)]="customer.selectAll"
                                   class="checkboxHeight"
                                   type="checkbox"
                                   value="{{ customer.selectAll }}" />
                        </span>
                        <app-favorite-icon [favoriteData]="setIsFavoriteProperty(customer)"
                                           [callbackComponent]="getCallBackComponent"
                                           (favoriteClick)="toogleFavorite(customer)"></app-favorite-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matSort]="sort"
                                                 columnId="company"
                                                 propertyName="company">
                            <span>Company</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" mat-cell>
                    {{ customer.company }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actionStatus">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="customerNeedsAttention"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 propertyName="customerNeedsAttention"
                                                 [columnFormat]="10"
                                                 optionLabel="label"
                                                 optionValue="value"
                                                 [options]="needsAttentionOptions"
                                                 >
                            <span>Action Status</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    <div style="max-width:150px;">
                        <app-customer-action-status matTooltipPosition="after" customerActionStatusEmailRequired="{{info.customerActionStatusEmailRequired}}" customerActionStatusSetupRequired="{{info.customerActionStatusSetupRequired}}" customerActionStatusTopCustomer="{{info.customerActionStatusTopCustomer}}" moreThan2Badges="{{info.moreThan2Badges}}" toolTipEmailRequired="{{info.toolTipEmailRequired}}" toolTipSetupRequired="{{info.toolTipSetupRequired}}" toolTipTopCustomer="{{info.toolTipTopCustomer}}"></app-customer-action-status>
                    </div>
                </td>
            </ng-container>

            
            <!--<ng-container matColumnDef="needsAttention">
    +        <th *matHeaderCellDef class="text-left" mat-header-cell>
    +            <div class="">Needs Attention</div>
    +        </th>
    +        <td *matCellDef="let customer" class="text-left" mat-cell>
    +            <div>
    +                <ni-badge *ngIf="customer.needsAttention"
    +                          [arrow]="'right'"
    +                          [borderRadius]="false"
    +                          [color]="'warning'"
    +                          matTooltip="{{ customer.needsAttentionReason }}"
    +                          matTooltipPosition="after">
    +                    New Customer
    +                </ni-badge>
    +                <a *ngIf="
    +                        !customer.isFuelerLinxCustomer &&
    +                        !customer.contactExists
    +                    "
    +                   matTooltip="This customer does not have any contacts setup to receive price distribution."
    +                   matTooltipPosition="after"
    +                   style="float: right">
    +                    <i class="fa fa-warning" style="color: red"></i>
    +                </a>
    +            </div>
    +        </td>
    +    </ng-container>-->

            <ng-container matColumnDef="tags">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matSort]="sort"
                                                 columnId="tags"
                                                 propertyName="tags"
                                                 optionLabel="label"
                                                 optionValue="value"
                                                 [columnFormat]="11"
                                                 [options]="tags">
                            <span>Tags</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" clickStopPropagation mat-cell>
                    <p-multiSelect class="tags-dropdown-filter"
                                   [options]="customer.availableTags"
                                   #multiInput
                                   [(ngModel)]="customer.tags"
                                   display="chip"
                                   placeholder="No tags"
                                   optionLabel="label"
                                   autofocusFilter="true"
                                   [virtualScroll]="true"
                                   (onChange)="onCustomerTagUpdate($event, customer)"
                                   maxSelectedLabels="10"
                                   filterBy="name"
                                   (onPanelShow)="onCustomerTagPanelShow($event, customer)"
                                   [showToggleAll]="false"
                                   itemSize="50"
                                   resetFilterOnHide="true"
                                   (onPanelHide)="checkState()">
                        <ng-template let-value pTemplate="selectedItems">
                            <div class="p-multiselect-token"
                                 *ngFor="let tag of customer.tags">
                                <span class="p-multiselect-token-label">
                                    {{ tag.name }}
                                </span>
                            </div>

                            <div *ngIf="
                                    !customer.tags || customer.tags.length == 0
                                ">
                                Select a Tag
                            </div>
                        </ng-template>
                        <ng-template let-tag pTemplate="item">
                            <div class="fbo-item">
                                <div>{{ tag.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <div class="fbo-item addCustom">
                                <a (click)="newCustomTag(customer)">&lt;Add Custom&gt;</a>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </td>
            </ng-container>

            <ng-container matColumnDef="isFuelerLinxCustomer">
                <th *matHeaderCellDef class="overflow-label" mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [columnFormat]="8"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="isFuelerLinxCustomer"
                                                 propertyName="isFuelerLinxCustomer">
                            <div>
                                <div>FuelerLinx</div>
                                <div>Network</div>
                            </div>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" mat-cell>
                    {{ getIsInNetworkDisplayString(customer) }}
                </td>
            </ng-container>

            <ng-container matColumnDef="fleetSize">
                <th *matHeaderCellDef class="overflow-label" mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [columnFormat]="1"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="fleetSize"
                                                 propertyName="fleetSize">
                            <div>
                                <div>Fleet</div>
                                <div>Size</div>
                            </div>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" mat-cell>
                    {{ customer.fleetSize }}
                </td>
            </ng-container>

            <ng-container matColumnDef="pricingTemplateName">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matSort]="sort"
                                                 columnId="pricingTemplateId"
                                                 propertyName="pricingTemplateId"
                                                 [columnFormat]="10"
                                                 optionLabel="name"
                                                 optionValue="oid"
                                                 [options]="pricingTemplatesData">
                            <span>ITP Margin Template</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" clickStopPropagation mat-cell>
                    <select (ngModelChange)="onMarginChange($event, customer)"
                            [(ngModel)]="customer.pricingTemplateId"
                            class="dropdownHeight select-dropdown"
                            id="pricingtemplates"
                            name="ddlPricingTemplate">
                        <option *ngFor="let pricingTemplate of pricingTemplatesData"
                                value="{{ pricingTemplate.oid }}">
                            {{ pricingTemplate.name }}
                        </option>
                    </select>
                </td>
            </ng-container>

            <ng-container matColumnDef="fuelVendors">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matSort]="sort"
                                                 columnId="fuelVendors"
                                                 propertyName="fuelVendors"
                                                 optionLabel="label"
                                                 optionValue="value"
                                                 [columnFormat]="11"
                                                 [options]="fuelVendors">
                            <span>Fuel Vendors</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" clickStopPropagation mat-cell>
                    <p-multiSelect class="fuelvendors-dropdown-filter"
                                   [options]="customer.fuelVendors"
                                   [(ngModel)]="customer.fuelVendors"
                                   display="chip"
                                   placeholder="No Fuel Vendors"
                                   optionLabel="label"
                                   [virtualScroll]="true"
                                   [disabled]="
                            !customer.fuelVendors ||
                            !customer.fuelVendors.length
                        "
                                   (onChange)="onFuelVendorUpdate($event, customer)"
                                   [showToggleAll]="false"
                                   itemSize="50">
                        <ng-template let-value pTemplate="selectedItems">
                            <div class="p-multiselect-token"
                                 *ngFor="let fv of customer.fuelVendors">
                                <span class="p-multiselect-token-label">
                                    {{ fv.value }}
                                </span>
                            </div>

                            <div *ngIf="
                                    !customer.fuelVendors ||
                                    customer.fuelVendors.length === 0
                                ">
                                No Fuel Vendors
                            </div>
                        </ng-template>
                        <ng-template let-fv pTemplate="item">
                            <div class="fbo-item">
                                <div>{{ fv.value }}</div>
                            </div>
                        </ng-template>
                    </p-multiSelect>
                </td>
            </ng-container>

            <ng-container matColumnDef="allInPrice">
                <th *matHeaderCellDef class="overflow-label" mat-header-cell>
                    <div>All-In</div>
                </th>
                <td *matCellDef="let customer" clickStopPropagation mat-cell>
                    <div (click)="onCustomerPriceClicked(customer)"
                         class="allInPrice">
                        {{ getAllIPriceDisplayString(customer) }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="certificateTypeDescription">
                <th *matHeaderCellDef class="overflow-label" mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matSort]="sort"
                                                 columnId="certificateTypeDescription"
                                                 propertyName="certificateTypeDescription">
                            <div>
                                <div>Certificate</div>
                                <div>Type</div>
                            </div>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" mat-cell>
                    {{ customer.certificateTypeDescription }}
                </td>
            </ng-container>

            <ng-container matColumnDef="aircraftsVisits">
                <th *matHeaderCellDef
                    class="overflow-label"
                    mat-header-cell
                    [matTooltip]="
                        'Since ' + (airportWatchStartDate | date : 'MM/dd/YYYY')
                    ">
                    <span *ngIf="dataSource">
                        <app-table-column-filter [columnFormat]="1"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="aircraftsVisits"
                                                 propertyName="aircraftsVisits">
                            <div>
                                <div>Previous</div>
                                <div>Visits</div>
                            </div>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" mat-cell>
                    {{ customer.aircraftsVisits }}
                </td>
            </ng-container>

            <ng-container matColumnDef="pricingFormula">
                <th *matHeaderCellDef class="overflow-label" mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [columnFormat]="0"
                                                 (filteredDataSource)="onFilterApplied($event)"
                                                 [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="pricingFormula"
                                                 propertyName="pricingFormula">
                            <div>Pricing Formula</div>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let customer" mat-cell>
                    {{ customer.pricingFormula }}
                </td>
            </ng-container>

            <ng-container matColumnDef="invoiceEmail">
                <th *matHeaderCellDef class="overflow-label" mat-header-cell>
                    <div>Invoice Email</div>
                </th>
                <td *matCellDef="let customer" clickStopPropagation mat-cell>
                    {{ customer.invoiceUniqueEmail }}
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let customer" mat-cell>
                    <div class="pull-right">
                        <button (click)="
                                deleteCustomer(customer);
                                $event.stopPropagation()
                            "
                                *ngIf="!customer.isFuelerLinxCustomer"
                                [view]="'error'"
                                beforeIcon="fa fa-trash"
                                ni-button
                                size="small">
                            Delete
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="getTableColumns(); sticky: true"
                mat-header-row></tr>
            <tr (click)="editCustomer(row)"
                *matRowDef="let row; columns: getTableColumns()"
                mat-row></tr>
        </table>

        <div class="invisible">
            <mat-paginator
                (page)="(0); onPageChanged($event)"
                [length]="customersData.length"
                [pageSizeOptions]="[10, 25, 50, 100]"
                [pageSize]="pageSize"
            ></mat-paginator>
        </div>

        <popover-content
            #feeAndTaxPopover
            placement="left"
            title="Price Information"
            [closeOnClickOutside]="true"
        >
            <price-breakdown
                #priceBreakdownPreview
                *ngIf="focusedCustomer"
                [customerInfoByGroupId]="focusedCustomer.customerInfoByGroupId"
                [feeAndTaxDisplayMode]="2"
                [feesAndTaxes]="feesAndTaxes"
                [priceTemplateId]="focusedCustomer.pricingTemplateId"
                [hideTooltips]="true"
            ></price-breakdown>
        </popover-content>
    </div>
</div>
