<div class="customer-aircraft-table">
    <div>
        <div style="padding-left: 15px; padding-right: 15px;">
            <mat-form-field>
                <input (keyup)="applyFilter($event.target.value)"
                       matInput
                       placeholder="Search by Tail, Make, Model, etc." [(ngModel)]="search"/>
                <span matPrefix><i class="fa fa-search"></i></span>
            </mat-form-field>
        </div>
        <table
            [dataSource]="customerAircraftsDataSource"
            class="col-md-12"
            mat-table
            matSort
            matSortActive="tailNumber"
            matSortDirection="asc"
        >
            <ng-container matColumnDef="tailNumber">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Tail</th>
                <td
                    *matCellDef="let customerAircraft"
                    mat-cell
                >
                    <div class="row">
                        <app-favorite-icon
                        [favoriteData]="setIsFavoriteProperty(customerAircraft)"
                        [callbackComponent]="getCallBackComponent"></app-favorite-icon>
                        <span (click)="editCustomerAircraft(customerAircraft)">{{ customerAircraft.tailNumber }}</span>
                    </div>

                </td>
            </ng-container>

            <ng-container matColumnDef="aircraftType">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
                <td
                    (click)="editCustomerAircraft(customerAircraft)"
                    *matCellDef="let customerAircraft"
                    mat-cell
                >
                    {{ customerAircraft.make }} {{ customerAircraft.model }}
                </td>
            </ng-container>

            <ng-container matColumnDef="aircraftSize">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Size</th>
                <td
                    (click)="editCustomerAircraft(customerAircraft)"
                    *matCellDef="let customerAircraft"
                    mat-cell
                >
                    {{ customerAircraft.aircraftSizeDescription }}
                </td>
            </ng-container>

            <ng-container matColumnDef="aircraftPricingTemplate">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    Pricing
                </th>
                <td *matCellDef="let customerAircraft" mat-cell>
                    <mat-form-field floatLabel="never">
                        <mat-label>Company Pricing</mat-label>
                        <mat-select
                            (selectionChange)="
                                onMarginChange($event, customerAircraft)
                            "
                            [value]="customerAircraft.pricingTemplateId"
                        >
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="
                                    let pricingTemplate of pricingTemplatesData
                                "
                                [value]="pricingTemplate.oid"
                                >{{ pricingTemplate.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th *matHeaderCellDef mat-header-cell>
                    <div class="pull-right">
                        <button
                            (click)="newCustomerAircraft()"
                            [view]="'accent'"
                            beforeIcon="fa fa-plus"
                            class="mr-3"
                            ni-button
                        >
                            Add New
                        </button>
                    </div>
                </th>
                <td (click)="editCustomerAircraft(customerAircraft)"
                    *matCellDef="let customerAircraft" mat-cell>
                    <div [title]="getNoteToDisplayForAircraft(customerAircraft)">
                        {{getNoteToDisplayForAircraft(customerAircraft)}}
                    </div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        </table>
    </div>

    <div>
        <mat-paginator
            (page)="(pageIndex); onPageChanged($event)"
            [length]="resultsLength"
            [pageSizeOptions]="[10, 25, 50, 100, 200, 500]"
            [pageSize]="500"
        ></mat-paginator>
    </div>
</div>
