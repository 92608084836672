<h1 *ngIf="!data.customTitle" mat-dialog-title>Save Changes?</h1>
<h1 *ngIf="data.customTitle" mat-dialog-title>{{ data.customTitle }}</h1>
<div class="mt-1 mb-2" mat-dialog-content>
    <div *ngIf="!data.customText" class="text-center">
        You are about to close this {{ data.description }}. Are you sure?
    </div>
    <div *ngIf="data.customText" class="text-center">
        {{ data.customText }}
    </div>
</div>
<div mat-dialog-actions>
    <div class="text-center col-12">
        <button
            mat-dialog-close="save"
            class="mr-1"
            color="accent"
            mat-flat-button
        >
            {{ data.save }}
        </button>
        <button
            mat-dialog-close="discard"
            class="mr-1"
            color="warn"
            mat-flat-button
        >
            {{ data.discard }}
        </button>
        <button (click)="onCancelClick()" mat-stroked-button>
            {{ data.cancel }}
        </button>
    </div>
</div>
