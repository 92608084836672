<div class="chart-container">
    <div class="flex mb-2 position-relative">
        <div class="d-inline-block mr-2 ml-2">
            <app-report-filters
                [hiddenFilters]="reportHiddenItems"
                [icao]="icao"
                [dataSource]="dataSource"
                [selectedDateFilter]="selectedDateFilter"
                (onChangeIcaoFilter)="changeIcaoFilter($event)"
                (onDateChange)="applyPresetDateFilter($event)"
                (commercialAicraftToogle)="filterChanged($event)"
            >
            </app-report-filters>
        </div>
        <div class="actions">
            <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                <mat-icon
                    aria-hidden="true"
                    class="mat-icon material-icons"
                    role="img"
                    >settings</mat-icon
                >
            </button>
            <mat-menu
                #gridActions="matMenu"
                class="switch-nested-menu-left-arrows"
                xPosition="before"
            >
                <button (click)="openSettings()" mat-menu-item>Settings</button>
            </mat-menu>
        </div>
    </div>

    <div
        class="table-container mat-elevation-z3"
        (scroll)="onTableScroll($event)"
    >
        <table
            [dataSource]="dataSource"
            mat-table
            matSort
            matSortActive="dateTime"
            matSortDirection="desc"
        >
            <ng-container matColumnDef="company">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter
                            [matDataSource]="dataSource"
                            [matSort]="sort"
                            columnId="company"
                            propertyName="company"
                        >
                            <span>Company</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.company }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tailNumber">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter
                            [matDataSource]="dataSource"
                            [matSort]="sort"
                            columnId="tailNumber"
                            propertyName="tailNumber"
                        >
                            <span>Tail #</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.tailNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="aircraftType">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter
                            [matDataSource]="dataSource"
                            [matSort]="sort"
                            columnId="aircraftType"
                            propertyName="aircraftType"
                        >
                            <span>Aircraft</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.aircraftType }}
                </td>
            </ng-container>

            <ng-container
                matColumnDef="{{ dynamicColumns[dynamicColumnIndex].id }}"
                *ngFor="
                    let dynamicColumn of dynamicColumns;
                    index as dynamicColumnIndex
                "
            >
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter
                            [matDataSource]="dataSource"
                            [matSort]="sort"
                            columnId="{{
                                dynamicColumns[dynamicColumnIndex].id
                            }}"
                            propertyName="{{
                                dynamicColumns[dynamicColumnIndex].id
                            }}"
                            [columnFormat]="1"
                        >
                            <span>{{
                                dynamicColumns[dynamicColumnIndex].name
                            }}</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{
                        !info[dynamicColumns[dynamicColumnIndex].id]
                            ? 0
                            : info[dynamicColumns[dynamicColumnIndex].id]
                    }}
                </td>
            </ng-container>

            <tr
                *matHeaderRowDef="visibleColumns; sticky: true"
                mat-header-row
            ></tr>
            <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
        </table>
        <div class="invisible">
            <mat-paginator
                [pageSizeOptions]="[10, 25, 50, 100]"
                showFirstLastButtons
            ></mat-paginator>
        </div>
    </div>
    <ngx-ui-loader
    [loaderId]="chartName"
    bgsColor="rgb(100, 181, 246)"
    bgsPosition="center-center"
    bgsType="ball-spin"
    overlayColor="rgba(0, 0, 0, 0.1)"
></ngx-ui-loader>
</div>
