<div class="font-weight-bold">
    <div>
        <div *ngFor="let fee of rampFeesForCategory">
            <div class="container-fluid mt-2">
                <div class="row">
                    <div class="col-3">
                        <div class="mat-form-field">
                            <div
                                (click)="
                                    fee.subDetailsExpanded =
                                        !fee.subDetailsExpanded
                                "
                                class="
                                    mat-form-field-wrapper
                                    ramp-fee-details-expansion
                                "
                            >
                                <div class="mat-form-field-flex">
                                    <div class="mat-form-field-infix">
                                        <div
                                            class="
                                                ramp-fee-details-expansion-icon
                                            "
                                        >
                                            <mat-icon
                                                *ngIf="!fee.subDetailsExpanded"
                                                >expand_more</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="fee.subDetailsExpanded"
                                                >expand_less</mat-icon
                                            >
                                        </div>
                                        <div
                                            class="
                                                ramp-fee-details-expansion-label
                                            "
                                        >
                                            {{getLabel(fee)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 text-center">
                        <mat-form-field>
                            <input (change)="rampFeeRequiresUpdate(fee)"
                                   [(ngModel)]="fee.price"
                                   matInput
                                   placeholder="Ramp/Facil. Fee"
                                   type="number" />
                            <span matPrefix>$</span>
                        </mat-form-field>
                        <div *ngIf="fee.isFeeNegative" class="text-danger">Fee cannot be negative</div>
                    </div>
                    <div class="col-3 text-center">
                        <mat-form-field>
                            <input
                                (change)="rampFeeRequiresUpdate(fee)"
                                [(ngModel)]="fee.waived"
                                matInput
                                placeholder="Avoidance"
                                type="number"
                            />
                            <span matSuffix>Gal.</span>
                        </mat-form-field>
                        <div *ngIf="fee.isWaivedNegative" class="text-danger">Avoidance cannot be negative</div>
                    </div>
                    <div class="col-3 text-center">
                        <!--<mat-form-field *ngIf="fee.categoryType == 1">
                            <input matInput [(ngModel)]="fee.expirationDate" [matDatepicker]="categoryDatePicker" placeholder="Expiration Date" disabled (change)="rampFeeRequiresUpdate(fee)">
                            <mat-datepicker-toggle matSuffix [for]="categoryDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #categoryDatePicker disabled="false"></mat-datepicker>
                        </mat-form-field>-->
                        <div>
                            <button
                                (click)="deleteRampFee(fee)"
                                *ngIf="fee.categoryType != 1"
                                [view]="'error'"
                                beforeIcon="fa fa-trash"
                                ni-button
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="fee.subDetailsExpanded">
                    <ni-card
                        [headerBgColor]="'info'"
                        [theme]="'small'"
                        class="mb-3"
                        title="Applies To"
                    >
                        <div class="container-fluid applies-to-container">
                            <div *ngIf="fee.categoryType == 1" class="row">
                                <div
                                    *ngFor="let aircraft of fee.appliesTo"
                                    class="col-12"
                                >
                                    {{ aircraft.make }} {{ aircraft.model }}
                                </div>
                            </div>
                            <div *ngIf="fee.categoryType == 2" class="row">
                                <div>
                                    <mat-form-field>
                                        <mat-select
                                            (change)="
                                                rampFeeRequiresUpdate(fee)
                                            "
                                            [(ngModel)]="fee.categoryMinValue"
                                            placeholder="Type"
                                        >
                                            <mat-option
                                                *ngFor="
                                                    let aircraftType of aircraftTypes
                                                "
                                                [value]="
                                                    aircraftType.aircraftId
                                                "
                                            >
                                                {{ aircraftType.make }}
                                                {{ aircraftType.model }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="fee.categoryType == 3" class="row">
                                <div class="col-3">Weight Range</div>
                                <div class="col-3">
                                    <mat-form-field>
                                        <input (change)="
                                                rampFeeRequiresUpdate(fee)
                                            "
                                               [(ngModel)]="fee.categoryMinValue"
                                               matInput
                                               placeholder="Min. Weight"
                                               type="number" />
                                        <span matSuffix>Lbs.</span>
                                    </mat-form-field>
                                    <div *ngIf="fee.isCategoryValueNegative" class="text-danger">Weight cannot be negative</div>
                                    <div *ngIf="fee.isCategoryMinValueGrater" class="text-danger">Min value cannot be grater than Max value</div>
                                </div>
                                <div class="col-3">to</div>
                                <div class="col-3">
                                    <mat-form-field>
                                        <input (change)="
                                                rampFeeRequiresUpdate(fee)
                                            "
                                               [(ngModel)]="fee.categoryMaxValue"
                                               matInput
                                               placeholder="Max. Weight"
                                               type="number" />
                                        <span matSuffix>Lbs.</span>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="fee.categoryType == 4" class="row">
                                <div class="col-3">Wingspan</div>
                                <div class="col-3">
                                    <mat-form-field>
                                        <input (change)="
                                                rampFeeRequiresUpdate(fee)
                                            "
                                               [(ngModel)]="fee.categoryMinValue"
                                               matInput
                                               placeholder="Min. Wingspan"
                                               type="number" />
                                        <span matSuffix>Feet</span>
                                    </mat-form-field>
                                    <div *ngIf="fee.isCategoryValueNegative" class="text-danger">Wingspan cannot be negative</div>
                                </div>
                                <div class="col-3">to</div>
                                <div class="col-3">
                                    <mat-form-field>
                                        <input
                                            (change)="
                                                rampFeeRequiresUpdate(fee)
                                            "
                                            [(ngModel)]="fee.categoryMaxValue"
                                            matInput
                                            placeholder="Max. Wingspan"
                                            type="number"
                                        />
                                        <span matSuffix>Feet</span>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="fee.categoryType == 5" class="row">
                                <div class="col-3">
                                    Tail Number:&nbsp;<input
                                        [(ngModel)]="fee.categoryStringValue"
                                        disabled="disabled"
                                        matInput
                                    />
                                </div>
                            </div>
                        </div>
                    </ni-card>
                </div>
            </div>
        </div>
    </div>
</div>
