<div *ngIf="oldPricingTemplate != null && newPricingTemplate != null">
        <table class="table table-hover table-respoisve table-bordered">
            <thead>
               <tr>
                   <th>Name</th>
                   <th>Old Data</th>
                   <th>New Data</th>
               </tr>
             </thead>
               <tbody>
                 <tr>
                     <td>ITP Margin Name</td>
                     <td>{{oldPricingTemplate.name}}</td>
                     <td>{{newPricingTemplate.name}}</td>
                </tr>
               </tbody>
        </table>
</div>
