<ni-card [headerBgColor]="'gray'" title="Customer Statistics">
    <div class="chart-container">
        <div class="flex position-relative">
            <div class="d-inline-block mr-2 ml-2" style="width: 130px">
                <mat-form-field>
                    <input
                        (dateChange)="refreshData()"
                        (focus)="dashboardSettingsStartDateFilter.open()"
                        [(ngModel)]="filterStartDate"
                        [matDatepicker]="dashboardSettingsStartDateFilter"
                        [max]="filterEndDate"
                        matInput
                        placeholder="Start Date"
                    />
                    <mat-datepicker-toggle
                        [for]="dashboardSettingsStartDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #dashboardSettingsStartDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="d-inline-block mr-2 ml-2" style="width: 130px">
                <mat-form-field>
                    <input
                        (dateChange)="refreshData()"
                        (focus)="dashboardSettingsEndDateFilter.open()"
                        [(ngModel)]="filterEndDate"
                        [matDatepicker]="dashboardSettingsEndDateFilter"
                        [min]="filterStartDate"
                        matInput
                        placeholder="End Date"
                    />
                    <mat-datepicker-toggle
                        [for]="dashboardSettingsEndDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #dashboardSettingsEndDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="d-inline-block mr-2 ml-2">
                <p-multiSelect
                    class="fbos-dropdown-filter"
                    [options]="fbos"
                    [(ngModel)]="selectedFbos"
                    optionLabel="fbo"
                    display="chip"
                    placeholder="Select FBOs"
                    (onChange)="filterChanged()"
                    [virtualScroll]="true"
                    itemSize="50"
                >
                    <ng-template let-value pTemplate="selectedItems">
                        <div
                            class="p-multiselect-token"
                            *ngFor="let fbo of selectedFbos"
                        >
                            <span
                                class="p-multiselect-token-label ng-tns-c52-5"
                            >
                                {{ fbo.icao }} - {{ fbo.fbo }}
                            </span>
                            <span
                                class="p-multiselect-token-icon pi pi-times-circle"
                            ></span>
                        </div>

                        <div *ngIf="!selectedFbos || selectedFbos.length === 0">
                            Select FBOs
                        </div>
                    </ng-template>
                    <ng-template let-fbo pTemplate="item">
                        <div class="fbo-item">
                            <div>{{ fbo.icao }} - {{ fbo.fbo }}</div>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>
            <div class="d-inline-block mr-2 ml-2" *ngIf="dataSource">
                <app-table-global-search
                    [matDataSource]="dataSource"
                    placeholder="Search by Company"
                ></app-table-global-search>
            </div>
            <div class="actions">
                <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                    <mat-icon
                        aria-hidden="true"
                        class="mat-icon material-icons"
                        role="img"
                        >settings</mat-icon
                    >
                </button>
                <mat-menu
                    #gridActions="matMenu"
                    class="switch-nested-menu-left-arrows"
                    xPosition="before"
                >
                    <button (click)="onExport()" mat-menu-item>Export</button>
                    <button (click)="openSettings()" mat-menu-item>
                        Settings
                    </button>
                </mat-menu>
            </div>
        </div>

        <div class="table-container mat-elevation-z3">
            <table [dataSource]="dataSource" mat-table matSort>
                <ng-container matColumnDef="company">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="company"
                                propertyName="company"
                                [columnFormat]="0"
                            >
                                <span>Company</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>{{ info.company }}</td>
                </ng-container>

                <ng-container matColumnDef="directOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="directOrders"
                                propertyName="directOrders"
                                [columnFormat]="1"
                            >
                                <span>Direct Orders</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.directOrders }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="companyQuotesTotal">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="companyQuotesTotal"
                                propertyName="companyQuotesTotal"
                                [columnFormat]="1"
                            >
                                <span>Number of Quotes</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.companyQuotesTotal }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="conversionRate">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="conversionRate"
                                propertyName="conversionRate"
                                [columnFormat]="1"
                            >
                                <span>Conversion Rate</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.conversionRate }}%
                    </td>
                </ng-container>

                <ng-container matColumnDef="totalOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="totalOrders"
                                propertyName="totalOrders"
                                [columnFormat]="1"
                            >
                                <span>Total Orders</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.totalOrders }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastPullDate">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="lastPullDate"
                                propertyName="lastPullDate"
                                [columnFormat]="4"
                            >
                                <span>Last Quoted</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.lastPullDate }}
                    </td>
                </ng-container>
                <tr
                    *matHeaderRowDef="visibleColumns; sticky: true"
                    mat-header-row
                ></tr>
                <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
            </table>
        </div>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>

    <mat-paginator
        (page)="(pageIndex); onPageChanged($event)"
        [length]="dataLength"
        [pageSizeOptions]="[10, 25, 50, 100]"
        [pageSize]="pageSize"
    ></mat-paginator>
</ni-card>
