<h1 mat-dialog-title>Potential Customer Match</h1>
<div mat-dialog-content>
    <div *ngIf="data.isAircraftMatch">
        <h6>Match by Aircraft Tails</h6>
        <p *ngIf="data.matchCustomerName">
            Company name:
            <a
                href="/default-layout/customers/{{ data.matchCustomerOid }}"
                style="color: #007bff !important"
                target="_blank"
                >{{ data.matchCustomerName }}</a
            >
        </p>
        <p>List of Aircraft Tails</p>
        <ul>
            <li *ngFor="let number of data.aircraftTails">
                {{ number }}
            </li>
        </ul>
        <hr />
    </div>
    <div *ngIf="data.isNameMatch">
        <h6>Match by Company Name</h6>
        <p *ngIf="data.matchCustomerName">
            Company name:
            <a
                href="/default-layout/customers/{{ data.matchNameCustomerOid }}"
                style="color: #007bff !important"
                target="_blank"
                >{{ data.matchNameCustomer }}</a
            >
        </p>
        <hr />
    </div>
    <div *ngIf="data.isContactMatch">
        <h6>Match by Company Contact</h6>
        <p *ngIf="data.matchCustomerName">
            Company name:
            <a
                href="/default-layout/customers/{{
                    data.matchContactCustomerOid
                }}"
                style="color: #007bff !important"
                target="_blank"
                >{{ data.matchNameCustomer }}</a
            >
        </p>
        <hr />
    </div>
    <div style="margin-top: 10px; margin-bottom: 10px">
        <span>
            Merging these customers will keep your existing contact details and
            assigned pricing templates while allowing you to price to this
            customer directly via FuelerLinx. Keeping them separate will keep
            two separate customers in your FBOLinx account.
        </span>
    </div>
</div>
<div class="mt-2 text-center">
    <button (click)="onCancelClick()" [view]="'basic'" class="mr-1" ni-button>
        Remind Me Later
    </button>
    <button (click)="keepSeparate()" [view]="'accent'" class="mr-1" ni-button>
        Keep Separate
    </button>
    <button
        (click)="mergeCustomers()"
        [view]="'primary'"
        class="mr-1"
        ni-button
    >
        Merge Customers
    </button>
</div>
