<h1 mat-dialog-title>Forgot Password</h1>
<div mat-dialog-content>
    <div class="mb-3">Please provide your email address below.</div>
    <div>
        <mat-form-field>
            <input
                #email="ngModel"
                [(ngModel)]="data.email"
                [email]="true"
                aria-label="Email Login"
                matInput
                placeholder="Email"
                type="email"
            />
        </mat-form-field>
        <div *ngIf="email.errors?.email" style="color: red">
            Please enter a valid email address.
        </div>
    </div>
</div>
<div class="mt-2 text-center">
    <button
        [disabled]="!data.email || !data.email.length"
        [mat-dialog-close]="data"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        Send Reset Link
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
</div>
