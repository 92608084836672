import { Component } from '@angular/core';

@Component({
    selector: 'app-email-content-selection',
    styleUrls: ['./email-content-selection.component.scss'],
    templateUrl: './email-content-selection.component.html',
})
export class EmailContentSelectionComponent {
    constructor() {}
}
