<h1 mat-dialog-title>Copy Template Confirmation</h1>
<div *ngIf="data" mat-dialog-content>
    <div class="row">
        <div class="col-xs-12 col-md-12">
            <mat-form-field>
                <input
                    [(ngModel)]="data.name"
                    aria-label="Margin Name"
                    autocomplete="off"
                    matInput
                    placeholder="Margin Name"
                />
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button
        (click)="ConfirmCopy()"
        [mat-dialog-close]="data"
        class="mr-1"
        color="green"
        mat-stroked-button
    >
        <mat-icon>file_copy</mat-icon>
        Copy Template
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>&nbsp;
</div>
