<div>
    <div class="dialog-header">
        Price Checker
        <a (click)="onCancelClick()" class="float-right close-btn">
            <mat-icon>close</mat-icon>
        </a>
        </div>
    </div>

    <div mat-dialog-content>
        <div>
            <price-checker #priceChecker [hideFeeAndTaxGeneralBreakdown]="isCsr" [hideTooltips]="isCsr"></price-checker>
        </div>
 </div>
