<section>
    <div *ngIf="!fboInfo || !fboAirportInfo || !availableAntennas">
        <mat-spinner></mat-spinner>
    </div>

    <ni-card
        *ngIf="fboInfo && fboAirportInfo && availableAntennas"
        [headerBgColor]="'info'"
        class="mb-0"
        title="FBO: {{ fboInfo.fbo }}"
    >
        <div>
            <mat-tab-group>
                <!--FBO Info Tab-->
                <mat-tab label="FBO Info">
                    <div class="container-fluid">
                        <div>
                            <h5>Location Information</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.fbo"
                                           matInput
                                           placeholder="FBO Name" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.address"
                                           matInput
                                           placeholder="Address" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboAirportInfo.icao"
                                           matInput
                                           placeholder="ICAO" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.city"
                                           matInput
                                           placeholder="City" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.state"
                                           matInput
                                           placeholder="State" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.country"
                                           matInput
                                           placeholder="Country" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.zipCode"
                                           matInput
                                           placeholder="Zip Code" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.website"
                                           matInput
                                           placeholder="Website" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.website"
                                           matInput
                                           placeholder="Website" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                &nbsp;
                            </div>
                            <div class="col-md-6">
                                <mat-label>Antenna Box Name</mat-label>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="fboInfo.antennaName"
                                                class="dropdownHeight select-dropdown" id="antennaname" name="ddlAntennaName" place>
                                        <mat-option *ngFor="let antenna of availableAntennas" value="{{ antenna }}">
                                            {{ antenna }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <h5>Account Information</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field>
                                    <mat-select [(ngModel)]="fboInfo.groupId"
                                                placeholder="Group">
                                        <mat-option *ngFor="let group of groups"
                                                    [value]="group.oid">
                                            {{ group.groupName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.fuelDeskEmail"
                                           matInput
                                           placeholder="Fuel Desk Email" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field style="max-width: 240px">
                                    <input [(ngModel)]="fboInfo.senderAddress"
                                           matInput
                                           placeholder="Sender Address"
                                           matTooltip="Your distribution emails will come from this address"
                                           matTooltipPosition="after" />
                                </mat-form-field>
                                @FBOLinx.com
                            </div>
                            <div class="col-md-6">
                                <mat-form-field>
                                    <input [(ngModel)]="fboInfo.replyTo"
                                           matInput
                                           placeholder="ReplyTo"
                                           matTooltip="For distribution emails, customer replies will default to this address"
                                           matTooltipPosition="after" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row" *ngIf="canChangeActive">
                            <div class="col-3">
                                <mat-slide-toggle [(ngModel)]="fboInfo.active"
                                                  color="accent"
                                                  (change)="onActiveToggle($event)">
                                    Active
                                </mat-slide-toggle>
                            </div>

                            <div class="col-3">
                                <mat-slide-toggle [(ngModel)]="fboInfo.suspended"
                                                  color="warn">
                                    Suspended
                                </mat-slide-toggle>
                            </div>
                            <div *ngIf="isConductor" class="col-6">
                                <div class="expiration-date">
                                    <mat-form-field>
                                        <input [(ngModel)]="fboInfo.expirationDate"
                                               [matDatepicker]="
                                                expirationDatePicker
                                            "
                                               matInput
                                               placeholder="Account Expires" />
                                        <mat-datepicker-toggle [for]="expirationDatePicker"
                                                               matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker-toggle matSuffix
                                                               (click)="
                                                fboInfo.expirationDate = null
                                            ">
                                            <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #expirationDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4" *ngIf="canChangeActive">
                            <div class="col-6">
                                <mat-slide-toggle [(ngModel)]="fboInfo.isJetNetIntegrationEnabled" color="accent">
                                    JetNet Integration
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row" *ngIf="canChangeActive">
                            <div class="col-3">
                                <mat-label>Account Type</mat-label>
                                <mat-form-field>
                                    <mat-select [(ngModel)]="fboInfo.accountType"
                                                class="dropdownHeight select-dropdown" id="accounttype" name="ddlAccountType" place>
                                        <mat-option *ngFor="let accountType of availableAccountTypes" [value]="accountType.value">
                                            {{ accountType.type }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <button (click)="saveEdit()"
                                    class="mr-1"
                                    color="green"
                                    mat-stroked-button>
                                <mat-icon>save</mat-icon>
                                Save Changes
                            </button>
                            <button (click)="cancelEdit()" mat-stroked-button>
                                Cancel
                            </button>
                        </div>
                    </div>
                </mat-tab>

                <!--User Tab-->
                <mat-tab label="Users">
                    <div class="mt-2">
                        <app-users-home
                            [fboInfo]="fboInfo"
                            [groupInfo]="groupInfo"
                        ></app-users-home>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ni-card>
</section>
