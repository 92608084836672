<section>
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel hideToggle #rampfeepanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon *ngIf="!rampfeepanel.expanded">keyboard_arrow_right</mat-icon>
                    <mat-icon *ngIf="rampfeepanel.expanded">keyboard_arrow_down</mat-icon>
                    Ramp Fee & Avoidance
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <app-ramp-fees-home></app-ramp-fees-home>
        </mat-expansion-panel>

        <mat-expansion-panel hideToggle #servicesandfeespanel  expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title >
                    <mat-icon *ngIf="!servicesandfeespanel.expanded">keyboard_arrow_right</mat-icon>
                    <mat-icon *ngIf="servicesandfeespanel.expanded">keyboard_arrow_down</mat-icon>
                    FBO Services <span class="panel-title-description pl-3">Select or deselect the services that your facility offers</span>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>
            <app-services-and-fees></app-services-and-fees>
        </mat-expansion-panel>
    </mat-accordion>
</section>
