<div class="add-button-container d-block d-md-none" *ngIf="allowAddingNew">
    <button
        (click)="addServiceOrderClicked()"
        [view]="'accent'"
        beforeIcon="fa fa-plus"
        class="action-btn"
        ni-button
    ></button>
</div>

<mat-drawer-container class="" style="min-height: 500px" [hasBackdrop]="false">
    <mat-drawer
        #serviceOrderDrawer
        class=""
        mode="over"
        style="width: 380px"
        position="end"
    >
        <div *ngIf="selectedServiceOrder">
            <app-service-orders-item-list
                [serviceOrder]="selectedServiceOrder"
                (serviceOrderItemsChanged)="serviceOrderItemsChanged($event)"
                (closeClicked)="serviceOrderItemsCloseClicked()"
                [isFreemiumAccount]="isFreemiumAccount"
            ></app-service-orders-item-list>
        </div>
    </mat-drawer>

    <div class="container-fluid" *ngIf="!serviceOrdersData">
        <div class="row">
            <div class="col-12">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </div>

    <div class="container-fluid" *ngIf="serviceOrdersData">
        <div class="row">
            <div class="col-6 col-md-3">
                <app-table-global-search
                    (filterApplied)="filterChanged($event)"
                    placeholder="Search by Company, Tail, etc."
                    [showClearButton]="false"
                ></app-table-global-search>
            </div>
            <div class="col-6 col-md-3">
                <mat-form-field>
                    <mat-select
                        (selectionChange)="sortTypeChanged()"
                        [(ngModel)]="sortType"
                        placeholder="Sort By"
                    >
                        <mat-option [value]="'arrivalDateTimeLocal'"
                            >ETA</mat-option
                        >
                        <mat-option [value]="'departureDateTimeLocal'"
                            >ETD</mat-option
                        >
                        <mat-option [value]="'customerInfoByGroup.company'"
                            >Company</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-3">
                <mat-form-field>
                    <input
                        (dateChange)="applyDateFilterChange()"
                        (focus)="serviceOrdersStartDateFilter.open()"
                        [(ngModel)]="filterStartDate"
                        [matDatepicker]="serviceOrdersStartDateFilter"
                        [max]="filterEndDate"
                        matInput
                        placeholder="Start Date"
                    />
                    <mat-datepicker-toggle
                        [for]="serviceOrdersStartDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #serviceOrdersStartDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-6 col-md-3">
                <mat-form-field>
                    <input
                        (dateChange)="applyDateFilterChange()"
                        (focus)="serviceOrdersEndDateFilter.open()"
                        [(ngModel)]="filterEndDate"
                        [matDatepicker]="serviceOrdersEndDateFilter"
                        [min]="filterStartDate"
                        matInput
                        placeholder="End Date"
                    />
                    <mat-datepicker-toggle
                        [for]="serviceOrdersEndDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #serviceOrdersEndDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row d-none d-md-flex" *ngIf="allowAddingNew">
            <div class="col-12 text-right">
                <div>
                    <button
                        (click)="addServiceOrderClicked()"
                        [view]="'accent'"
                        [disabled]="isFreemiumAccount"
                        beforeIcon="fa fa-plus"
                        class="action-btn"
                        ni-button
                        size="small"
                    >
                        Add Order
                    </button>
                </div>
            </div>
        </div>

        <div
            class="row"
            *ngIf="
                (!inCompleteServiceOrders || inCompleteServiceOrders.length == 0) && !isFreemiumAccount
            "
        >
            <div class="col-12 text-center">
                No service orders found.
                <span *ngIf="allowAddingNew"
                    >You can
                    <a
                        class="btn-link cursor-pointer"
                        (click)="addServiceOrderClicked()"
                        >add one to begin</a
                    >.</span
                >
            </div>
        </div>

        <mat-selection-list
            #incompletedServiceOrders
            (selectionChange)="serviceOrderToggleChanged($event)"
        >
            <mat-list-option
                *ngFor="let order of inCompleteServiceOrders"
                [value]="order"
                [selected]="order.isCompleted"
                togglePosition="before"
                checkboxPosition="before"
                labelPosition="after"
                [ngClass]="{ highlighted: selectedServiceOrder == order}"
            >
                <div clickStopPropagation (click)="serviceOrderClicked(order)" >
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col">
                                    <div>
                                        <span class="pr-3 d-block d-md-inline"
                                            ><span
                                                class="font-weight-bold d-none d-md-inline"
                                                >Customer:</span
                                            >
                                            {{
                                                order.customerInfoByGroup
                                                    .company
                                            }}</span
                                        >
                                        <span class="d-block d-md-inline"
                                            ><span
                                                class="font-weight-bold d-none d-md-inline"
                                                >Tail:
                                            </span>
                                            {{
                                                order.customerAircraft
                                                    .tailNumber
                                            }}</span
                                        >
                                    </div>
                                    <div>
                                        <span class="pr-3 d-block d-md-inline"
                                            ><span
                                                class="font-weight-bold d-none d-md-inline"
                                                >ETA:
                                            </span>
                                            {{
                                                order.arrivalDateTimeLocal
                                                    | date
                                                        : "MMM d, yyyy, @ HH:mm"
                                            }}
                                            (L)</span
                                        >
                                        <span class="d-block d-md-inline"
                                            ><span
                                                class="font-weight-bold d-none d-md-inline"
                                                >ETD:
                                            </span>
                                            {{
                                                order.departureDateTimeLocal
                                                    | date
                                                        : "MMM d, yyyy, @ HH:mm"
                                            }}
                                            (L)</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="small">
                                        <span
                                            >Services:
                                            {{
                                                order.numberOfCompletedItems
                                            }}
                                            of
                                            {{
                                                order.serviceOrderItems.length
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 text-right">
                            <div class="vertical-aligned-button-container">
                                <button
                                    (click)="
                                        deleteServiceOrderClicked(order);
                                        $event.stopPropagation()
                                    "
                                    [disabled]="isFreemiumAccount"
                                    [view]="'error'"
                                    beforeIcon="fa fa-trash"
                                    ni-button
                                    size="small"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-list-option>
        </mat-selection-list>

        <div
            *ngIf="completeServiceOrders && completeServiceOrders.length > 0"
            class="mt-2"
        >
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <strong
                                >Completed ({{
                                    completeServiceOrders.length
                                }})</strong
                            >
                        </mat-panel-title>
                        <mat-panel-description>
                            Service orders that have been completed.
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-selection-list
                        #completedServiceOrders
                        (selectionChange)="serviceOrderToggleChanged($event)"
                    >
                        <mat-list-option
                            *ngFor="let order of completeServiceOrders"
                            [value]="order"
                            [selected]="order.isCompleted"
                            togglePosition="before"
                            checkboxPosition="before"
                            labelPosition="after"
                            [disabled]="isFreemiumAccount"
                        >
                            <div
                                clickStopPropagation
                                (click)="
                                    serviceOrderClicked(order);
                                    serviceOrderDrawer.toggle()
                                "
                            >
                                <div class="row">
                                    <div class="col-10">
                                        <div class="row">
                                            <div class="col">
                                                <div>
                                                    <span
                                                        class="pr-3 d-block d-md-inline"
                                                        ><span
                                                            class="font-weight-bold d-none d-md-inline"
                                                            >Customer:</span
                                                        >
                                                        {{
                                                            order
                                                                .customerInfoByGroup
                                                                .company
                                                        }}</span
                                                    >
                                                    <span
                                                        class="d-block d-md-inline"
                                                        ><span
                                                            class="font-weight-bold d-none d-md-inline"
                                                            >Tail:
                                                        </span>
                                                        {{
                                                            order
                                                                .customerAircraft
                                                                .tailNumber
                                                        }}</span
                                                    >
                                                </div>
                                                <div>
                                                    <span
                                                        class="pr-3 d-block d-md-inline"
                                                        ><span
                                                            class="font-weight-bold d-none d-md-inline"
                                                            >ETA:
                                                        </span>
                                                        {{
                                                            order.arrivalDateTimeLocal
                                                                | date
                                                                    : "MMM d, yyyy, @ HH:mm"
                                                        }}
                                                        (L)</span
                                                    >
                                                    <span
                                                        class="d-block d-md-inline"
                                                        ><span
                                                            class="font-weight-bold d-none d-md-inline"
                                                            >ETD:
                                                        </span>
                                                        {{
                                                            order.departureDateTimeLocal
                                                                | date
                                                                    : "MMM d, yyyy, @ HH:mm"
                                                        }}
                                                        (L)</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="small">
                                                    <span
                                                        >Services:
                                                        {{
                                                            order.numberOfCompletedItems
                                                        }}
                                                        of
                                                        {{
                                                            order
                                                                .serviceOrderItems
                                                                .length
                                                        }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2 text-right">
                                        <div
                                            class="vertical-aligned-button-container"
                                        >
                                            <button
                                                (click)="
                                                    deleteServiceOrderClicked(
                                                        order
                                                    );
                                                    $event.stopPropagation()
                                                "
                                                [view]="'error'"
                                                beforeIcon="fa fa-trash"
                                                ni-button
                                                size="small"
                                                [disabled]="isFreemiumAccount"
                                            >
                                                <span>Delete</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</mat-drawer-container>
