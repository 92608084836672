
    <div class="row text-md-center">
        <div class="col-12 col-md-6 pb-2" *ngIf="enableSaf">
            <ni-card [align]="'left'"
                     bgColor="success"
                     class="current-price-panel mb-0"
                     headerBgColor="success"
                     outline="true"
                     theme="tiny"
                     title="SAF">
                <div class="header-button" style="position: absolute; right: 10px; top: -30px" *ngIf="retailSaf > 0">
                    <button (click)="clear('SAF')"
                            color="warn" mat-flat-button size="small" class="mr-1">
                        Clear
                    </button>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="row">
                        <div class="col">
                            <h6 class="text-muted mt-0 mb-1 pr-2 font-weight-normal">Retail:</h6>
                        </div>
                        <div class="col">
                            <h6 class="mt-0 mb-1 font-weight-bold">
                                <span *ngIf="retailSaf > 0">${{ retailSaf | decimalPrecision }}</span>
                                <span *ngIf="retailSaf <= 0">Expired</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="row">
                        <div class="col">
                            <h6 class="text-muted mt-0 mb-1 pr-3 font-weight-normal">Cost:</h6>
                        </div>
                        <div class="col">
                            <h6 class="mt-0 mb-1 font-weight-bold">
                                <span *ngIf="costSaf > 0">${{ costSaf | decimalPrecision }}</span>
                                <span *ngIf="costSaf <= 0">Expired</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div *ngIf="retailSaf > 0" class="d-flex justify-content-between">
                    <h6 class="mt-0 mb-1 font-weight-normal price-expired">{{effectiveToSaf}}</h6>
                </div>
</ni-card>
        </div>
        <div class="col-sm-12 col-md-6" *ngIf="enableJetA">
            <ni-card [align]="'left'"
                     bgColor="success"
                     class="current-price-panel mb-0"
                     headerBgColor="success"
                     outline="true"
                     theme="tiny"
                     title="Jet-A">
                <div class="header-button" style="position: absolute; right: 10px; top: -30px" *ngIf="retailJetA > 0 || costJetA > 0">
                    <button (click)="clear('JetA')" class="mr-1" color="warn" mat-flat-button size="small">
                        Clear
                    </button>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="row">
                        <div class="col">
                            <h6 class="text-muted mt-0 mb-1 pr-2 font-weight-normal">Retail:</h6>
                        </div>
                        <div class="col">
                            <h6 class="mt-0 mb-1 font-weight-bold">
                                <span *ngIf="retailJetA > 0">${{ retailJetA | decimalPrecision }}</span>
                                <span *ngIf="retailJetA <= 0">Expired</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="row">
                        <div class="col">
                            <h6 class="text-muted mt-0 mb-1 pr-3 font-weight-normal">Cost:</h6>
                        </div>
                        <div class="col">
                            <h6 class="mt-0 mb-1 font-weight-bold">
                                <span *ngIf="costJetA > 0">${{ costJetA | decimalPrecision }}</span>
                                <span *ngIf="costJetA <= 0">Expired</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div *ngIf="retailJetA > 0" class="d-flex justify-content-between">
                    <h6 class="mt-0 mb-1 font-weight-normal price-expired">{{effectiveToJetA}}</h6>
                </div>
            </ni-card>
        </div>
    </div>

