<fee-and-tax-breakdown
    (omitCheckChanged)="data.omitCheckChanged"
    [customerMargin]="data.customerMargin"
    [displayMode]="0"
    [fboPrice]="data.fboPrice"
    [feesAndTaxes]="data.feesAndTaxes"
    [marginType]="data.marginType"
    [discountType]="data.discountType"
    [validDepartureTypes]="data.validDepartureTypes"
    [validFlightTypes]="data.validFlightTypes"
></fee-and-tax-breakdown>
