<div class="card-wrap">
    <h3 class="card-header h5">
        <div class="row">
            <div class="col-12">
                <span class="card-header-title">
                    {{airportFboGeofenceGridItem.icao}}
                </span>
                <button (click)="closeGeofenceEdit()" class="float-right close-btn"
                        mat-icon-button>
                    <mat-icon aria-hidden="false">close</mat-icon>
                </button>
            </div>
        </div>
    </h3>
    <div class="card-content edit-customer-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <ngx-ui-loader [loaderId]="loaderName"
                                   bgsColor="rgb(100, 181, 246)"
                                   bgsPosition="center-center"
                                   bgsType="ball-spin"
                                   overlayColor="rgba(0, 0, 0, 0.1)"></ngx-ui-loader>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center mb-3" *ngIf="isEditing && activeCluster">
                    You are currently editing the cluster for {{this.activeCluster.fboName}}.  Click on the map to drop points for your fence.
                </div>
            </div>

            <div class="row">

                <div class="col-md-8" [ngClass]="{'is-editing': (isEditing)}">
                    <div id="fbo-geofencing-map" style="min-height: 600px;"></div>
                    <div id="fbo-geofencing-layer-menu">
                        <input id="default-fbolinx" type="radio" name="rtoggle" value="default" checked="checked" (click)="layerToggleClicked('default')">
                        <!-- See a list of Mapbox-hosted public styles at -->
                        <!-- https://docs.mapbox.com/api/maps/styles/#mapbox-styles -->
                        <label for="default-fbolinx">Default</label>
                        <input id="satellite-v9" type="radio" name="rtoggle" value="satellite" (click)="layerToggleClicked('satellite-v9')">
                        <!-- See a list of Mapbox-hosted public styles at -->
                        <!-- https://docs.mapbox.com/api/maps/styles/#mapbox-styles -->
                        <label for="satellite-v9">Satellite</label>
                        <input id="light-v10" type="radio" name="rtoggle" value="light" (click)="layerToggleClicked('light-v10')">
                        <label for="light-v10">Light</label>
                        <input id="dark-v10" type="radio" name="rtoggle" value="dark" (click)="layerToggleClicked('dark-v10')">
                        <label for="dark-v10">Dark</label>
                        <input id="streets-v11" type="radio" name="rtoggle" value="streets" (click)="layerToggleClicked('streets-v11')">
                        <label for="streets-v11">Streets</label>
                        <input id="outdoors-v11" type="radio" name="rtoggle" value="outdoors" (click)="layerToggleClicked('outdoors-v11')">
                        <label for="outdoors-v11">Outdoors</label>
                    </div>
                </div>

                <div class="col-4">
                    <div class="container-fluid">
                        <div class="row pb-2">
                            <div class="col-9">
                                <div>
                                    <!--Ramp GeoFences-->
                                    <mat-form-field>
                                        <input (keyup)="onClusterFilterChanged($event.target.value)"
                                               placeholder="Search Geo-Fences"
                                               matInput />
                                        <span matPrefix><i class="fa fa-search"></i></span>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-3">
                                <button (click)="newClusterClicked()" [view]="'accent'" beforeIcon="fa fa-plus" class="action-btn" size="small"
                                        ni-button>
                                    Add New
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div class="row">
                            <div class="col-12">

                            </div>
                        </div>

                        <div class="row pt-2" *ngIf="!clustersFiltered || clustersFiltered.length == 0">
                            <div class="col-12">
                                No records to display
                            </div>
                        </div>

                        <div class="row pt-2" *ngFor="let cluster of clustersFiltered">
                            <div class="col-12" (mouseenter)="onMouseEnterFbo(cluster)" (mouseleave)="onMouseLeaveFbo(cluster)">
                                <div class="row" [ngClass]="{'inactive-custer': (activeCluster && activeCluster != cluster)}">
                                    <div class="col-9">
                                        {{cluster.fboName}}
                                    </div>
                                    <div class="col-3" *ngIf="isEditing && activeCluster == cluster">
                                        <button (click)="stopEditingRowClicked(cluster)" [view]="'basic'" beforeIcon="fa fa-stop" class="action-btn" ni-button size="small">
                                            Stop Editing
                                        </button>
                                    </div>
                                    <div class="col-3" *ngIf="!isEditing">
                                        <button (click)="deleteRowClicked(cluster)" [view]="'error'" beforeIcon="fa fa-trash" class="action-btn" ni-button size="small">
                                            Delete
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>




