<h1 mat-dialog-title>Fuel Orders Export</h1>
<div class="mt-1 mb-2" mat-dialog-content>
    <div class="row">
        <div class="col-6">
            <mat-form-field>
                <input
                    [(ngModel)]="data.filterStartDate"
                    [matDatepicker]="dashboardSettingsStartDateFilter"
                    [max]="data.filterEndDate"
                    matInput
                    placeholder="Start Date"
                />
                <mat-datepicker-toggle
                    [for]="dashboardSettingsStartDateFilter"
                    matSuffix
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dashboardSettingsStartDateFilter
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field>
                <input
                    [(ngModel)]="data.filterEndDate"
                    [matDatepicker]="dashboardSettingsEndDateFilter"
                    [min]="data.filterStartDate"
                    matInput
                    placeholder="End Date"
                />
                <mat-datepicker-toggle
                    [for]="dashboardSettingsEndDateFilter"
                    matSuffix
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dashboardSettingsEndDateFilter
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="text-center col-12">
        <button
            [mat-dialog-close]="data"
            class="mr-1"
            color="accent"
            mat-flat-button
        >
            Export
        </button>
        <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
    </div>
</div>
