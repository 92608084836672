<ni-card>
    <div *ngIf="isFuelOrdersShowing">
        <div *ngIf="fuelreqsData">
            <app-fuelreqs-grid
                (dateFilterChanged)="dateFilterChanged($event)"
                [filterEndDate]="filterEndDate"
                [filterStartDate]="filterStartDate"
                [fuelreqsData]="fuelreqsData"
                [servicesAndFees]="servicesAndFees"
            ></app-fuelreqs-grid>
        </div>

        <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
    </div>
</ni-card>
