<h1 mat-dialog-title>New Aircraft</h1>
<div mat-dialog-content>
    <div>
        <mat-form-field>
            <input
                [(ngModel)]="data.tailNumber"
                aria-label="Tail Number"
                cdkFocusInitial
                matInput
                placeholder="Tail Number"
            />
        </mat-form-field>
    </div>
    <div>
        <app-autocomplete-search
            label="Aircraft Make/Model"
            [optionValue]="['make', 'model']"
            [options]="aircraftTypes"
            [displayFn]="displayAircraft"
            (selectionChanged)="onAircraftTypeChanged($event)"
        ></app-autocomplete-search>
        <!-- <mat-form-field>
            <mat-select (selectionChange)="onAircraftTypeChanged()" [(ngModel)]="data.selectedAircraft"
                        placeholder="Aircraft Make/Model">
                <mat-option *ngFor="let aircraftType of aircraftTypes" [value]="aircraftType">
                    {{aircraftType.make}} {{aircraftType.model}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
</div>
<div class="mt-2 text-center">
    <button
        [disabled]="!data.tailNumber || !data.selectedAircraft"
        [mat-dialog-close]="data"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        Add Aircraft
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
</div>
