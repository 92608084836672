import { Component } from "@angular/core";

@Component({
    selector: 'app-lobby-view',
    templateUrl: './lobby-view.component.html',
    styleUrls: ['./lobby-view.component.scss'],
})
export class LobbyViewComponent {
    constructor() {}
}
