<section>
    <div *ngIf="!customerForm">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="customerForm" [formGroup]="customerForm">

        <div class="card-wrap">
            <h3 class="card-header h5">
                <div class="row">
                    <div class="col-12">
                        <span class="card-header-title">{{
                            customerForm.value.company
                            }}</span>
                        <button [disabled]="isEditing" (click)="cancelCustomerEdit()" class="float-right close-btn" *ngIf="showClose"
                            mat-icon-button>
                            <mat-icon aria-hidden="false">close</mat-icon>
                        </button>
                    </div>
                </div>
            </h3>
            <div *ngIf="loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="card-content edit-customer-content">
                <mat-tab-group [selectedIndex]="selectedIndex" (selectedTabChange)="tabClick($event)">
                    <mat-tab label="Customer Details">
                        <div class="container-fluid" >
                            <h5>General Information</h5>

                            <div class="row mb-2">
                                <div class="col-md-6">
                                    <mat-slide-toggle (change)="toggleChange($event)" color="accent"
                                                      formControlName="active">Active
                                    </mat-slide-toggle>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-form-field>
                                                <input formControlName="company" matInput placeholder="Company" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field>
                                                <mat-select multiple formControlName="customerTag" placeholder="Customer Tags">
                                                    <mat-select-trigger>
                                                        <mat-chip-list>
                                                            <mat-chip *ngFor="let tag of tagsSelected" [removable]="true"
                                                                      (removed)="removeCustomerTag(tag)" [value]="tag.oid">
                                                                {{ tag.name }}
                                                                <mat-icon matChipRemove>cancel</mat-icon>
                                                            </mat-chip>
                                                        </mat-chip-list>
                                                    </mat-select-trigger>

                                                    <mat-option [disabled]="isOptionDisabled(tag.oid)" *ngFor="
                                                    let tag of tags
                                                " [value]="
                                                    tag.oid
                                                ">
                                                        {{ tag.name }}
                                                    </mat-option>
                                                    <mat-option [value]="-1" class="hide-checkbox"
                                                                [disabled]="isOptionDisabled(-1)" (click)="newCustomTag()">
                                                        &lt;Add Custom&gt;
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field>
                                                <mat-select formControlName="certificateType" placeholder="Certificate Type">
                                                    <mat-option *ngFor="
                                                    let certificateType of certificateTypes
                                                " [value]="certificateType.value">
                                                        {{
                                                        certificateType.description
                                                        }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-12">
                                            <mat-form-field>
                                                <input formControlName="address" matInput placeholder="Address" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-4">
                                            <mat-form-field>
                                                <input formControlName="state" matInput placeholder="State" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-4">
                                            <mat-form-field>
                                                <input formControlName="city" matInput placeholder="City" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-4">
                                            <mat-form-field>
                                                <input formControlName="zipCode" matInput placeholder="Zip Code" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field>
                                                <input formControlName="country" matInput placeholder="Country" />
                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>

                                <!--Notes here - Use some inline style to match the material layout since there isn't a good native textarea for Angular Material-->
                                <!--Transition this to a common stylesheet if we find ourselves needing it elsewhere-->
                                <div class="col-12 col-sm-6">
                                    <div class="row" style="height: 100%;">
                                        <div class="col-12" class="notes-label">
                                            <label>Notes</label>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field>
                                            <textarea matInput
                                                          class="notes-textarea"
                                                          formControlName="editableNote"
                                                          [(ngModel)]="customerInfoByGroupNote.notes"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <h5>Contacts</h5>

                            <!--Contacts Grid-->
                            <div *ngIf="
                                    contactsData && !currentContactInfoByGroup
                                ">
                                <app-contacts-grid
                                    (newContactClicked)="newContactClicked()" [contactsData]="contactsData">
                                </app-contacts-grid>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Aircraft" *ngIf="showAircraftTab">
                        <!--Customer Aircraft Grid-->
                        <div *ngIf="customerAircraftsData && customerInfoByGroup">
                            <app-customer-aircrafts-grid (updateCustomerPricingTemplate)="
                                    updateCustomerPricingTemplate($event)
                                " [customerAircraftsData]="customerAircraftsData" [customer]="customerInfoByGroup"
                                [pricingTemplatesData]="pricingTemplatesData" [search]="searchText"></app-customer-aircrafts-grid>
                        </div>
                    </mat-tab>

                    <mat-tab label="Pricing" *ngIf="showPricingTab">
                        <div class="container-fluid">
                            <h5>Price & Margin Information</h5>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Margin Template</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="customCustomerType">
                                        <td>
                                            <mat-form-field class="template-dropdown">
                                                <mat-select formControlName="customerMarginTemplate"
                                                    placeholder="Margin Template">
                                                    <mat-option *ngFor="
                                                            let pricingTemplate of pricingTemplatesData
                                                        " [value]="
                                                            pricingTemplate.oid
                                                        ">
                                                        {{
                                                        pricingTemplate.name
                                                        }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div *ngIf="feesAndTaxes" class="sub-widget">
                                <price-breakdown #priceBreakdownPreview (omitCheckChanged)="
                                        omitFeeAndTaxCheckChanged($event)
                                    " [customerInfoByGroupId]="
                                        customerInfoByGroup.oid
                                    " [feeAndTaxDisplayMode]="1" [feesAndTaxes]="feesAndTaxes" [priceTemplateId]="
                                        customCustomerType.customerType
                                    " tooltipPlacement="left"
                                                 (calculationsComplated)="priceBreakdownCalculationsCompleted($event)
                                     " [isMember]="isMember" ></price-breakdown>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Customer Analytics" *ngIf="showAnalyticsTab">
                        <app-customers-analytics [customerId]="customerId"></app-customers-analytics>
                   </mat-tab>


                    <mat-tab label="History" *ngIf="showHistoryTab">
                        <div *ngIf="!isLoadingHistory">
                            <app-customer-history [customerHistory]="customerHistory">

                            </app-customer-history>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</section>
