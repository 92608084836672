<div>
    <mat-form-field>
        <input
            (keyup)="applyFilter($event.target.value)"
            matInput
            placeholder="Search by ICAO or FBO Name"
            value="{{ searchValue }}"
        />
        <span matPrefix><i class="fa fa-search"></i></span>
    </mat-form-field>

    <table
        [dataSource]="fbosDataSource"
        class="col-md-12"
        mat-table
        matSort
        matSortActive="{{ tableSortFbos }}"
        matSortDirection="{{ tableSortOrderFbos }}"
    >
        <ng-container matColumnDef="icao">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>ICAO</th>
            <td *matCellDef="let fbo" mat-cell>{{ fbo.icao }}</td>
        </ng-container>

        <ng-container matColumnDef="fbo">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>FBO</th>
            <td *matCellDef="let fbo" mat-cell>{{ fbo.fbo }}</td>
        </ng-container>

        <ng-container matColumnDef="price">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                Retail / Cost
            </th>
            <td *matCellDef="let fbo" mat-cell>
                <span
                    [ngClass]="{
                        'price-expired': !fbo.retailPrice,
                        'price-live': fbo.retailPrice,
                        'font-weight-bold': true
                    }"
                    >{{
                        fbo.retailPrice
                            ? "$" + (fbo.retailPrice | decimalPrecision)
                            : "Expired"
                    }}</span
                >
                /
                <span
                    [ngClass]="{
                        'price-expired': !fbo.costPrice,
                        'price-live': fbo.costPrice,
                        'font-weight-bold': true
                    }"
                    >{{
                        fbo.costPrice
                            ? "$" + (fbo.costPrice | decimalPrecision)
                            : "Expired"
                    }}</span
                >
            </td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                Pricing Status
            </th>
            <td *matCellDef="let fbo" mat-cell>
                <span
                    [ngClass]="{
                        'price-expired': !fbo.retailPrice && !fbo.costPrice,
                        'price-live': fbo.retailPrice || fbo.costPrice,
                        'font-weight-bold': true
                    }"
                >
                    {{
                        !fbo.retailPrice && !fbo.costPrice
                            ? "Expired"
                            : "Pricing Live"
                    }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="edit">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let fbo" mat-cell>
                <div class="pull-right">
                    <button
                        (click)="editRecord(fbo); $event.stopPropagation()"
                        [view]="'warning'"
                        beforeIcon="fa fa-pencil"
                        ni-button
                        size="small"
                    >
                        Edit
                    </button>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th *matHeaderCellDef mat-header-cell>
                <div class="pull-right">
                    <button
                        (click)="newRecord(); $event.stopPropagation()"
                        *ngIf="canManageFbo"
                        [view]="'accent'"
                        beforeIcon="fa fa-plus"
                        ni-button
                    >
                        Add New
                    </button>
                </div>
            </th>
            <td *matCellDef="let fbo" mat-cell>
                <div class="pull-right">
                    <button
                        (click)="deleteRecord(fbo); $event.stopPropagation()"
                        [view]="'error'"
                        beforeIcon="fa fa-trash"
                        ni-button
                        size="small"
                    >
                        Delete
                    </button>
                </div>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
            (click)="manageFBO(row, $event)"
            *matRowDef="let row; columns: displayedColumns"
            mat-row
        ></tr>
    </table>
</div>

<div>
    <mat-paginator
        (page)="(pageIndexFbos)"
        [length]="resultsLength"
        [pageSizeOptions]="[10, 25, 50, 100]"
        [pageSize]="pageSizeFbos"
    ></mat-paginator>
</div>
