<app-horizontal-navbar (sidebarState)="sidebarState()"
[title]="pageTitle"></app-horizontal-navbar>

<app-vertical-navbar [ngClass]="{ hideSidebar: !openedSidebar }"
                     [isMenuVisible]="openedSidebar"></app-vertical-navbar>

<div [ngClass]="layoutClasses" class="site-container">

    <div [ngClass]="{ 'full-width': !openedSidebar}" class="container-fluid sticky-panel" *ngIf="isPricePanelVisible()">
        <div class="row" style="align-items: center;">
            <div class="col-2 pl-5" *ngIf="!isJetNetIntegrationEnabled">
                &nbsp;
            </div>
            <div class="col-2 pl-5" *ngIf="isJetNetIntegrationEnabled">
                <app-jetnet-search></app-jetnet-search>
            </div>

            <div [ngClass]="{ 'row-csr' : isCsr }" *ngIf="!isCsr" class="col-10 text-md-right pt-md-0">
                <div class="d-inline-block">
                    <app-fbo-prices-panel [enableSaf]="enableSaf"
                                          [enableJetA]="enableJetA"
                                          [costSaf]="costSaf"
                                          [retailSaf]="retailSaf"
                                          [costJetA]="costJetA"
                                          [retailJetA]="retailJetA"
                                          [effectiveToSaf]="effectiveToSaf"
                                          [effectiveToJetA]="effectiveToJetA"
                                          (onClearFboPrice)="onClearFboPrice($event)"></app-fbo-prices-panel>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isAdditionNavBarVisible()">
        <div *ngIf="pricingTemplatesData && isNotGroupAdmin">
            <app-addition-navbar #insideElement
                                [templatelst]="pricingTemplatesData"></app-addition-navbar>
        </div>
    </ng-container>
    <main [ngClass]="{ 'full-width': !openedSidebar }"
          class="main-content">

        <div class="main-content-wrap">
            <app-demo-request-static-dialog></app-demo-request-static-dialog>
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </main>
</div>
