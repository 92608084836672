<div class="customer-aircraft-table" style="position: relative">
    <div class="container-fluid">
        <div class="row">
            <div class="col-11">
                <mat-form-field>
                    <input (keyup)="applyFilter($event.target.value)"
                           matInput
                           placeholder="Search by Tail, Make, Model, etc." />
                    <span matPrefix><i class="fa fa-search"></i></span>
                </mat-form-field>
            </div>

        </div>
    </div>

        <div class="w-100 table-container" style="overflow-x: auto; position: relative;"  (scroll)="onTableScroll($event)">
            <table [dataSource]="dataSource"
                   class="col-md-12"
                   mat-table
                   matSort
                   matSortActive="tailNumber"
                   matSortDirection="asc">
                <ng-container matColumnDef="tailNumber">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Tail</th>
                    <td *matCellDef="let aircraft"
                        mat-cell>
                        <div class="row">
                            <app-favorite-icon
                            [favoriteData]="setIsFavoriteProperty(aircraft)"
                            [callbackComponent]="getCallBackComponent"
                            ></app-favorite-icon>
                            <span (click)="editCustomerAircraft(aircraft)">{{ aircraft.tailNumber }}</span>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="aircraftType">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
                    <td (click)="editCustomerAircraft(aircraft)"
                        *matCellDef="let aircraft"
                        mat-cell>
                        {{ getAircrafttypeDisplayString(aircraft) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="aircraftSize">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Size</th>
                    <td (click)="editCustomerAircraft(aircraft)"
                        *matCellDef="let aircraft"
                        mat-cell>
                        {{ aircraft.aircraftSizeDescription }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="company">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>
                        Company
                    </th>
                    <td (click)="editCustomerAircraft(aircraft)"
                        *matCellDef="let aircraft"
                        mat-cell>
                        {{ aircraft.company }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="aircraftPricingTemplate">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>
                        Pricing
                    </th>
                    <td *matCellDef="let aircraft" clickStopPropagation mat-cell>
                        <mat-form-field floatLabel="never">
                            <mat-label>Company Pricing</mat-label>
                            <mat-select (selectionChange)="onMarginChange($event, aircraft)"
                                        [value]="aircraft.pricingTemplateId">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="
                                    let pricingTemplate of pricingTemplatesData
                                "
                                            [value]="pricingTemplate.oid">{{ pricingTemplate.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
            </table>
            <div class="invisible">
                <mat-paginator (page)="(0); onPageChanged($event)"
                [length]="resultsLength"
                [pageSizeOptions]="[10, 25, 50]"
                [pageSize]="pageSize"></mat-paginator>
            </div>
        </div>
</div>
