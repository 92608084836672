<section>
    <div *ngIf="!pricingTemplate">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="pricingTemplateForm" [formGroup]="pricingTemplateForm" class="pricing-template-edit">
        <div *ngIf="isSaving" class="ml-1 mb-1 text-success">
            Saving Changes <i class="fa fa-spinner fa-spin"></i>
        </div>
        <div *ngIf="hasSaved" class="ml-1 mb-1 text-success">
            Changes Saved!
        </div>
        <div class="card-wrap">
            <h3 class="card-header h5">
                <div class="row">
                    <div class="col-12">
                        <span class="card-header-title">{{
                            pricingTemplateForm.value.name
                        }}</span>
                        <button
                            (click)="cancelPricingTemplateEdit()"
                            class="float-right close-btn"
                            mat-icon-button
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
            </h3>
            <div class="card-content">
                <div class="container-fluid">
                    <mat-tab-group>
                        <mat-tab label="General Information">
                            <h5>General Information</h5>
                            <div class="d-inline-block" style="width: 300px">
                                <mat-slide-toggle color="accent"
                                                  formControlName="default">Default</mat-slide-toggle>
                            </div>
                            <div class="d-inline-block">
                                <div style="width: 300px">
                                    <mat-form-field>
                                        <mat-select formControlName="emailContentId"
                                                    placeholder="Select Email Template">
                                            <mat-option *ngFor="
                                                    let emailContentTemplate of emailTemplatesDataSource
                                                "
                                                        [value]="
                                                    emailContentTemplate.oid
                                                ">
                                                {{ emailContentTemplate.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="mt-2 d-flex">
                                <div class="pr-3" style="width: 300px">
                                    <mat-form-field>
                                        <input formControlName="name"
                                               matInput
                                               placeholder="Name" />
                                    </mat-form-field>
                                </div>
                                <div style="width: 300px" class="pr-3">
                                    <mat-form-field>
                                        <mat-select formControlName="marginType"
                                                    placeholder="Type">
                                            <mat-option *ngFor="
                                                    let marginType of marginTypeDataSource
                                                "
                                                        [value]="marginType.value">
                                                {{ marginType.text }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="pr-3" style="width: 300px">
                                    <mat-form-field>
                                        <mat-select formControlName="discountType"
                                                    placeholder="Type">
                                            <mat-option *ngFor="
                                                    let discountType of discountTypeDataSource
                                                "
                                                        [value]="discountType.value">
                                                {{ discountType.text }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                            <h5 class="mt-4">Volume Tiers</h5>
                            <table class="
                                    mat-table
                                    table-responsive
                                    volume-tiers-table
                                ">
                                <thead>
                                    <tr class="mat-header-row">
                                        <th>
                                            <div class="">Min. (gal)</div>
                                        </th>
                                        <th>
                                            <div class="">Max. (gal)</div>
                                        </th>
                                        <th>
                                            <div class="">Margin</div>
                                        </th>
                                        <th>
                                            <div>
                                                Total
                                                <a matTooltip="Please see the scenario pricing tab for out-the-door pricing that includes taxes & fees"
                                                   matTooltipPosition="after" style="float: right">
                                                    <i class="fa fa-info-circle"></i>
                                                </a>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ml-4">
                                                <button (click)="
                                                        addCustomerMargin()
                                                    "
                                                        [view]="'accent'"
                                                        beforeIcon="fa fa-plus"
                                                        ni-button>
                                                    Add New
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="customerMargins">
                                    <tr *ngFor="
                                            let customerMargin of customerMarginsFormArray.controls;
                                            let i = index
                                        "
                                        class="mat-row">
                                        <td [formGroupName]="i" class="">
                                            <div class="volume-tiers-width">
                                                <mat-form-field class="">
                                                    <input formControlName="min"
                                                           (blur)="updateCustomerMarginVolumeValues(i)"
                                                           matInput />
                                                </mat-form-field>
                                            </div>
                                        </td>
                                        <td class="">
                                            <div class="volume-tiers-width">
                                                <mat-form-field>
                                                    <input disabled="disabled"
                                                           matInput
                                                           value="{{
                                                            (customerMargin.value
                                                                .max == 0
                                                                ? 99999
                                                                : customerMargin
                                                                      .value.max) | decimalPrecision
                                                        }}" />
                                                </mat-form-field>
                                            </div>
                                        </td>
                                        <td
                                            [formGroupName]="i">
                                            <div class="volume-tiers-width">
                                                <mat-form-field style="width:100px">
                                                    <input (click)="
                                                            $event.target.select()
                                                        "
                                                           formControlName="amount"
                                                           matInput
                                                           [step]="inputStepDefaultValue"
                                                           type="number" />
                                                    <span *ngIf="
                                                    pricingTemplateForm.value
                                                        .discountType == 0 "
                                                          class="prefixDollar"
                                                          matPrefix>$</span>
                                                    <span *ngIf="
                                                    pricingTemplateForm.value
                                                        .discountType == 1 "
                                                          class="prefixPercentage"
                                                          matSuffix>%</span>

                                                </mat-form-field>
                                            </div>
                                        </td>
                                        <!--TOTAL-->
                                        <td [formGroupName]="i"
                                            class="font-weight-bold font-italic">
                                            <div>
                                                <div *ngIf="
                                                pricingTemplateForm.value
                                                    .marginType == 0 &&
                                                pricingTemplateForm.value
                                                    .discountType != 1">
                                                    {{ (jetACost-0) + (customerMargin.value.amount-0) | currencyPresicion }}
                                                </div>
                                                <div *ngIf="
                                                pricingTemplateForm.value
                                                    .marginType == 0 &&
                                                pricingTemplateForm.value
                                                    .discountType == 1">
                                                    {{ (jetACost-0) + ((jetACost-0) * ((customerMargin.value.amount-0) / 100)) | currencyPresicion }}
                                                </div>
                                                <div *ngIf="
                                                pricingTemplateForm.value
                                                    .marginType == 1 &&
                                                pricingTemplateForm.value
                                                    .discountType != 1">
                                                    {{ (jetARetail-0) - (customerMargin.value.amount-0) | currencyPresicion }}
                                                </div>
                                                <div *ngIf="
                                                pricingTemplateForm.value
                                                    .marginType == 1 &&
                                                pricingTemplateForm.value
                                                    .discountType == 1">
                                                    {{ (jetARetail-0) - ((jetARetail-0) * (customerMargin.value.amount-0) / 100) | currencyPresicion }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class="">
                                            <div class="ml-4" *ngIf="customerMarginsFormArray.length > 1">
                                                <button (click)="
                                                        deleteCustomerMargin(i)
                                                    "
                                                        [view]="'error'"
                                                        beforeIcon="fa fa-trash"
                                                        ni-button
                                                        size="small">
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <h5 class="mt-2">Tax/Fee Exclusions</h5>
                            <div
                                 *ngIf="feesAndTaxes">
                                <price-breakdown #feeAndTaxGeneralBreakdown
                                                 (omitCheckChanged)="
                                        omitFeeAndTaxCheckChanged($event)
                                    "
                                                 [feeAndTaxDisplayMode]="1"
                                                 [feesAndTaxes]="feesAndTaxes"
                                                 [priceTemplateId]="pricingTemplate.oid"
                                                 priceBreakdownLoader="ITP-tax-fees-exclusion-loader"
                                                 tooltipPlacement="left"
                                                 [hidePriceTierBreakdown]="true"
                                                 [isMember]="isMember"></price-breakdown>
                            </div>

                            <h5 class="mt-2">Fuel Uplift Notes</h5>
                            <div style="height:600px;">
                                <ejs-richtexteditor #typeRTE
                                                    formControlName="notes"
                                                    id="rteNotes"
                                                    height="400"
                                                    [toolbarSettings]='tools' (actionBegin)="onActionBegin($event)"></ejs-richtexteditor>
                                <!--
    <h6 class="mt-2">File Attachment</h6>
    <div>
        <input type="file"
               (change)="onFileChange($event)"
               accept=".doc,.docx,.xls,.xlsx,.csv,.pdf"
               #fileUpload />
        <button *ngIf="!isUploadingFile"
                (click)="uploadFile()"
                class="ml-3"
                color="accent"
                mat-raised-button>
            Upload File
        </button>
        <div *ngIf="isUploadingFile">
            <mat-spinner></mat-spinner>
        </div>
        <div class="mt-2" *ngIf="fileName != ''">
            <button (click)="downloadFile()" mat-stroked-button>
                Download {{fileName}}
            </button>
        </div>
        <div class="mt-2" *ngIf="fileName != ''">
            <button (click)="deleteFile()" class="btn btn-danger btn-sm">
                Delete
            </button>
        </div>
    </div>
        -->
                            </div>
                        </mat-tab>

                        <mat-tab label="Scenario Pricing">
                            <h5 class="mt-4">Template Prices</h5>

                            <div class="mt-4 sub-widget">
                                <price-breakdown
                                    #priceBreakdownPreview
                                    [hideFeeAndTaxGeneralBreakdown]="true"
                                    [priceTemplateId]="pricingTemplate.oid"
                                    [feesAndTaxes]="feesAndTaxes"
                                    tooltipPlacement="bottom"
                                    priceBreakdownLoader="ITP-scenario-pricing-loader"
                                ></price-breakdown>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</section>
