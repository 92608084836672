<h1 mat-dialog-title>Table Settings</h1>
<div class="mt-1 mb-2" mat-dialog-content>
    <div class="columns-container">
        <h5>Columns</h5>

        <div
            #visibleList="cdkDropList"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListData]="columns"
            cdkDropList
            class="columns-list"
        >
            <div *ngFor="let column of columns" cdkDrag class="columns-box">
                <mat-icon>drag_indicator</mat-icon>
                <mat-checkbox
                    (change)="toggleColumnVisible(column)"
                    [checked]="!column.hidden"
                ></mat-checkbox>
                <span class="column-name">{{ column.name }}</span>
                <mat-button-toggle-group
                    appearance="legacy"
                    aria-label="Sort Order"
                >
                    <mat-button-toggle
                        (change)="onSortChange(column)"
                        [checked]="
                            column.sort === 'asc' || column.sort === 'desc'
                        "
                    >
                        <mat-icon *ngIf="!column.sort">swap_vert</mat-icon>
                        <mat-icon *ngIf="column.sort === 'asc'"
                            >arrow_upward</mat-icon
                        >
                        <mat-icon *ngIf="column.sort === 'desc'"
                            >arrow_downward</mat-icon
                        >
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <div class="text-center col-12">
        <button
            (click)="onSave()"
            [disabled]="isSaveButtonDisabled"
            class="mr-1"
            color="accent"
            mat-raised-button
        >
            Save
        </button>
        <button (click)="onCancel()" mat-stroked-button>Dismiss</button>
    </div>
</div>
