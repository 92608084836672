<ni-card *ngIf="options.useCard"
         [bgColor]="'info'"
         [outline]="true"
         class="info-card">
    <h6 class="mt-0 title">Your Direct FBOLinx Orders</h6>
    <div class="count">{{ totalOrders }}</div>
    <small *ngIf="dayDifference <= 60">(Last {{ dayDifference }} days) Not Including Contract Fuel</small>
    <small *ngIf="dayDifference > 60">(Last {{ monthDifference }} months) Not Including Contract Fuel</small>
    <mat-icon>shopping_basket</mat-icon>
</ni-card>

<div *ngIf="!options.useCard">Orders: {{ totalOrders }}</div>
