import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-services-and-fees-home',
  templateUrl: './services-and-fees-home.component.html',
  styleUrls: ['./services-and-fees-home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServicesAndFeesHomeComponent implements OnInit {
    constructor() { }

    ngOnInit() {
    }

}
