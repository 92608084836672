<section>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
    <div>
        <mat-accordion class="example-headers-align" multi>
            <ng-container
                *ngFor="
                    let service of servicesAndFeesGridDisplay;
                    let i = index
                "
            >
                <mat-expansion-panel hideToggle #servicesRef>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon *ngIf="!servicesRef.expanded"
                                >keyboard_arrow_right</mat-icon
                            >
                            <mat-icon *ngIf="servicesRef.expanded"
                                >keyboard_arrow_down</mat-icon
                            >
                            {{ service.serviceType.name }}
                        </mat-panel-title>
                        <mat-panel-description
                            *ngIf="service.serviceType.isCustom"
                        >
                            <span
                                (click)="
                                    openCategoryDialog(service.serviceType)
                                "
                            >
                                <mat-icon class="material-icons-outlined"
                                    >edit</mat-icon
                                >
                            </span>
                            <span (click)="deleteCategory(service.serviceType)">
                                <mat-icon class="material-icons-outlined"
                                    >delete</mat-icon
                                >
                            </span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="row services-cards-container mat-elevation-z">
                        <div
                            class="col-5 service-card"
                            *ngFor="let val of service.servicesAndFees"
                        >
                            <ng-container *ngIf="val.isEditMode">
                                <div class="col-7">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            placeholder="Service Name"
                                            [(ngModel)]="val.editedValue"
                                        />
                                    </mat-form-field>
                                </div>
                                <!-- Submit button -->
                                <div class="actions-buttons col-5">
                                    <button
                                        (click)="saveItem(val)"
                                        class="mr-1"
                                        ni-button
                                        size="small"
                                        [view]="'accent'"
                                    >
                                        Save
                                    </button>
                                    <button
                                        (click)="
                                            toogleEditModel(
                                                val,
                                                service.servicesAndFees
                                            )
                                        "
                                        class="mr-1"
                                        ni-button
                                        size="small"
                                        [view]="'error'"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!val.isEditMode">
                                <div class="text">
                                    <mat-checkbox
                                        (click)="updateActiveFlag(val)"
                                        [(ngModel)]="val.isActive"
                                        [disabled]="val.isSaving"
                                    >
                                    </mat-checkbox>
                                    <span class="pl-3">{{ val.service }}</span>
                                </div>
                                <div class="actions-buttons">
                                    <span
                                        (click)="toogleEditModel(val)"
                                        *ngIf="val.isCustom"
                                        ><mat-icon
                                            class="material-icons-outlined"
                                            >edit</mat-icon
                                        ></span
                                    >
                                    <span
                                        (click)="
                                            deleteItem(
                                                val,
                                                service.serviceType.name
                                            )
                                        "
                                        *ngIf="val.isCustom"
                                        ><mat-icon
                                            class="material-icons-outlined"
                                            >delete</mat-icon
                                        ></span
                                    >
                                    <span [matTooltip]="getInfoTooltipText(val)"
                                        ><mat-icon
                                            class="material-icons-outlined"
                                            >info</mat-icon
                                        ></span
                                    >
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="add-button-row">
                        <button
                            ni-button
                            class="col-md-7 col-sm-12"
                            [view]="'success'"
                            size="large"
                            (click)="createNewItem(service.serviceType)"
                            *ngIf="isAvailableForCurrentUser()"
                        >
                            Create New Item
                        </button>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>

        <div class="add-button-row">
            <button
                ni-button
                class="col-md-7 col-sm-12"
                [view]="'accent'"
                size="large"
                (click)="openCategoryDialog()"
                *ngIf="isAvailableForCurrentUser()"
            >
                Create New Category
            </button>
        </div>
    </div>
</section>
