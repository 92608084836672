<div class="col-12">
    <ngx-ui-loader [loaderId]="missedOrdersLoader"
                   bgsColor="rgb(100, 181, 246)"
                   bgsPosition="center-center"
                   bgsType="ball-spin"
                   overlayColor="rgba(0, 0, 0, 0.1)"></ngx-ui-loader>
</div>

<div class="d-none d-md-block" style="position: absolute; right: 10px; top: -30px" *ngIf="!isCsr">
    <i class="fa fa-external-link mr-1 text-white cursor-pointer"
       (click)="goToAnalyticsReports()">
    </i>
</div>

<!--<div class="price-checker-button">
    <button (click)="openPriceChecker()" view="'default'" size="small" ni-button>
        Price Checker
    </button>
</div>-->

<div>
    <div class="subtitle">FuelerLinx customers that have dispatched fuel at another FBO at your airport.</div>
    <div *ngIf="noMissedOrders">No Missed Orders</div>
    <div *ngIf="!noMissedOrders">
        <div>
            <div class="row border-bottom pb-2 mb-1 font-weight-bold">
                <div class="col">Company</div>
                <div class="col">Aircraft</div>
                <div class="col">Quantity</div>
                <div class="col">Time of Dispatch</div>
                <div class="col" *ngIf="!isCsr"></div>
                <div class="col"></div>
            </div>
        </div>
        <div *ngFor="let missedOrder of fbosMissedOrdersDataSource;">
            <div class="row pt-1">
                <div class="col">{{ missedOrder.customerName }}</div>
                <div class="col"> {{missedOrder.tailNumber}}</div>
                <div class="col"> {{ missedOrder.missedQuotesCount }} Missed order{{missedOrder.missedQuotesCount < 2 ? "" : "s" }}</div>
                <div class="col"> {{ missedOrder.createdDate }}</div>
                <div class="col" *ngIf="!isCsr">
                    <button (click)="goToAnalyticsReports()" [view]="'accent'" size="small" ni-button>
                        Missed Orders
                    </button>
                </div>
                <div class="col">
                    <button *ngIf="!isCsr" (click)="goToCustomerDetails(missedOrder)" [view]="'accent'" size="small" ni-button>
                        Adjust ITP Margin
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
