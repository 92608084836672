<ni-card [align]="'center'" [title]="'Reset Password'">
    <mat-spinner *ngIf="!validated"></mat-spinner>
    <form
        (ngSubmit)="onSubmit()"
        *ngIf="validated && !validationError && !reset"
        [formGroup]="form"
    >
        <mat-form-field>
            <input
                formControlName="email"
                matInput
                placeholder="Email"
                readonly
                required
            />
        </mat-form-field>

        <mat-form-field>
            <input
                formControlName="newPassword"
                matInput
                placeholder="New Password"
                required
                type="password"
            />
        </mat-form-field>

        <mat-form-field>
            <input
                formControlName="confirmPassword"
                matInput
                placeholder="Confirm Password"
                required
                type="password"
            />
        </mat-form-field>

        <mat-hint *ngIf="error" class="text-danger">{{ error }}</mat-hint>
        <mat-hint
            *ngIf="
                form.get('newPassword').value !=
                    form.get('confirmPassword').value &&
                form.get('confirmPassword').value
            "
            class="text-danger"
            >Password does not match
        </mat-hint>

        <button class="mt-3" color="accent" mat-flat-button type="submit">
            Reset Password
        </button>
    </form>
    <div class="text-center">
        <mat-hint *ngIf="validationError" class="text-danger"
            >The reset password link is expired!</mat-hint
        >
        <mat-hint *ngIf="reset"
            >Password Reset Succeed! Redirecting...</mat-hint
        >
    </div>
</ni-card>
