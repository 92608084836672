import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aircraft-legend',
  templateUrl: './aircraft-legend.component.html',
  styleUrls: ['./aircraft-legend.component.scss']
})
export class AircraftLegendComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
