<div style="width: 100%;">
    <!--START DISPLAY MODES 0 and 1 - Standard calculations with name on left and cost on right-->
    <div *ngIf="displayMode == 0 || displayMode == 1" class="container-fluid">
        <div class="row font-weight-bold mb-3 pb-1">
            <div class="col">
                <div *ngIf="displayMode == 1" class="d-inline-block">
                    <div class="ml-n2">Omit</div>
                </div>
                <div *ngIf="displayMode == 1" class="d-inline-block ml-4 pl-1">
                    Name
                </div>
                <div *ngIf="displayMode == 0" class="d-inline-block">Name</div>
            </div>
            <div class="col text-right">Amount</div>
        </div>

        <!--Top row for Cost/Retail Price - Display mode 0 and 1-->
        <div class="row mb-3">
            <div *ngIf="displayMode == 1" class="col-1"></div>
            <div class="col ml-4">
                {{ marginType == 0 ? "Cost" : "Retail" }}
            </div>
            <div class="col"></div>
            <div class="col text-right">${{ fboPrice | decimalPrecision }}</div>
        </div>

        <!--Above the line taxes - Display mode 0 and 1-->
        <div *ngFor="let aboveLineTax of aboveTheLineTaxes"
             class="row font-italic"
             [ngClass]="{'faded-text': aboveLineTax.omittedFor && aboveLineTax.omittedFor.length > 0}">
            <div *ngIf="displayMode == 1" class="col-1">
                <mat-checkbox (change)="omitChanged(aboveLineTax)"
                              [(ngModel)]="aboveLineTax.isOmitted"
                              type="checkbox"
                              *ngIf="marginType != 1" [disabled]="isMember"></mat-checkbox>
            </div>
            <div class="col ml-4">
                <span [ngClass]="{
                    'strike-through':
                        aboveLineTax.isOmitted ||
                        validDepartureTypes.indexOf(
                            aboveLineTax.departureType
                        ) == -1 ||
                        validFlightTypes.indexOf(
                            aboveLineTax.flightTypeClassification
                        ) == -1
                }">{{ aboveLineTax.name }}</span> <sup class="pl-1" *ngIf="aboveLineTax.omittedFor && aboveLineTax.omittedFor.length > 0">{{aboveLineTax.omittedFor}}</sup>
            </div>
            <div [ngClass]="{
                    'strike-through':
                        aboveLineTax.isOmitted ||
                        validDepartureTypes.indexOf(
                            aboveLineTax.departureType
                        ) == -1 ||
                        validFlightTypes.indexOf(
                            aboveLineTax.flightTypeClassification
                        ) == -1
                }"
                 class="col text-right">
                <span *ngIf="aboveLineTax.calculationType == 1">{{ aboveLineTax.value | decimalPrecision }}%</span>
            </div>
            <div [ngClass]="{
                    'strike-through':
                        aboveLineTax.isOmitted ||
                        validDepartureTypes.indexOf(
                            aboveLineTax.departureType
                        ) == -1 ||
                        validFlightTypes.indexOf(
                            aboveLineTax.flightTypeClassification
                        ) == -1
                }"
                 class="col text-right">
                <span *ngIf="marginType == 0">{{ aboveLineTax.amount | currencyPresicion }}</span>
                <span *ngIf="marginType == 1">Applied</span>
            </div>
        </div>

        <!--Sub-total-->
        <div *ngIf="customerMargin != null" class="row mt-3 mb-3">
            <div *ngIf="displayMode == 1" class="col-1"></div>
            <div class="col font-weight-bold ml-4">Sub-total</div>
            <div class="col"></div>
            <div class="col text-right font-weight-bold">
                {{ preMarginSubTotal | currencyPresicion }}
            </div>
        </div>

        <!--ITP Margin-->
        <div *ngIf="customerMargin != null" class="row">
            <div *ngIf="displayMode == 1" class="col-1"></div>
            <div class="col ml-4" *ngIf="marginType == 0">ITP-Margin</div>
            <div class="col ml-4" *ngIf="marginType == 1">Discount</div>
            <div class="col"></div>
            <div class="col text-right">
                <span *ngIf="marginType == 1">-</span>${{ marginITP | decimalPrecision }}
            </div>
        </div>

        <!--Sub-total with Margin-->
        <div *ngIf="customerMargin != null" class="row mb-3 mt-3">
            <div *ngIf="displayMode == 1" class="col-1"></div>
            <div class="col font-weight-bold ml-4">Sub-total w/ Margin</div>
            <div class="col"></div>
            <div class="col text-right font-weight-bold">
                {{ subTotalWithMargin | currencyPresicion }}
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr *ngIf="showLineSeparator"
                    style="margin-bottom: 10px; margin-top: 10px" />
            </div>
        </div>

        <!--Below the line taxes - Display mode 0 and 1-->
        <div *ngFor="let belowLineTax of belowTheLineTaxes"
             class="row font-italic"
             [ngClass]="{'faded-text': belowLineTax.omittedFor && belowLineTax.omittedFor.length > 0}">
            <div *ngIf="displayMode == 1" class="col-1">
                <mat-checkbox (change)="omitChanged(belowLineTax)"
                              [(ngModel)]="belowLineTax.isOmitted"
                              type="checkbox" [disabled]="isMember"></mat-checkbox>
            </div>
            <div class="col ml-4">
                <span [ngClass]="{
                    'strike-through':
                        belowLineTax.isOmitted ||
                        validDepartureTypes.indexOf(
                            belowLineTax.departureType
                        ) == -1 ||
                        validFlightTypes.indexOf(
                            belowLineTax.flightTypeClassification
                        ) == -1
                }">{{ belowLineTax.name }}</span> <sup class="pl-1" *ngIf="belowLineTax.omittedFor && belowLineTax.omittedFor.length > 0">{{belowLineTax.omittedFor}}</sup>
            </div>
            <div [ngClass]="{
                    'strike-through':
                        belowLineTax.isOmitted ||
                        validDepartureTypes.indexOf(
                            belowLineTax.departureType
                        ) == -1 ||
                        validFlightTypes.indexOf(
                            belowLineTax.flightTypeClassification
                        ) == -1
                }"
                 class="col text-right">
                <span *ngIf="belowLineTax.calculationType == 1">{{ belowLineTax.value | decimalPrecision }}%</span>
            </div>
            <div [ngClass]="{
                    'strike-through':
                        belowLineTax.isOmitted ||
                        validDepartureTypes.indexOf(
                            belowLineTax.departureType
                        ) == -1 ||
                        validFlightTypes.indexOf(
                            belowLineTax.flightTypeClassification
                        ) == -1
                }"
                 class="col text-right">
                <span *ngIf="belowLineTax.amount">{{ belowLineTax.amount | currencyPresicion }}</span>
            </div>
        </div>

        <!--Total-->
        <div *ngIf="customerMargin != null" class="row font-weight-bold mb-3">
            <div *ngIf="displayMode == 1" class="col-1"></div>
            <div class="col ml-4">Total</div>
            <div class="col"></div>
            <div class="col text-right">{{ total | currencyPresicion }}</div>
        </div>
    </div>

    <!--START DISPLAY MODE 2 - No margin shown and reverse horizontal order-->
    <div *ngIf="displayMode == 2" class="container-fluid" style="width: 100%">
        <!--Top row for Cost/Retail Price - Display mode 0 and-->
        <div *ngIf="displayMode == 2" class="row font-weight-bold">
            <div class="col-6 label-tax-fees">
                <div class="">
                    ${{ fboPrice | decimalPrecision }}
                </div>
            </div>
            <div class="col-6 label-tax-fees">
                <div class="no-padding-label">
                    {{ marginType == 0 ? "Cost" : "Posted Retail" }}
                </div>
            </div>
        </div>

        <!--Above the line taxes - Display mode 2-->
        <div *ngFor="let aboveLineTax of aboveTheLineTaxes" class="row">
            <div class="col-6">
                <div [ngClass]="{
                        'strike-through':
                            aboveLineTax.isOmitted ||
                            validDepartureTypes.indexOf(
                                aboveLineTax.departureType
                            ) == -1 ||
                            validFlightTypes.indexOf(
                                aboveLineTax.flightTypeClassification
                            ) == -1
                    }"                     >
                    <div *ngIf="marginType == 1"
                         class="font-italic">
                        Applied
                    </div>
                    <div *ngIf="marginType == 0">
                        <div *ngIf="aboveLineTax.calculationType == 1">
                            {{ aboveLineTax.value | decimalPrecision }}%
                        </div>
                        <div *ngIf="
                                aboveLineTax.calculationType == 0 &&
                                aboveLineTax.amount
                            ">
                            {{ aboveLineTax.amount | currencyPresicion }}
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-6 label-tax-fees">
                <div [ngClass]="{
                        'strike-through':
                            aboveLineTax.isOmitted ||
                            validDepartureTypes.indexOf(
                                aboveLineTax.departureType
                            ) == -1 ||
                            validFlightTypes.indexOf(
                                aboveLineTax.flightTypeClassification
                            ) == -1
                    }"
                      >
                    {{ aboveLineTax.name }}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr *ngIf="showLineSeparator"
                    style="margin-bottom: 10px; margin-top: 10px" />
            </div>
        </div>

        <!--Below the line taxes - Display mode 2-->
        <div *ngFor="let belowLineTax of belowTheLineTaxes" class="row">
            <div class="col-6 label-tax-fees" style="padding-right: 0px">
                <div [ngClass]="{
                        'strike-through':
                            belowLineTax.isOmitted ||
                            validDepartureTypes.indexOf(
                                belowLineTax.departureType
                            ) == -1 ||
                            validFlightTypes.indexOf(
                                belowLineTax.flightTypeClassification
                            ) == -1
                    }"
                     class="">
                    <div *ngIf="belowLineTax.calculationType == 1">
                        {{ belowLineTax.value | decimalPrecision }}%
                    </div>
                    <div *ngIf="
                            belowLineTax.calculationType == 0 &&
                            belowLineTax.amount
                        ">
                        {{ belowLineTax.amount | decimalPrecision }}
                    </div>
                </div>
            </div>
            <div class="col-6 label-tax-fees" style="padding-right: 0px">
                <div [ngClass]="{
                        'strike-through':
                            belowLineTax.isOmitted ||
                            validDepartureTypes.indexOf(
                                belowLineTax.departureType
                            ) == -1 ||
                            validFlightTypes.indexOf(
                                belowLineTax.flightTypeClassification
                            ) == -1
                    }"
                     class="no-padding-label">
                    {{ belowLineTax.name }}
                </div>
            </div>
          </div>
        </div>
    <div *ngIf="displayMode == 0 || displayMode == 1" class="container-fluid">
        <div class="row">
            <div class="col">
                <sub>C - Omitted for customer</sub>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <sub>P - Omitted for template</sub>
            </div>
        </div>
    </div>
</div>
