<div>
    <h1 mat-dialog-title class="text-center">Pricing Expired</h1>
    <div class="mt-1 mb-2" mat-dialog-content>
        <div class="text-center">
            Your fuel pricing has expired. Please update your cost/retail values.
        </div>
    </div>
    <div mat-dialog-actions class="row">
        <div class="col-12 text-center text-md-right">
            <div class="d-inline-block">
                <button (click)="onRemindMeLaterClick()"
                        *ngIf="!hideRemindMeButton"
                        class="mr-1"
                        color="orange"
                        mat-flat-button>
                    Remind Me Later
                </button>
            </div>
            <div class="d-inline-block">
                <button (click)="onCancelClick()" mat-stroked-button>Dismiss</button>
            </div>
        </div>
    </div>
</div>
