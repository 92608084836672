<div class="container-custom">
    <div class="row">
      <div class="col-8">
        <h4>
            About FBOLinx
        </h4>
        <div class="about-text">
            <p >
                FBOLinx is a direct pricing solution designed to integrate into FuelerLinx: the world's largest business aviation fuel marketplace and tankering software system. FBOs can automatically push direct quotes, receive fuel orders, and accept payments from one intuitive interface.
            </p>

            <p>
                FBOLinx includes integrated CRM functionality to help you manage leads, contacts and confidential pricing. The essential platform for FBOs that want to understand their customers, grow relationships, and increase efficiency.
            </p>
        </div>
        <h4>
            Features
        </h4>
        <p>
            Direct and Confidential Pricing to over 3,000 aircraft FBOLinx instantly provides a direct pipeline from your FBO to flight departments; ensuring accurate, confidential pricing tailored to your customer. A simple interface ensures your FBO maximizes exposure through the FuelerLinx marketplace.
        </p>

        <p>
            Build Customer Relationships FBOLinx gives you the ability to reach more flight departments within a vast and transient market. Nurture and grow your customer base with tailored pricing and instant access.
        </p>

        <p>
            Your Direct Pricing in the FuelerLinx Marketplace FuelerLinx software is used by over 800 flight departments to make more informed fuel purchasing decisions. It’s secure, confidential and effective. FBOLinx users can push tailored, confidential pricing to each flight department in the FuelerLinx network in seconds.
        </p>

      </div>
      <div class="col-4 aircraft-image">
        <h4>
            &nbsp;
        </h4>
        <p>
            <img
                src="/../../../assets/img/landing-page/DSC03168.jpg"
                height="70%"
                width="70%"
            />
        </p>
      </div>
    </div>
    <div class="row demo-button">
        <button
        (click)="openRequestDemo()"
            [view]="'accent'"
            ni-button
        >
        Request Demo
        </button>
    </div>
  </div>
