<div>
    <div *ngIf="usersData || currentUser">
        <div *ngIf="currentUser">
            <app-users-edit
                (cancelClicked)="cancelUserEditClicked()"
                (saveClicked)="saveUserEditClicked()"
                [fboInfo]="fboInfo"
                [groupInfo]="groupInfo"
                [userInfo]="currentUser"
            ></app-users-edit>
        </div>
        <div *ngIf="!currentUser && usersData">
            <app-users-grid
                (editUserClicked)="editUserClicked($event)"
                [fboInfo]="fboInfo"
                [groupInfo]="groupInfo"
                [usersData]="usersData"
            ></app-users-grid>
        </div>
    </div>
    <div *ngIf="!(usersData || currentUser)">
        <mat-spinner></mat-spinner>
    </div>
</div>
