<div><h1 mat-dialog-title>{{ title }}</h1></div>
<div class="mt-1 mb-2" mat-dialog-content style="max-width: 600px;">
    <span>
        {{ data.description }}
    </span>
    <table class="mat-table mt-2" style="min-width: 65%; max-width: 75%" *ngIf="data.tableItemsList">
        <tbody>
            <tr *ngFor="let cell of data.tableItemsList"
                class="mat-row mat-row-not-hover text-left">
                <td class="mat-cell">
                    {{ cell }}
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="data.listItemsList">
        <ul>
            <li *ngFor="let cell of data.listItemsList">
                <div [innerHTML]="cell"></div>
            </li>
        </ul>
    </div>
    <div *ngIf="data.additionalInfo" class="mt-2">
        <span>
            {{ data.additionalInfo }}
        </span>
    </div>
</div>
<div mat-dialog-actions>
    <div class="text-right col-12">
        <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
        <button [mat-dialog-close]="data"
                class="mr-1"
                color="green"
                mat-flat-button>
            {{ buttonText }}
        </button>
    </div>
</div>
