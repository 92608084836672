<section>
    <div *ngIf="emailTemplates">
        <app-email-templates-grid
            (deleteEmailTemplateClicked)="deleteEmailTemplateClicked($event)"
            (editEmailTemplateClicked)="editEmailTemplateClicked($event)"
            (copyEmailTemplateClicked)="copyEmailTemplateClicked($event)"
            (newEmailTemplateAdded)="newEmailTemplateAdded($event)"
            [emailTemplatesData]="emailTemplates"
        ></app-email-templates-grid>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
</section>
