<section>
    <div class="table-container">
        <div *ngIf="customersData">
            <app-group-customers-grid
                [customersData]="customersData"
            ></app-group-customers-grid>
        </div>
        <ngx-ui-loader
            [loaderId]="chartName"
            bgsColor="rgb(100, 181, 246)"
            bgsPosition="center-center"
            bgsType="ball-spin"
            overlayColor="rgba(0, 0, 0, 0.1)"
        ></ngx-ui-loader>
    </div>
</section>
