<h1 mat-dialog-title>Merge Groups and FBOs</h1>
<div class="group-merge-dialog">
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>Base Group</mat-label>
            <mat-select [(ngModel)]="baseGroup" [disabled]="loading">
                <mat-option *ngFor="let group of data.groups"
                            [value]="group.oid">
                    {{ group.groupName }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-hint *ngIf="failed" class="text-danger">Failed to merge groups. Contact the support team!</mat-hint>
    </div>

    <div *ngIf="loading">* May take a few minutes</div>

    <div mat-dialog-actions>
        <button (click)="onSaveClick()"
                [disabled]="loading"
                class="mr-1"
                color="accent"
                mat-flat-button>
            {{ loading ? "Merging &nbsp;" : "Merge Groups" }}
            <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        </button>
        <button (click)="onCancelClick()"
                [disabled]="loading"
                mat-stroked-button>
            Cancel
        </button>
    </div>
</div>
