// todo: create a object constant and change every tragget to constants
export const locationChangedEvent = 'location changed';
export const icaoChangedEvent = 'icao changed';
export const fboPricesUpdatedEvent = 'fbo prices updated';
export const fboChangedEvent = 'fbo changed';
export const menuTooltipShowedEvent = 'menu tooltips showed';
export const customerUpdatedEvent = 'customer updated';
export const fboProductPreferenceChangeEvent = 'fbo product preference change'
export const fboPricesClearedEvent = 'fbo prices cleared';
export const deleteGroupEvent = 'group deleted';
export const resetMissedOrders = 'reset missed orders';
export const fboPricesLoadedEvent = 'fbo-prices-loaded';
export const serviceOrdersChangedEvent = 'service-orders-changed';
export const flightWatchDataEvent = 'flightWatchDataEvent';
export const flyToOnMapEvent = 'flyToOnMap';
export const accountTypeChangedEvent = 'account-type-changed';
export const fetchFlighWatchDataEvent = 'fetch-flighWatch-data';
