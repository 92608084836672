<div class="landing-page-container">
    <div
        [ngClass]="{ sticky: isSticky }"
        class="theme-header-fixed"
        id="landing-menu-header"
    >
        <div *ngIf="!isSticky" class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-md-10">
                    <div class="header-top-bar">
                        <div class="pull-right">
                            <a
                                (click)="onLogin()"
                                class="signin-btn"
                                routerLink="."
                                >Sign In</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="zpcontainer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4">
                        <a class="ml-n4">
                            <!--<img src="/../../../assets/img/landing-page/FBOlinx-Logo-v2.2-DARK-BACKGROUND.png" style="height:62px;width:207px;">-->
                            <img
                                src="/../../../assets/img/landing-page/fbolinx_dark.png"
                                style="height: 62px; width: 207px"
                            />
                        </a>
                    </div>
                    <div class="col-8">
                        <nav class="navbar navbar-expand-lg">
                            <div
                                class="collapse navbar-collapse"
                                id="navbarNav"
                            >
                                <ul class="navbar-nav">
                                    <li class="nav-item active">
                                        <a
                                            class="nav-link"
                                            onclick="document.getElementById('home-content').scrollIntoView({ behavior: 'smooth', block: 'center' });"
                                            routerLink="."
                                            >Home
                                            <span class="sr-only"
                                                >(current)</span
                                            ></a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            onclick="document.getElementById('about-content').scrollIntoView({ behavior: 'smooth', block: 'center' });"
                                            routerLink="."
                                            >About</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            onclick="document.getElementById('marketplace-content').scrollIntoView({ behavior: 'smooth', block: 'center' });"
                                            routerLink="."
                                            >Marketplace</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            onclick="document.getElementById('integrations-content').scrollIntoView({ behavior: 'smooth', block: 'center' });"
                                            routerLink="."
                                            >Integrations</a
                                        >
                                    </li>
                                    <li class="nav-item">
                                        <a
                                            class="nav-link"
                                            onclick="document.getElementById('contact-us-content').scrollIntoView({ behavior: 'smooth', block: 'center' });"
                                            routerLink="."
                                            >Contact</a
                                        >
                                    </li>
                                    <li *ngIf="isSticky" class="nav-item">
                                        <a
                                            (click)="onLogin()"
                                            class="nav-link"
                                            routerLink="."
                                            >Sign In</a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div
            class="img-fluid citation-background"
            style="
                background-image: url('../../../assets/img/landing-page/Citation-FRONT-lower-opacity_v2.png');
            "
        >
            <div class="home-content vh-100 zpcontainer" id="home-content">
                <div class="container-fluid zpelement">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="xl-title" style="color: white">
                                Precision Marketing
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="xl-title" style="color: #369eea">
                                Platform for FBOs
                            </div>
                        </div>
                        <div class="zpelement mb-2 col-lg-8">
                            <p style="color: white">
                                A direct pricing solution designed to integrate
                                into FuelerLinx: the world's largest business
                                aviation fuel marketplace and tankering software
                                system. FBOs can automatically push direct
                                quotes, receive fuel orders, and accept payments
                                from one intuitive interface.
                            </p>
                        </div>
                        <div class="zpelement mb-2 col-lg-8">
                            <p style="color: white">
                                FBOLinx includes integrated CRM functionality to
                                help you manage leads, contacts and confidential
                                pricing. The essential platform for FBOs that
                                want to understand their customers, grow
                                relationships, and increase efficiency.
                            </p>
                        </div>
                        <div class="zpelement col-lg-8">
                            <button
                                (click)="openRequestDemo()"
                                class="request-demo-white-border-button"
                                type="button"
                            >
                                Request Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="theme-container"
            id="about-content"
            style="
                background-color: #ecf0f1;
                color: black;
                scroll-margin-top: 100px;
            "
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="title-container zpcontainer">
                        <div class="large-title">
                            Real-Time Fuel Quoting
                            <br />
                            Automated, Customizable, & Secure
                        </div>
                    </div>
                    <div class="zpcontainer">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xs-12 col-lg-6">
                                    <div
                                        class="text-right plane-image zpelement"
                                    >
                                        <img
                                            alt=""
                                            class=""
                                            size="fit"
                                            src="{{ planeFrontImage }}"
                                            style="
                                                width: 100%;
                                                padding: 0px;
                                                margin: 0px;
                                            "
                                            title=""
                                        />
                                    </div>
                                </div>
                                <div
                                    class="col-xs-12 col-lg-6 bulleted-content"
                                >
                                    <div class="zpelement">
                                        <ul>
                                            <li>
                                                <span style="color: inherit">
                                                    <strong
                                                        >Direct and Confidential
                                                        Pricing to over 3,000
                                                        aircraft</strong
                                                    >
                                                    FBOLinx instantly provides a
                                                    direct pipeline from your
                                                    FBO to flight departments;
                                                    ensuring accurate,
                                                    confidential pricing
                                                    tailored to your customer. A
                                                    simple interface ensures
                                                    your FBO maximizes exposure
                                                    through the FuelerLinx
                                                    marketplace.
                                                </span>
                                            </li>
                                            <li>
                                                <span style="color: inherit">
                                                    <strong
                                                        >Build Customer
                                                        Relationships</strong
                                                    >
                                                    FBOLinx gives you the
                                                    ability to reach more flight
                                                    departments within a vast
                                                    and transient market.
                                                    Nurture and grow your
                                                    customer base with tailored
                                                    pricing and instant access.
                                                </span>
                                            </li>
                                            <li>
                                                <span style="color: inherit">
                                                    <strong
                                                        >Your Direct Pricing in
                                                        the FuelerLinx
                                                        Marketplace</strong
                                                    >
                                                    FuelerLinx software is used
                                                    by over 800 flight
                                                    departments to make more
                                                    informed fuel purchasing
                                                    decisions. It’s secure,
                                                    confidential and effective.
                                                    FBOLinx users can push
                                                    tailored, confidential
                                                    pricing to each flight
                                                    department in the FuelerLinx
                                                    network in seconds.
                                                </span>
                                            </li>
                                        </ul>
                                        <div>
                                            <button
                                                (click)="openRequestDemo()"
                                                class="request-demo-button"
                                                type="button"
                                            >
                                                Request Demo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="theme-container"
            id="marketplace-content"
            style="
                background-color: #091a26;
                color: white;
                scroll-margin-top: 80px;
            "
        >
            <div class="zpcontainer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-lg-6">
                            <div class="title-container">
                                <div class="medium-title" data-editor="true">
                                    <span>
                                        Connect directly into the&nbsp;<span
                                            style="
                                                font-family: Arial, sans-serif;
                                            "
                                            ><span style="font-weight: 700"
                                                >Fueler</span
                                            ><span
                                                style="
                                                    font-weight: 700;
                                                    color: rgb(54, 158, 234);
                                                "
                                                ><span
                                                    style="font-style: italic"
                                                    >Linx
                                                </span></span
                                            ></span
                                        ><span>Marketplace</span>
                                    </span>
                                </div>
                                <div class="zpelement">
                                    <p>
                                        <span
                                            >FuelerLinx is an aviation fuel
                                            planning and tankering software
                                            system flight departments use to
                                            compare all fuel price options on a
                                            field, find tech stops, and purchase
                                            fuel.&nbsp;</span
                                        ><br />
                                    </p>

                                    <p>
                                        <span
                                            >Over the past decade, FuelerLinx
                                            has built the premier fuel software
                                            for the business aviation
                                            industry.&nbsp; FuelerLinx spans
                                            nearly all software systems, brings
                                            together critical data, and boasts a
                                            customer base featuring the largest
                                            fuel buyers in the industry.</span
                                        >
                                    </p>

                                    <p>
                                        <span
                                            >Additional features such as
                                            multi-leg tankering calculations,
                                            proximity fuel maps, and fuel
                                            transaction auditing make FuelerLinx
                                            a one-stop shop for anyone who wants
                                            to streamline their fuel purchasing
                                            and maximize operational
                                            efficiency.</span
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-lg-6 screenshot-container">
                            <div class="title-container">
                                <div class="fbolinx-screenshots">
                                    <img
                                        alt=""
                                        class=""
                                        size="fit"
                                        src="/../../../assets/img/landing-page/Web 1920 – 2.png"
                                        style="
                                            width: 100%;
                                            padding: 0px;
                                            margin: 0px;
                                        "
                                        title=""
                                    />
                                </div>
                                <div class="fbolinx-screenshots">
                                    <img
                                        alt=""
                                        class=""
                                        size="fit"
                                        src="/../../../assets/img/landing-page/image.png"
                                        style="
                                            width: 100%;
                                            padding: 0px;
                                            margin: 0px;
                                        "
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="theme-container integrations-container"
            id="integrations-content"
            style="
                background-color: #e4e4e4;
                color: black;
                scroll-margin-top: 90px;
            "
        >
            <div class="zpcontainer">
                <div class="container-fluid">
                    <div class="title-container">
                        <div class="text-center">
                            <div class="medium-title">Integrations</div>
                        </div>
                        <div class="mt-4 small-divider-container">
                            <hr />
                        </div>
                        <div class="mt-4 mb-5 divider-container">
                            <hr />
                        </div>
                    </div>
                    <div class="text-center mb-5">
                        <div class="text-center integration-partners">
                            <mat-button-toggle-group
                                (change)="changeIntegrationPartnerView($event)"
                                aria-label="Integration Partners"
                                name="integration-partners"
                                value="{{ integrationPartnerView }}"
                            >
                                <mat-button-toggle value="0"
                                    >All</mat-button-toggle
                                >
                                <mat-button-toggle value="1"
                                    >Market Intelligence</mat-button-toggle
                                >
                                <mat-button-toggle value="2"
                                    >Point of Sale</mat-button-toggle
                                >
                                <mat-button-toggle value="3"
                                    >FBO Chains</mat-button-toggle
                                >
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <div class="container pt-3">
                        <div
                            *ngFor="let partnerGroup of integrationPartners"
                            class="d-flex justify-content-around mb-4"
                        >
                            <div *ngFor="let partner of partnerGroup">
                                <img
                                    alt="integration-partner"
                                    class="integration-partner-logo"
                                    src="{{ partner }}"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="pb-3 contact-us-container theme-container"
            id="contact-us-content"
            style="background-color: #ecf0f1; color: #04131d"
        >
            <div class="zpcontainer">
                <div class="container-fluid">
                    <div class="title-container">
                        <div class="medium-title mb-5">Contact Us</div>
                    </div>

                    <hr class="mb-5 mt-5" />
                    <div class="row">
                        <div class="col-4">
                            <p>7415 Hayvenhurst Place</p>
                            <p>
                                Van Nuys, CA 91406&nbsp;<br /><span
                                    >Phone:
                                    <span
                                        class="gc-cs-link"
                                        id="gc-number-0"
                                        title="Call with Google Voice"
                                        >(866) 998-5469</span
                                    ></span
                                >
                            </p>
                        </div>

                        <div class="col-4">
                            <p>
                                Phone :
                                <span
                                    class="gc-cs-link"
                                    id="gc-number-0"
                                    title="Call with Google Voice"
                                    >+1 (866) 998-5469</span
                                >
                            </p>
                        </div>
                        <div class="col-4">
                            <p>Email : info@fbolinx.com</p>
                        </div>
                    </div>
                    <hr class="mb-3 mt-5" />
                </div>
            </div>
        </div>
        <div
            class="theme-container"
            style="background-color: #091a26; color: white"
        >
            <div class="zpcontainer">
                <div class="container-fluid">
                    <div class="title-container">
                        <div class="text-center medium-title">
                            FBOLinx: Precision Marketing Platform for FBOs
                        </div>
                    </div>

                    <div class="mt-5 mb-4 text-center">
                        <button
                            (click)="openRequestDemo()"
                            class="request-demo-button"
                            type="button"
                        >
                            Request Demo
                        </button>
                    </div>

                    <div class="page-footer text-center">
                        FBOLinx is the most efficient way to stream direct
                        pricing to the top business aircraft operating in
                        today's marketplace!
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer [isLandingPage]="true"></app-footer>
