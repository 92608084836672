<h1 mat-dialog-title>FBO Group Customer Pricing Report</h1>
<div mat-dialog-content>
    <p>Select the customer(s) you'd like to generate a price sheet for.</p>
  
    <ejs-grid
      #grid
      (rowDeselected)="rowDeselected()"
      (rowSelected)="rowSelected()"
      [dataSource]="dataSources"
      [enableHover]="false"
      [allowSelection]="true"
      [selectionSettings]="selectOptions"
      [editSettings]="editSettings"
      allowFiltering="true"
      [filterSettings]="filterOptions"
      [allowSorting]="true"
      height="350"
    >
      <e-columns>
        <e-column type="checkbox" width="50"></e-column>
        <e-column
          field="customerId"
          headerText="Customer ID"
          isPrimaryKey="true"
          [visible]="false"
        ></e-column>
        <e-column field="company" headerText="Customer" [allowSorting]="true">
          <ng-template #filterTemplate let-data>
            <input
                (input)="onFilterInput($event, 'company', 'contains')"
                [value]="data.filterValue || ''"
                placeholder="Filter"
                class="e-input"
                id="searchBox"
            />
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
</div>
<div class="" mat-dialog-actions>
    <button
        (click)="onGenerate()"
        [disabled]="
            !selectedCustomers ||
            !selectedCustomers.length ||
            downloading ||
            emailing
        "
        color="accent"
        mat-raised-button
    >
        {{ !downloading ? "Generate and Download" : "" }}
        <mat-spinner *ngIf="downloading" diameter="20"></mat-spinner>
    </button>
    <button
        (click)="onEmail()"
        [disabled]="
            !selectedCustomers ||
            !selectedCustomers.length ||
            emailing ||
            emailing
        "
        color="green"
        mat-raised-button
    >
        {{ !emailing ? "Email Pricing" : "" }}
        <mat-spinner *ngIf="emailing" diameter="20"></mat-spinner>
    </button>
</div>
