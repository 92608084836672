<div class="">
    <table
        [dataSource]="contactsDataSource"
        class="mat-elevation-z8 col-md-12"
        mat-table
        matSort
        style="box-shadow: none !important"
    >
        <ng-container matColumnDef="firstName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
                First Name
            </th>
            <td
                (click)="editContactPopup(contact)"
                *matCellDef="let contact"
                mat-cell
            >
                {{ contact.firstName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Last Name</th>
            <td
                (click)="editContactPopup(contact)"
                *matCellDef="let contact"
                mat-cell
            >
                {{ contact.lastName }}
            </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Title</th>
            <td
                (click)="editContactPopup(contact)"
                *matCellDef="let contact"
                mat-cell
            >
                {{ contact.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
            <td
                (click)="editContactPopup(contact)"
                *matCellDef="let contact"
                mat-cell
            >
                {{ contact.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Phone</th>
            <td
                (click)="editContactPopup(contact)"
                *matCellDef="let contact"
                mat-cell
            >
                {{ contact.phone }}
            </td>
        </ng-container>

        <ng-container matColumnDef="copyAlerts">
            <th style="min-width: 185px; max-width: 185px; width: 185px;" *matHeaderCellDef mat-header-cell mat-sort-header class="text-right">
                <div style="margin-right: 15px">
                    <div style="width: 112px;">Pricing Distribution</div>
                </div>
                <div>
                    <mat-slide-toggle (change)="UpdateAllCopyAlertsValues()"
                                      [checked]="copyAll"
                                      color="accent"></mat-slide-toggle>
                </div>
            </th>
            <td style="min-width: 185px; max-width: 185px;width: 185px;" *matCellDef="let contact" mat-cell class="text-right">

                        <div>
                            <mat-slide-toggle (click)="UpdateCopyAlertsValue(contact)"
                                              [(ngModel)]="contact.copyAlerts"
                                              [checked]="contact.copyAlerts"
                                              color="accent"></mat-slide-toggle>
                        </div>

            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th *matHeaderCellDef mat-header-cell>
                <!--<div class="actions-menu pull-right">-->
                <div class="pull-right">
                    <button
                        (click)="newRecord()"
                        [view]="'accent'"
                        beforeIcon="fa fa-plus"
                        class="mr-3"
                        ni-button
                    >
                        Add New
                    </button>
                </div>
            </th>
            <td *matCellDef="let contact" mat-cell>&nbsp;</td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
            (click)="editRecord(row, $event)"
            *matRowDef="let row; columns: displayedColumns"
            mat-row
        ></tr>
    </table>
</div>
