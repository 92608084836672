<h1 mat-dialog-title>Warning Message</h1>
<div class="mt-1 mb-2" mat-dialog-content>
    <div class="text-center">
        You are {{data.action}} your current default margin template. Please select
        another margin template below. Your default template will be used for
        newly added customers or if you delete a margin template with customers
        assigned to it.
    </div>
    <div class="text-center">
        <mat-form-field>
            <mat-select
                [(ngModel)]="selectedTemplate"
                placeholder="Margin Template"
                required
            >
                <mat-option
                    *ngFor="let pricingTemplate of data.data"
                    [value]="pricingTemplate.oid"
                >
                    {{ pricingTemplate.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <div class="text-center col-12">
        <button (click)="onSelect()" color="green" mat-stroked-button>
            Change Default Template
        </button>
        <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
    </div>
</div>
