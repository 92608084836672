<h1 mat-dialog-title>{{ title }}</h1>

<div [formGroup]="form" mat-dialog-content>
    <div *ngIf="isSaving" class="container-fluid">
        <div class="row">
            <div class="col">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    <mat-horizontal-stepper #stepper *ngIf="!isSaving" [linear]="true">
        <mat-step formGroupName="firstStep">
            <ng-template matStepLabel>Margin Name</ng-template>
            <div class="container-fluid inner-content">
                <div class="row mt-2 mb-2">
                    <div class="col-8">
                        <mat-form-field>
                            <input
                                aria-label="Margin Name"
                                autocomplete="off"
                                formControlName="templateName"
                                matInput
                                placeholder="Margin Name"
                            />
                        </mat-form-field>
                    </div>
                    <div class="col-4 mt-3">
                        <mat-slide-toggle
                            color="accent"
                            formControlName="templateDefault"
                            >Default
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="text-right mt-2">
                <button [mat-dialog-close]="" class="mr-1" mat-stroked-button>
                    Cancel
                </button>
                <button color="green" mat-stroked-button matStepperNext>
                    Next
                </button>
            </div>
        </mat-step>
        <mat-step formGroupName="secondStep">
            <ng-template matStepLabel> Edit Tiers</ng-template>
            <div class="container-fluid inner-content">
                <div class="row mb-4 mt-2">
                    <div class="col-3">
                        <mat-form-field class="col-8">
                            <mat-select
                                formControlName="marginType"
                                placeholder="Type"
                            >
                                <mat-option
                                    *ngFor="
                                        let marginType of marginTypeDataSource
                                    "
                                    [value]="marginType.value"
                                >
                                    {{ marginType.text }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-5">
                        <mat-form-field class="col-8">
                            <mat-select

                                formControlName="discountType"
                                placeholder="Discount Type"
                            >
                                <mat-option
                                    *ngFor="
                                        let discountType of discountTypeDataSource
                                    "
                                    [value]="discountType.value"
                                >
                                    {{ discountType.text }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div
                    class="row mt-2"
                    style="border-top: 1px solid; border-top-style: outset"
                >
                    <div class="col">
                        <h5>Volume Tiers</h5>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <table class="mat-table" style="max-width: 75%">
                            <thead>
                                <tr class="mat-header-row">
                                    <th class="
                                            text-center
                                            mat-header-cell
                                            text-lg-center
                                        ">
                                        Min. (gal)
                                    </th>
                                    <th class="
                                            text-center
                                            mat-header-cell
                                            text-lg-center
                                        ">
                                        Max. (gal)
                                    </th>
                                    <th *ngIf="form.value.secondStep.marginType === 1"
                                        class="
                                            text-center
                                            mat-header-cell
                                            text-lg-center
                                        ">
                                        <span>Discount</span>
                                    </th>
                                    <th *ngIf="form.value.secondStep.marginType === 0" class="
                                            text-center
                                            mat-header-cell
                                            text-lg-center
                                        ">
                                        <span>Total</span>
                                    </th>
                                    <th class="text-center mat-header-cell">
                                        <button (click)="addCustomerMargin()"
                                                [view]="'accent'"
                                                beforeIcon="fa fa-plus"
                                                ni-button
                                                size="small">
                                            Add New
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody formArrayName="customerMargins">
                                <tr *ngFor="
                                        let customerMargin of customerMarginsFormArray.controls;
                                        let i = index
                                    "
                                    class="mat-row mat-row-not-hover">
                                    <td [formGroupName]="i"
                                        class="mat-cell text-center">
                                        <mat-form-field class="col-6">
                                            <input class="text-lg-right"
                                                   formControlName="min"
                                                   (blur)="updateCustomerMarginVolumeValues(i)"
                                                   matInput />
                                        </mat-form-field>
                                    </td>
                                    <td [formGroupName]="i"
                                        class="mat-cell text-center">
                                        <mat-form-field class="col-6">
                                            <input class="text-lg-right"
                                                   formControlName="max"
                                                   matInput />
                                        </mat-form-field>
                                    </td>
                                    <td [formGroupName]="i"
                                        class="mat-cell text-center">
                                        <div>
                                            <mat-form-field class="col-6">
                                                <input (click)="
                                                        $event.target.select()
                                                    "
                                                       formControlName="amount"
                                                       matInput
                                                       [step]="inputStepDefaultValue"
                                                       type="number" />
                                                <span *ngIf="form.value.secondStep.discountType == 0"
                                                      class="prefixDollar"
                                                      matPrefix>$</span>

                                                <span *ngIf="form.value.secondStep.discountType == 1"
                                                      class="prefixPercentage"
                                                      matSuffix>%</span>
                                            </mat-form-field>
                                        </div>
                                    </td>
                                    <td [formGroupName]="i"
                                        class="mat-cell text-center">
                                        <div>
                                            <div *ngIf="form.value.secondStep.marginType == 0 &&
                                                form.value.secondStep.discountType == 0">
                                                {{
                                                        jetACost + customerMargin.value.amount / 1
                                                            | currencyPresicion
                                                }}
                                            </div>
                                            <div *ngIf="form.value.secondStep.marginType == 0 &&
                                                form.value.secondStep.discountType == 1">
                                                {{
                                                        jetACost + (jetACost * customerMargin.value.amount / 100)
                                                            | currencyPresicion
                                                }}
                                            </div>
                                            <div *ngIf="form.value.secondStep.marginType == 1 &&
                                                form.value.secondStep.discountType == 0">
                                                {{
                                                        jetARetail - customerMargin.value.amount
                                                            | currencyPresicion
                                                }}
                                            </div>
                                            <div *ngIf="form.value.secondStep.marginType == 1 &&
                                                form.value.secondStep.discountType == 1">
                                                {{
                                                        jetARetail - (jetARetail * customerMargin.value.amount / 100)
                                                            | currencyPresicion
                                                }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right mat-cell" *ngIf="customerMarginsFormArray.length > 1">
                                        <button (click)="deleteCustomerMargin(i)"
                                                [view]="'error'"
                                                beforeIcon="fa fa-trash"
                                                ni-button
                                                size="small">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="text-right mt-2">
                <button [mat-dialog-close]="" class="mr-1" mat-stroked-button>
                    Cancel
                </button>
                <button (click)="resetWizard()" class="mr-1" mat-stroked-button>
                    Reset
                </button>
                <button class="mr-1" mat-stroked-button matStepperPrevious>
                    Back
                </button>
                <button color="green" mat-stroked-button matStepperNext>
                    Next
                </button>
            </div>
        </mat-step>
        <mat-step formGroupName="thirdStep">
            <ng-template matStepLabel>Notes</ng-template>
            <div class="container-fluid inner-content">
                <div class="row mt-2 mb-2">
                    <div class="col">
                        <mat-form-field>
                            <mat-select
                                formControlName="emailContentId"
                                placeholder="Select Email Template"
                                (selectionChange)="onEmailConentTemplateChanged($event)"
                            >
                                <mat-option
                                    *ngFor="
                                        let emailContentTemplate of emailTemplatesDataSource
                                    "
                                    [value]="emailContentTemplate.oid"
                                >
                                    {{ emailContentTemplate.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2 mb-2" style="font-size: 0.8rem">
                    <span class="font-weight-bold" style="font-size: 1rem">
                        Fuel Notes
                    </span>
                    &nbsp;&nbsp;&nbsp; To show to all FuelerLinx customers
                </div>
                <div class="row mt-2 mb-2">
                    <div class="col">
                        <ejs-richtexteditor
                            #typeNotes
                            [toolbarSettings]="{ enable: false }"
                            formControlName="notes"
                            placeholder="Hours of operation are from 7:00am-6:00pm..."
                            [insertImageSettings]='insertImageSettings'
                        ></ejs-richtexteditor>
                    </div>
                </div>
            </div>
            <div class="text-right mt-2">
                <button [mat-dialog-close]="" class="mr-1" mat-stroked-button>
                    Cancel
                </button>
                <button class="mr-1" mat-stroked-button matStepperPrevious>
                    Back
                </button>
                <button
                    (click)="addTemplateClicked()"
                    class="mr-1"
                    color="green"
                    (disabled)="form.value.thirdStep.emailContentId <= 0"
                    mat-stroked-button
                >
                    Save Template
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
