<section>
    <div *ngIf="!groupInfo">
        <mat-spinner></mat-spinner>
    </div>

    <ni-card
        *ngIf="groupInfo"
        class="mb-0"
        title="Group: {{ groupInfo.groupName }}"
    >
        <div>
            <mat-tab-group>
                <!--FBOs Tab-->
                <mat-tab label="FBOs">
                    <div class="mt-2">
                        <app-fbos-home
                            [embed]="true"
                            [groupInfo]="groupInfo"
                        ></app-fbos-home>
                    </div>
                </mat-tab>

                <!--User Tab-->
                <mat-tab label="Users">
                    <div class="mt-2">
                        <app-users-home
                            [groupInfo]="groupInfo"
                        ></app-users-home>
                    </div>
                </mat-tab>

                <!--Group Info Tab-->
                <mat-tab label="Group Info">
                    <div class="container-fluid mt-2">
                        <div>
                            <h5>Account Info</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field>
                                    <input
                                        [(ngModel)]="groupInfo.groupName"
                                        matInput
                                        placeholder="Group Name"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 text-center">
                                <mat-slide-toggle
                                    [(ngModel)]="groupInfo.active"
                                    color="accent"
                                    >Active
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row">
                            <section class="example-section">
                            <h5>Select documents to exempt:</h5>
                            <p *ngFor="let doc of documents">
                                <mat-checkbox [(ngModel)]="doc.isExempted">
                                {{getDocumentType(doc.documentType)}}
                                </mat-checkbox>
                            </p>
                            </section>
                        </div>
                        <div class="row mt-4 mb-5 pull-right">
                            <button
                                (click)="saveEdit()"
                                class="mr-1"
                                color="green"
                                mat-stroked-button
                            >
                                <mat-icon>save</mat-icon>
                                Save Changes
                            </button>
                            <button (click)="cancelEdit()" mat-stroked-button>
                                Cancel
                            </button>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </ni-card>
</section>
