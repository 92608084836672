<ni-card
    *ngIf="options.useCard"
    [bgColor]="'info'"
    [outline]="true"
    class="info-card"
>
    <h6 class="mt-0 title">Active Aircraft</h6>
    <div class="count">{{ totalAircraft }}</div>
    <small>Source: FuelerLinx &amp; Your Network</small>
    <mat-icon>airplanemode_active</mat-icon>
</ni-card>

<div *ngIf="!options.useCard">Aircraft: {{ totalAircraft }}</div>
