<div class="settings-filter">
    <mat-form-field class="example-full-width" *ngIf="!isSearchAirportHidden">
        <mat-label>Select Airport</mat-label>
        <input #input
                type="text"
                placeholder="Select Airport"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto"
                (input)="filter()">
        <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
            {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field *ngIf="!isSearchAicraftHidden">
        <mat-label>Search Aircraft</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input placeholder="Search Aircraft" matInput (keyup)="applyFilter($event)" />
    </mat-form-field>
</div>
