<h1 mat-dialog-title>Email Template</h1>
<div mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Email Template" [formGroup]="form">
            <div class="pt-3">
                <div>
                    <mat-form-field style="max-width: 200px">
                        <input aria-label="From Email"
                               formControlName="fromAddress"
                               matInput
                               placeholder="From email" />
                        <mat-error *ngIf="form.controls.fromAddress.invalid">Invalid email address.</mat-error>
                    </mat-form-field>
                    @FBOLinx.com
                </div>

                <div>
                    <mat-form-field style="max-width: 600px">
                        <input aria-label="Reply To"
                               formControlName="replyTo"
                               matInput
                               placeholder="Reply To: The address replies will be sent to" />
                        <mat-error *ngIf="form.controls.replyTo.invalid">Invalid email address.</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field style="max-width: 600px">
                    <input aria-label="Subject"
                           matInput
                           placeholder="Subject"
                           formControlName="subject" />
                    <mat-error *ngIf="form.controls.subject.invalid">Subject is required.</mat-error>
                </mat-form-field>

                <ejs-richtexteditor #typeEmail
                                    formControlName="emailContentHtml"
                                    placeholder="Enter email body here..." [insertImageSettings]='insertImageSettings'></ejs-richtexteditor>
            </div>
        </mat-tab>

        <mat-tab label="Logo">
            <div class="pt-3">
                <label>Upload your logo</label><br />
                <input
                    type="file"
                    (change)="onFileChange($event)"
                    accept=".jpg,.jpeg,.png"
                />
                <button
                    *ngIf="!isLogoUploading"
                    (click)="uploadFile()"
                    class="ml-3"
                    color="accent"
                    mat-raised-button
                >
                    Upload File
                </button>
                <button
                    *ngIf="logoUrl"
                    (click)="deleteFile()"
                    class="ml-1"
                    color="warn"
                    mat-raised-button
                >
                    Delete
                </button>
                <div *ngIf="isLogoUploading">
                    <mat-spinner></mat-spinner>
                </div>
                <div *ngIf="logoUrl">
                    <img
                        width="300"
                        height="auto"
                        src="{{ 'data:' + logoUrl }}"
                    />
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button
        [disabled]="form.invalid"
        (click)="update()"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        Update Email Template
    </button>
    <button [mat-dialog-close]="" class="mr-1" mat-stroked-button>
        Cancel
    </button>
</div>
