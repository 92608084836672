<section class="group-analytics">
    <ng-container *ngIf="customers">
        <ni-card
            [headerBgColor]="'gray'"
            class="report-card"
            title="Admin Reports"
        >
            <div>
                <button
                    (click)="onGenerate()"
                    class="action-button"
                    mat-flat-button
                >
                    <h5 class="mat-primary">
                        FBO Group Customer Pricing Report
                    </h5>
                    <p>
                        This report allows you to download current pricing from
                        each of your FBOs for a specific customer. Use to
                        generate a price sheet for a customer at all your
                        locations.
                    </p>
                </button>
            </div>

            <div>
                <button
                    (click)="onEditEmail()"
                    class="action-button"
                    mat-flat-button
                >
                    <h5 class="mat-primary">Edit Email</h5>
                    <p>
                        Customize email template used for customer specific
                        pricing at each of FBO locations.
                    </p>
                </button>
            </div>
        </ni-card>

        <app-group-analytics-customer-statistics
            [fbos]="fbos"
        ></app-group-analytics-customer-statistics>
        <app-group-analytics-fuel-vendor-sources></app-group-analytics-fuel-vendor-sources>
        <app-group-analytics-market-share></app-group-analytics-market-share>
        <ni-card [headerBgColor]="'gray'" title="FBO Network Arrivals & Departures" class="analytics-table">
            <app-group-analytics-intra-network-visits-report></app-group-analytics-intra-network-visits-report>
        </ni-card>
    </ng-container>

</section>
