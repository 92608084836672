<ni-card [headerBgColor]="'gray'" title="Your Monthly Fuel Orders">
    <div class="flex">
        <div class="d-inline-block mr-2 ml-2" style="width: 130px">
            <mat-form-field>
                <input
                    (dateChange)="refreshData()"
                    (focus)="dashboardSettingsStartDateFilter.open()"
                    [(ngModel)]="filterStartDate"
                    [matDatepicker]="dashboardSettingsStartDateFilter"
                    [max]="filterEndDate"
                    matInput
                    placeholder="Start Date"
                />
                <mat-datepicker-toggle
                    [for]="dashboardSettingsStartDateFilter"
                    matSuffix
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dashboardSettingsStartDateFilter
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="d-inline-block mr-2 ml-2" style="width: 130px">
            <mat-form-field>
                <input
                    (dateChange)="refreshData()"
                    (focus)="dashboardSettingsEndDateFilter.open()"
                    [(ngModel)]="filterEndDate"
                    [matDatepicker]="dashboardSettingsEndDateFilter"
                    [min]="filterStartDate"
                    matInput
                    placeholder="End Date"
                />
                <mat-datepicker-toggle
                    [for]="dashboardSettingsEndDateFilter"
                    matSuffix
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #dashboardSettingsEndDateFilter
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="chart-container">
        <ngx-charts-bar-vertical
            *ngIf="totalOrdersData"
            [legend]="false"
            [results]="totalOrdersData"
            [scheme]="colorScheme"
            [xAxis]="true"
            [yAxis]="true"
        ></ngx-charts-bar-vertical>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
</ni-card>
