<h1 mat-dialog-title>New FBO</h1>
<div mat-dialog-content>
    <app-airport-autocomplete
        (valueChange)="airportValueChanged($event)"
        [airportContainerModel]="data"
    ></app-airport-autocomplete>
    <mat-form-field
        *ngIf="dataSources.acukwikFbos && dataSources.acukwikFbos.length > 0"
    >
        <mat-select
            (selectionChange)="fboSelectionChange()"
            [(ngModel)]="data.acukwikFbo"
            id="newFbo"
            name="ddlNewFbo"
        >
            <mat-option
                *ngFor="let acukwikFbo of dataSources.acukwikFbos"
                [value]="acukwikFbo"
            >{{ acukwikFbo.handlerLongName }}</mat-option
            >
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="errorMessage != ''" class="mt-2 text-center" style="color: red">
    <p>{{errorMessage}}</p>
</div>
<div class="mt-2 text-center">
    <button
        (click)="onSaveChanges()"
        [disabled]="!data.acukwikFboHandlerId"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        Add FBO
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
</div>
