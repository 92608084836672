<h1 mat-dialog-title>{{ data.title }}</h1>
<div class="mt-1 mb-2" mat-dialog-content>
    <div class="text-center">
        {{ data.description }}
    </div>
</div>
<div mat-dialog-actions>
    <button
        (click)="onConfirm()"
        [disabled]="loading"
        class="mr-1 confirm-btn"
        color="accent"
        mat-raised-button
    >
        {{ !loading ? "Confirm" : "" }}
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
    </button>
    <button [mat-dialog-close]="false" mat-stroked-button>Cancel</button>
</div>
