<div hidden="true" >
    <app-aircraft-popup-container #aircraftPopupContainer  [flightData]="popupData" [fboId]="fboId" [groupId]="groupId" [isLoading]="false" ></app-aircraft-popup-container>
</div>
<div id="flight-watch-map"></div>

<div *ngIf="!isStable" class="map-error-msg">
    <mat-icon aria-hidden="false" aria-label="Server Error">wifi_tethering_off</mat-icon>
    <span>Server Error!!!</span>
</div>
<div class="location-button" (click)="goToCurrentIcao()">
    <img src="/assets/img/my_location.png" alt="my location"/>
</div>
