<div class="row">
    <div class="col-6 col-md-10">
        <mat-form-field class="filter">
            <input (keyup)="applyFilter($event.target.value)"
                   [value]="searchValue"
                   matInput
                   placeholder="Search by Group, FBO, User, ..." />
            <span matPrefix><i class="fa fa-search"></i></span>
        </mat-form-field>

        <mat-form-field class="account-filter">
            <mat-label>Group Status</mat-label>
            <mat-select [(ngModel)]="groupAccountType"
                        (selectionChange)="filterChanged()">
                <mat-option value="all">All</mat-option>
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="account-filter">
            <mat-label>FBO Active Status</mat-label>
            <mat-select [(ngModel)]="fboActiveAccountType"
                        (selectionChange)="filterChanged()">
                <mat-option value="all">All</mat-option>
                <mat-option value="active">Active</mat-option>
                <mat-option value="inactive">Inactive</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="account-filter">
            <mat-label>FBO Account Type</mat-label>
            <mat-select [(ngModel)]="fboAccountType"
                        (selectionChange)="filterChanged()">
                <mat-option value="all">All</mat-option>
                <mat-option value="premium">Premium</mat-option>
                <mat-option value="freemium">Freemium</mat-option>
            </mat-select>
        </mat-form-field>

        <button (click)="mergeGroups()"
                color="warn"
                mat-flat-button
                [disabled]="selectedRows.length < 2">
            <mat-icon>merge_type</mat-icon>
            Merge Groups
        </button>

        <!--<button (click)="addAssociation()"
           mat-flat-button>
        Add Association
    </button>-->
    </div>

    <div class="actions col-6 col-md-2">
        <button [matMenuTriggerFor]="gridActions" mat-icon-button>
            <mat-icon
                aria-hidden="true"
                class="mat-icon material-icons"
                role="img"
               >settings</mat-icon
            >
        </button>
        <mat-menu
            #gridActions="matMenu"
            class="switch-nested-menu-left-arrows"
            xPosition="before"
        >
            <button (click)="openSettings()" mat-menu-item>Settings</button>
        </mat-menu>
    </div>
</div>

<ejs-grid
    #grid
    (rowDeselected)="rowDeselected()"
    (rowSelected)="rowSelected($event)"
    (actionComplete)="actionHandler($event)"
    [allowPaging]="true"
    [allowSorting]="true"
    [allowFiltering]="true"
    [childGrid]="childGrid"
    [dataSource]="groupDataSource"
    [filterSettings]="filterSettings"
    [pageSettings]="pageSettings"
    [selectionSettings]="selectionOptions"
    [showColumnMenu]="true"
>
    <e-columns>
        <e-column type="checkbox" width="50"></e-column>
        <e-column field="groupName" headerText="Group"></e-column>
        <e-column field="expiredFboPricingCount" headerText="Pricing Expired">
            <ng-template #template let-data>
                <span [ngClass]="{ good: isValidPricing(data) }"
                      class="status">
                    {{
                        isValidPricing(data)
                            ? "All Pricing Live"
                            : (data.activeFboCount > 0
                            ? (data.expiredFboPricingCount +
                              " of " +
                              data.activeFboCount +
                              " Expired")
                            : "No Active FBOs")
                    }}
                </span>
            </ng-template>
        </e-column>
        <e-column field="needAttentionCustomers" headerText="Need Attentions">
            <ng-template #template let-data>
                <span
                    [ngClass]="{ good: data.needAttentionCustomers == 0}"
                    class="status"
                >
                    {{ data.needAttentionCustomers }} Need Attentions
                </span>
            </ng-template>
        </e-column>
        <e-column
            field="lastLogin"
            headerText="Last Login Date"
            [sortComparer]="dateSortComparer"
        >
            <ng-template #template let-data>
                {{ data.lastLogin | date: "medium" }}
            </ng-template>
        </e-column>
        <e-column field="active" headerText="Active"></e-column>
        <e-column field="users" headerText="Users">
            <ng-template #template let-data>
                {{ data.users.length }}
            </ng-template>
        </e-column>
        <e-column
            field="quotes30Days"
            headerText="Quotes (last 30 days)"
        ></e-column>
        <e-column
            field="orders30Days"
            headerText="Fuel Orders (last 30 days)"
        ></e-column>
        <e-column field="expiredFboAccountCount" headerText="Active / Inactive">
            <ng-template #template let-data>
                <span [ngClass]="{ good: data.expiredFboAccountCount === 0 }"
                      class="status">
                    {{
                        data.expiredFboAccountCount == 0 && data.fboCount > 0
                            ? "All Active"
                            : (data.fboCount > 0)
                            ? (data.expiredFboAccountCount +
                              " of " +
                              data.fboCount +
                              " Inactive")
                            : "No FBOs Found"
                    }}
                </span>
            </ng-template>
        </e-column>
        <e-column width="200">
            <ng-template #headerTemplate let-data>
                <button
                    (click)="addNewGroupOrFbo()"
                    [view]="'accent'"
                    beforeIcon="fa fa-plus"
                    ni-button
                >
                    Add New
                </button>
            </ng-template>
            <ng-template #template let-data>
                <button
                    [matMenuTriggerFor]="menu"
                    aria-label="Group Actions"
                    clickStopPropagation
                    mat-icon-button
                >
                    <mat-icon>settings</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button (click)="editGroup(data)"
                            clickStopPropagation
                            mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>Edit Group</span>
                    </button>
                    <button (click)="addNewFbo(data)"
                            clickStopPropagation
                            mat-menu-item>
                        <mat-icon>add</mat-icon>
                        <span>Add FBO</span>
                    </button>
                    <button (click)="deleteGroup(data)"
                            clickStopPropagation
                            mat-menu-item>
                        <mat-icon>delete_forever</mat-icon>
                        <span>Delete Group</span>
                    </button>
                    <button [disabled]="!getGroupFbos(data.oid).length"
                            [matMenuTriggerFor]="fbosMenu"
                            clickStopPropagation
                            mat-menu-item>
                        <span>Delete FBO</span>
                    </button>
                </mat-menu>
                <mat-menu #fbosMenu="matMenu">
                    <button
                        (click)="deleteFbo(fbo)"
                        *ngFor="let fbo of getGroupFbos(data.oid)"
                        clickStopPropagation
                        mat-menu-item
                    >
                        <mat-icon>delete_forever</mat-icon>
                        <span>{{ fbo.fbo }}</span>
                    </button>
                </mat-menu>
            </ng-template>
        </e-column>
    </e-columns>
</ejs-grid>

<ng-template #pricingExpiredTemplate let-data>
    <span [ngClass]="{ good: !data.pricingExpired }" class="status">
        {{ data.pricingExpired ? "Expired" : "Pricing Live" }}
    </span>
</ng-template>

<ng-template #fboManageTemplate let-data>
    <button
        (click)="editFBO(data)"
        [view]="'warning'"
        beforeIcon="fa fa-pencil"
        clickStopPropagation
        ni-button
        size="small"
    >
        Edit FBO
    </button>
</ng-template>

<ng-template #needAttentionTemplate let-data>
    <span [ngClass]="{ good: data.needAttentionCustomers == 0 }" class="status">
        {{ data.needAttentionCustomers }} Need Attentions
    </span>
</ng-template>

<ng-template #usersTemplate let-data>
    <span>{{ data.users.length }}</span>
</ng-template>

<ng-template #lastLoginTemplate let-data>
    {{ data.lastLogin | date: "medium" }}
</ng-template>

<ng-template #accountExpiredTemplate let-data>
    <span [ngClass]="{ good: !data.accountExpired }" class="status">
        {{ data.accountExpired ? "Inactive" : "Active" }}
    </span>
</ng-template>

<ng-template #accountTypeTemplate let-data>
    <span>
        {{ data.accountType ? "Freemium" : "Premium"}}
    </span>
</ng-template>
