<h1 mat-dialog-title>Confirm Aircraft Removal</h1>
<div *ngIf="data" mat-dialog-content>
    <div class="row">
        <div class="col-xs-12 col-md-12">
            <p>Are you sure you want to delete {{ data.tailNumber }}?</p>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button [mat-dialog-close]="data" class="mr-1" color="warn" mat-flat-button>
        <mat-icon>delete_forever</mat-icon>
        Delete
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>&nbsp;
</div>
