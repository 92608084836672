<section>
    <app-fbo-prices-home [isCsr]="isCsr"></app-fbo-prices-home>

    <div class="row">
        <div class="col-md-6 col-xl-3">
            <app-statistics-total-customers #statisticsTotalCustomers
                                            [endDate]="filterEndDate"
                                            [startDate]="filterStartDate"></app-statistics-total-customers>
        </div>

        <div class="col-md-6 col-xl-3">
            <app-statistics-total-aircraft #statisticsTotalAircraft
                                           [endDate]="filterEndDate"
                                           [startDate]="filterStartDate"></app-statistics-total-aircraft>
        </div>

        <div class="col-md-6 col-xl-3">
            <app-statistics-total-orders #statisticsTotalOrders
                                         [endDate]="filterEndDate"
                                         [startDate]="pastThirtyDaysStartDate"></app-statistics-total-orders>
        </div>

        <div class="col-md-6 col-xl-3">
            <app-statistics-orders-by-location #statisticsOrdersByLocation
                                               [endDate]="filterEndDate"
                                               [startDate]="pastThirtyDaysStartDate"></app-statistics-orders-by-location>
        </div>

    </div>
</section>
