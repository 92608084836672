<ni-card [headerBgColor]="'gray'" title="Fuel Vendor Share by Airport">
    <div class="chart-container">
        <div class="flex position-relative">
            <div class="d-inline-block mr-2 ml-2" style="width: 130px">
                <mat-form-field>
                    <input
                        (dateChange)="refreshData()"
                        (focus)="dashboardSettingsStartDateFilter.open()"
                        [(ngModel)]="filterStartDate"
                        [matDatepicker]="dashboardSettingsStartDateFilter"
                        [max]="filterEndDate"
                        matInput
                        placeholder="Start Date"
                    />
                    <mat-datepicker-toggle
                        [for]="dashboardSettingsStartDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #dashboardSettingsStartDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="d-inline-block mr-2 ml-2" style="width: 130px">
                <mat-form-field>
                    <input
                        (dateChange)="refreshData()"
                        (focus)="dashboardSettingsEndDateFilter.open()"
                        [(ngModel)]="filterEndDate"
                        [matDatepicker]="dashboardSettingsEndDateFilter"
                        [min]="filterStartDate"
                        matInput
                        placeholder="End Date"
                    />
                    <mat-datepicker-toggle
                        [for]="dashboardSettingsEndDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #dashboardSettingsEndDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="actions">
                <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                    <mat-icon
                        aria-hidden="true"
                        class="mat-icon material-icons"
                        role="img"
                        >settings</mat-icon
                    >
                </button>
                <mat-menu
                    #gridActions="matMenu"
                    class="switch-nested-menu-left-arrows"
                    xPosition="before"
                >
                    <button (click)="onExport()" mat-menu-item>Export</button>
                    <button (click)="openSettings()" mat-menu-item>
                        Settings
                    </button>
                </mat-menu>
            </div>
        </div>

        <div class="table-container mat-elevation-z3">
            <table [dataSource]="dataSource" mat-table matSort>
                <ng-container matColumnDef="icao">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="icao"
                                propertyName="icao"
                                [columnFormat]="0"
                            >
                                <span>ICAO</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>{{ info.icao }}</td>
                </ng-container>

                <ng-container matColumnDef="directOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="directOrders"
                                propertyName="directOrders"
                                [columnFormat]="1"
                            >
                                <span>Direct Orders</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.directOrders }}
                    </td>
                </ng-container>

                <ng-container
                    *ngFor="let vendor of vendors"
                    matColumnDef="{{ vendor }}"
                >
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                [columnId]="vendor"
                                [propertyName]="vendor"
                                [columnFormat]="1"
                            >
                                <span>{{ vendor }}</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>{{ info[vendor] }}</td>
                </ng-container>

                <!-- <ng-container matColumnDef="conversionRate">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter [matDataSource]="dataSource"
                                                    [matSort]="sort"
                                                    columnId="conversionRate"
                                                    propertyName="conversionRate"
                                                    [columnFormat]="1"
                            >                        <span>Conversion Rate</span>
</app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell> {{info.conversionRate}}%</td>
                </ng-container>

                <ng-container matColumnDef="totalOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter [matDataSource]="dataSource"
                                                    [matSort]="sort"
                                                    columnId="totalOrders"
                                                    propertyName="totalOrders"
                                                    [columnFormat]="1"
                            >                        <span>Total Orders</span>
</app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell> {{info.totalOrders}} </td>
                </ng-container>

                <ng-container matColumnDef="lastPullDate">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter [matDataSource]="dataSource"
                                                    [matSort]="sort"
                                                    columnId="lastPullDate"
                                                    propertyName="lastPullDate"
                                                    [columnFormat]="4"
                            >
                                                <span>Last Quoted</span>

                        </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell> {{info.lastPullDate}} </td>
                </ng-container> -->
                <tr
                    *matHeaderRowDef="visibleColumns; sticky: true"
                    mat-header-row
                ></tr>
                <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
            </table>
        </div>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
</ni-card>
