<div class="custom-key-tab">
    <div class="custom-key-content" style="padding-left: 25px">
        <div class="d-inline-block">
            <img
                src="/assets/img/airplane_FUELERLINX.png"
                style="width: 75%; height: 75%"
            />
        </div>
        <div class="d-inline-block">
            <span>FuelerLinx Client</span>
        </div>
    </div>
    <div class="custom-key-content">
        <div class="d-inline-block">
            <img
                src="/assets/img/airplane-FUEL_RELEASE.png"
                style="width: 75%; height: 75%"
            />
        </div>
        <div class="d-inline-block">
            <span>Active Fuel Release</span>
        </div>
    </div>
    <div class="custom-key-content">
        <div class="d-inline-block">
            <img
                src="/assets/img/airplane_ACTIVE.png"
                style="width: 75%; height: 75%"
            />
        </div>
        <div class="d-inline-block">
            <span>Out of Network</span>
        </div>
    </div>
    <div class="custom-key-content">
        <div class="d-inline-block">
            <img
                src="/assets/img/airplane_CLIENT.png"
                style="width: 75%; height: 75%"
            />
        </div>
        <div class="d-inline-block">
            <span>In Network</span>
        </div>
    </div>
</div>
