<h1 mat-dialog-title>New Ramp Fee</h1>
<div class="mt-1" mat-dialog-content>
    <mat-form-field>
        <mat-select [(ngModel)]="data.categoryType" placeholder="Category">
            <mat-option
                *ngFor="let categoryType of categoryTypes"
                [value]="categoryType.value"
            >
                {{ categoryType.text }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <mat-form-field>
            <input [(ngModel)]="data.price"
                   aria-label="Ramp/Facil. Fee"
                   matInput
                   placeholder="Ramp/Facil. Fee"
                   type="number"
                   (change)="checkForFeeNegativeValue(data.price)" />
            <span matPrefix>$</span>
        </mat-form-field>
        <div *ngIf="isFeeNegative" class="text-danger">Fee cannot be negative</div>
    </div>
    <div>
        <mat-form-field>
            <input [(ngModel)]="data.waived"
                   aria-label="Ramp/Facil. Fee"
                   matInput
                   placeholder="Avoidance"
                   type="number"
                   (change)="checkForWaivedNegativeValue(data.waived)" />
            <span matSuffix>Gal.</span>
        </mat-form-field>
        <div *ngIf="isWaivedNegative" class="text-danger">Avoidance cannot be negative</div>
    </div>
    <div>
        <mat-form-field>
            <input
                [(ngModel)]="data.expirationDate"
                [matDatepicker]="categoryDatePicker"
                disabled
                matInput
                placeholder="Expiration Date"
            />
            <mat-datepicker-toggle
                [for]="categoryDatePicker"
                matSuffix
            ></mat-datepicker-toggle>
            <mat-datepicker
                #categoryDatePicker
                disabled="false"
            ></mat-datepicker>
        </mat-form-field>
    </div>
    <div *ngIf="data.categoryType == 2">
        <mat-form-field>
            <mat-select [(ngModel)]="data.categoryMinValue" placeholder="Type">
                <mat-option
                    *ngFor="let aircraftType of aircraftTypes"
                    [value]="aircraftType.aircraftId"
                >
                    {{ aircraftType.make }} {{ aircraftType.model }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="data.categoryType == 3">
        <mat-form-field>
            <input [(ngModel)]="data.categoryMinValue"
                   aria-label="Min. Weight"
                   matInput
                   placeholder="Min. Weight"
                   type="number"
                   (change)="checkCategoryValidation(data)" />
            <span matSuffix>Lbs.</span>
        </mat-form-field>
        <div *ngIf="isCategoryValueNegative || !isMaxCategoryGrater" class="text-danger">
            {{ isMaxCategoryGrater ? '' : 'Max. Weight should be greater than Min. Weight' }}<br />
            {{ !isCategoryValueNegative ? '' : 'Weight cannot be negative'}}

        </div>
    </div>
    <div *ngIf="data.categoryType == 3">
        <mat-form-field>
            <input [(ngModel)]="data.categoryMaxValue"
                   aria-label="Max. Weight"
                   matInput
                   placeholder="Max. Weight"
                   type="number"
                   (change)="checkCategoryValidation(data)" />
            <span matSuffix>Lbs.</span>
        </mat-form-field>
    </div>
    <div *ngIf="data.categoryType == 4">
        <mat-form-field>
            <input [(ngModel)]="data.categoryMinValue"
                   aria-label="Min. Wingspan"
                   matInput
                   placeholder="Min. Wingspan"
                   type="number"
                   (change)="checkCategoryValidation(data)" />
            <span matSuffix>Feet</span>
        </mat-form-field>
        <div *ngIf="isCategoryValueNegative || !isMaxCategoryGrater" class="text-danger">
            {{ isMaxCategoryGrater ? '' : 'Max. Wingspan should be greater than Min. Wingspan' }}<br />
            {{ !isCategoryValueNegative ? '' : 'Wingspan cannot be negative'}}
        </div>
    </div>
    <div *ngIf="data.categoryType == 4">
        <mat-form-field>
            <input [(ngModel)]="data.categoryMaxValue"
                   aria-label="Max. Wingspan"
                   matInput
                   placeholder="Max. Wingspan"
                   type="number"
                   (change)="checkCategoryValidation(data)" />
            <span matSuffix>Feet</span>
        </mat-form-field>
    </div>
    <div *ngIf="data.categoryType == 5">
        <mat-form-field>
            <input
                [(ngModel)]="data.categoryStringValue"
                aria-label="Tail Number"
                matInput
                placeholder="Tail Number"
                type="text"
            />
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button
        [mat-dialog-close]="data"
        class="mr-1"
        color="green"
        mat-stroked-button
        [disabled] = "isWaivedNegative || isCategoryValueNegative || isFeeNegative || !isMaxCategoryGrater"
    >
        <mat-icon>save</mat-icon>
        Add Ramp Fee
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
</div>
