<div class="mobile-header">
    <h5>Dashboard</h5>
</div>

<section>
    <div class="container-fluid">

            <app-fbo-prices-update-generator [isCsr]="isCsr"
                                             [isMember]="isMember"></app-fbo-prices-update-generator>

        <div class="row" *ngIf="!integrationStatus">
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <ni-card [collapsible]="false"
                         [opened]="true"
                         bgColor="dead"
                         class=""
                         headerBgColor="dead"
                         outline="true"
                         theme="small"
                         title="Expired Pricing Lost Opportunities">
                    <div class="app-fbos-missed-quotes-grid">
                        <app-fbos-missed-quotes-grid #missedQuotes></app-fbos-missed-quotes-grid>
                    </div>
                </ni-card>
            </div>
            <div class="d-none d-md-block col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <ni-card [collapsible]="false"
                         [opened]="true"
                         bgColor="dead"
                         class=""
                         headerBgColor="dead"
                         outline="true"
                         theme="small"
                         title="Flight Watch - Live Flight Tracking">
                    <div class="map-container" (click)="gotoFlightWatch()">
                            <app-flight-watch-map-wrapper
                                (markerClicked)="null"
                                (airportClick)="null"
                                (setIcaoList)="null"
                                [center]="center"
                                [data]="flightWatchData"
                                [isStable]="isStable"
                                [icao]="selectedICAO"
                                [showFilters]="false"
                        ></app-flight-watch-map-wrapper>
                    </div>
                </ni-card>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="!isSingleSourceFbo">
                <ni-card [collapsible]="false"
                         [opened]="true"
                         bgColor="dead"
                         class="prices-board-item prices-board-second-column price-checker"
                         headerBgColor="dead"
                         outline="true"
                         theme="small"
                         title="Lost To Competition">
                    <app-fbos-missed-orders-grid #missedOrders></app-fbos-missed-orders-grid>
                </ni-card>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-xl-3">
                <app-statistics-total-customers #statisticsTotalCustomers
                                                [endDate]="filterEndDate"
                                                [startDate]="filterStartDate"></app-statistics-total-customers>
            </div>

            <div class="col-md-6 col-xl-3">
                <app-statistics-total-aircraft #statisticsTotalAircraft
                                               [endDate]="filterEndDate"
                                               [startDate]="filterStartDate"></app-statistics-total-aircraft>
            </div>

            <div class="col-md-6 col-xl-3">
                <app-statistics-total-orders #statisticsTotalOrders
                                             [endDate]="filterEndDate"
                                             [startDate]="pastThirtyDaysStartDate"></app-statistics-total-orders>
            </div>

            <div class="col-md-6 col-xl-3">
                <app-statistics-orders-by-location #statisticsOrdersByLocation
                                                   [endDate]="filterEndDate"
                                                   [startDate]="pastThirtyDaysStartDate"></app-statistics-orders-by-location>
            </div>
        </div>
    </div>
</section>
