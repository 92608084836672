<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div class="row">

    <div class="col-10">
        <div class="row mat-elevation-z">
            <ng-container *ngFor="let service of fuelreqsServicesAndFeesGridDisplay;">
                <div class="col-3">
                    <ni-card customStyle="height: 88px;">
                        <div class="service-card-services">
                            <div class="row ml-sm-n3 mr-sm-n5 ml-md-n3 mr-md-n1  ml-lg-n4 mr-lg-n2 ml-xl-n4 mr-xl-n3" *ngIf="service.isAddMode">
                                <div class="col-7 text" *ngIf="service.isAddMode">
                                    <span>
                                        <app-autocomplete-search [panelWidth]="250" [filter]="service.serviceName" (selectionChanged)="onAddServiceChanged(service, $event)" (filterChanged)="onAddServiceChanged(service, $event)" [options]="servicesAndFeesOptions">
                                        </app-autocomplete-search>
                                    </span>
                                </div>
                                <div class="col-4 text" *ngIf="service.isAddMode">
                                    <button [view]="'accent'"
                                            beforeIcon="fa fa-plus"
                                            ni-button
                                            size="small"
                                            *ngIf="!service.isAdding"
                                            [disabled]="service.serviceName == ''"
                                            (click)="add(service)">
                                        Add
                                    </button>
                                    <div *ngIf="service.isAdding">
                                        <mat-spinner diameter="20"></mat-spinner>
                                    </div>
                                </div>
                            </div>

                            <div class="row" style="margin-left: -15px; margin-right: -130px;" *ngIf="service.isEditMode">
                                <div class="col-5 text" *ngIf="service.isEditMode">
                                    <span>
                                        <app-autocomplete-search [panelWidth]="250" [filter]="service.serviceName" (selectionChanged)="onAddServiceChanged(service, $event)" (filterChanged)="onAddServiceChanged(service, $event)" [options]="servicesAndFeesOptions">
                                        </app-autocomplete-search>
                                    </span>
                                </div>
                                <div class="col-5 text" *ngIf="service.isEditMode">
                                    <button [view]="'accent'"
                                            beforeIcon="fa fa-save"
                                            ni-button
                                            size="small"
                                            [disabled]="service.serviceName == ''"
                                            (click)="updateServiceName(service)">
                                        Apply
                                    </button>
                                    <!--<button ni-button
                                    size="small"
                                    (click)="cancelEdit(service)">
                                Cancel
                            </button>&nbsp;-->
                                </div>
                            </div>

                            <div class="row" style="margin-left: -15px; margin-right:-90px;" *ngIf="!service.isAddMode && !service.isEditMode">
                                <div class="col-1 text" *ngIf="!service.isAddMode">
                                    <mat-checkbox [(ngModel)]="service.isCompleted" (change)="updateCompletedFlag(service)">
                                    </mat-checkbox>
                                </div>
                                <div class="col-5 text" *ngIf="!service.isEditMode && !service.isAddMode && !service.serviceName.startsWith('Fuel: ')">
                                    <div class="padding-5" style="width: 140px; max-width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [matTooltip]="service.serviceName" *ngIf="!service.isCompleted">{{service.serviceName}}</div>
                                    <div class="padding-5" style="width: 140px; max-width: 140px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [matTooltip]="service.serviceName" *ngIf="service.isCompleted"><s>{{service.serviceName}}</s></div>
                                </div>
                                <div class="col-5 text" *ngIf="!service.isEditMode && !service.isAddMode && service.serviceName.startsWith('Fuel: ')">
                                    <div class="padding-5" style="width: 160px; max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [matTooltip]="service.serviceName" *ngIf="!service.isCompleted">{{service.serviceName}}</div>
                                    <div class="padding-5" style="width: 160px; max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" [matTooltip]="service.serviceName" *ngIf="service.isCompleted"><s>{{service.serviceName}}</s></div>
                                </div>
                                <div class="col-5 text" *ngIf="!service.isEditMode && !service.isAddMode">
                                    <span *ngIf="!service.serviceName.startsWith('Fuel: ') && service.toolTipText != 'Source: FuelerLinx Database'"><mat-icon class="material-icons-outlined" (click)="toogleEditModel(service)">edit</mat-icon></span>
                                    <span *ngIf="!service.serviceName.startsWith('Fuel: ')"><mat-icon class="material-icons-outlined" (click)="deleteItem(service, service.serviceName)">delete</mat-icon></span>
                                    <span *ngIf="!service.serviceName.startsWith('Fuel: ')"
                                          [matTooltip]="service.toolTipText"><mat-icon class="material-icons-outlined">info</mat-icon></span>
                                </div>
                            </div>
                        </div>
                    </ni-card>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="col-2">
        <div (click)="toggleNotesDrawer(true)">
            <button beforeIcon="fa fa-clipboard"
                    [view]="'primary'"
                    ni-button
                    size="small"
                    #nodeInput>
                View Notes
            </button>
        </div>
    </div>
</div>
