<h1 mat-dialog-title>New Customer Type</h1>
<div mat-dialog-content>
    <div>
        <div>
            <mat-form-field>
                <input
                    [(ngModel)]="data.name"
                    aria-label="Customer Type"
                    matInput
                    placeholder="Customer Type"
                />
            </mat-form-field>
        </div>
        <div>
            <mat-slide-toggle
                [(ngModel)]="data.allowMultiplePricingTemplates"
                color="accent"
                >Has multiple margins (i.e. commercial and private)
            </mat-slide-toggle>
        </div>
    </div>
</div>
<div class="mt-2 text-center">
    <button
        [mat-dialog-close]="data"
        class="mr-1"
        color="accent"
        mat-flat-button
    >
        Add Type
    </button>
    <button (click)="onCancelClick()" mat-stroked-button>Cancel</button>
</div>
