<ni-card [headerBgColor]="'gray'" title="Market Share by Airport">
    <div class="chart-container">
        <div class="flex position-relative">
            <div class="d-inline-block mr-2 ml-2" style="width: 130px">
                <mat-form-field>
                    <input
                        (dateChange)="refreshData()"
                        (focus)="dashboardSettingsStartDateFilter.open()"
                        [(ngModel)]="filterStartDate"
                        [matDatepicker]="dashboardSettingsStartDateFilter"
                        [max]="filterEndDate"
                        matInput
                        placeholder="Start Date"
                    />
                    <mat-datepicker-toggle
                        [for]="dashboardSettingsStartDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #dashboardSettingsStartDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="d-inline-block mr-2 ml-2" style="width: 130px">
                <mat-form-field>
                    <input
                        (dateChange)="refreshData()"
                        (focus)="dashboardSettingsEndDateFilter.open()"
                        [(ngModel)]="filterEndDate"
                        [matDatepicker]="dashboardSettingsEndDateFilter"
                        [min]="filterStartDate"
                        matInput
                        placeholder="End Date"
                    />
                    <mat-datepicker-toggle
                        [for]="dashboardSettingsEndDateFilter"
                        matSuffix
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                        #dashboardSettingsEndDateFilter
                        disabled="false"
                    ></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="actions">
                <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                    <mat-icon
                        aria-hidden="true"
                        class="mat-icon material-icons"
                        role="img"
                        >settings</mat-icon
                    >
                </button>
                <mat-menu
                    #gridActions="matMenu"
                    class="switch-nested-menu-left-arrows"
                    xPosition="before"
                >
                    <button (click)="onExport()" mat-menu-item>Export</button>
                    <button (click)="openSettings()" mat-menu-item>
                        Settings
                    </button>
                </mat-menu>
            </div>
        </div>

        <div class="table-container mat-elevation-z8">
            <table [dataSource]="dataSource" mat-table matSort>
                <ng-container matColumnDef="icao">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="icao"
                                propertyName="icao"
                                [columnFormat]="0"
                            >
                                <span>ICAO</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>{{ info.icao }}</td>
                </ng-container>

                <ng-container matColumnDef="airportOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="airportOrders"
                                propertyName="airportOrders"
                                [columnFormat]="1"
                            >
                                <span>Total Orders at Airport</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.airportOrders }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="fboOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="fboOrders"
                                propertyName="fboOrders"
                                [columnFormat]="1"
                            >
                                <span
                                    >Your Orders at Airport: Contract Fuel
                                    Vendors</span
                                ></app-table-column-filter
                            >
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.fboOrders }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="yourOrders">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="yourOrders"
                                propertyName="yourOrders"
                                [columnFormat]="1"
                            >
                                <span>Your Orders at Airport: Directs</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.yourOrders }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="marketShare">
                    <th *matHeaderCellDef mat-header-cell>
                        <span *ngIf="dataSource">
                            <app-table-column-filter
                                [matDataSource]="dataSource"
                                [matSort]="sort"
                                columnId="marketShare"
                                propertyName="marketShare"
                                [columnFormat]="1"
                            >
                                <span>Market Share</span>
                            </app-table-column-filter>
                        </span>
                    </th>
                    <td *matCellDef="let info" mat-cell>
                        {{ info.marketShare }}%
                    </td>
                </ng-container>

                <tr
                    *matHeaderRowDef="visibleColumns; sticky: true"
                    mat-header-row
                ></tr>
                <tr *matRowDef="let row; columns: visibleColumns" mat-row></tr>
            </table>
        </div>
    </div>
    <ngx-ui-loader
        [loaderId]="chartName"
        bgsColor="rgb(100, 181, 246)"
        bgsPosition="center-center"
        bgsType="ball-spin"
        overlayColor="rgba(0, 0, 0, 0.1)"
    ></ngx-ui-loader>
</ni-card>
