<div class="text-center" *ngIf="!customerAircraftInfo || isLoading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="customerAircraftInfo && !isLoading" class="">
    <div class="container-fluid">
        <div>
            <h5>
                General Information&nbsp;&nbsp;
                <button
                    (click)="ConfirmDelete(customerAircraftInfo)"
                    [disabled]="data && data.disableDelete"
                    color="warn"
                    mat-flat-button
                >
                    Delete Aircraft
                </button>
            </h5>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input
                        [(ngModel)]="customerAircraftInfo.tailNumber"
                        matInput
                        placeholder="Tail Number"
                    />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="customerAircraftInfo.aircraftId"
                        placeholder="Type"
                    >
                        <mat-option
                            *ngFor="let aircraftType of aircraftTypes"
                            [value]="aircraftType.aircraftId"
                        >
                            {{ aircraftType.make }} {{ aircraftType.model }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="customerAircraftInfo.size"
                        placeholder="Size"
                    >
                        <mat-option
                            *ngFor="let aircraftSize of aircraftSizes"
                            [value]="aircraftSize.value"
                        >
                            {{ aircraftSize.description }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="customerAircraftNote">
            <div class="col-12">
                <mat-form-field>
                    <textarea matInput placeholder="Notes" [(ngModel)]="customerAircraftNote.notes" rows="4"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <div class="pull-right">
                <button
                    (click)="saveEdit()"
                    class="mr-1"
                    color="green"
                    mat-stroked-button
                >
                    <mat-icon>save</mat-icon>
                    Save Aircraft
                </button>
                <button (click)="cancelEdit()" mat-stroked-button>
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>
